import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import { AccountingService } from '../services/accounting_service';
import MessageService from '../services/message_service.js';
import MainLayout from '../main/main_layout';
import Constant from '../helpers/constant';
import ConfirmDialog from '../main/confirm_dialog';
import MatchingAccounting from '../matching/matching_accounting';

class AccountingDetail extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: {},
      detail: [],
      loading: true,
      dateLabel: '',
      itemsLabel: '',
      partnerLabel: '',
      canceling: false,
    }

    this.accountingID = this.props.match.params.id;

    moment.locale('th');

    this.onVoidClick = this.onVoidClick.bind(this);

  }

  componentDidMount() {

    AccountingService.getDetail(this.accountingID).then(
      resp => {

        let dateLabel = '';
        let itemsLabel = '';
        let partnerLabel = '';
        switch (resp.data.accounting_type) {

          case 'SELL':
            this.dateLabel = 'วันที่ขาย';
            this.itemsLabel = 'รายการขาย';
            this.partnerLabel = 'ลูกหนี้';
            break;

          case 'BUY':
            this.dateLabel = 'วันที่ซื้อ';
            this.itemsLabel = 'รายการซื้อ';
            this.partnerLabel = 'เจ้าหนี้';
            break;

          case 'EXPENSE':
            this.dateLabel = 'วันที่จ่าย';
            this.itemsLabel = 'รายการรายจ่าย';
            this.partnerLabel = 'เจ้าหนี้';
            break;

          case 'INCOME':
            this.dateLabel = 'วันที่รับ';
            this.itemsLabel = 'รายการรายรับ';
            break;

          case 'WITHDRAW':
          case 'WITHDRAW_EXPIRED':
            this.dateLabel = 'วันที่เบิก';
            this.itemsLabel = 'รายการเบิก';
            break;

          case 'HARVEST':
            this.dateLabel = 'วันที่เก็บเกี่ยว';
            this.itemsLabel = 'รายการเก็บเกี่ยว';
            break;

          case 'PAYABLE':
            this.dateLabel = 'วันที่ชำระ';
            this.itemsLabel = 'รายการชำระ';
            this.partnerLabel = 'เจ้าหนี้';
            break;

          case 'RECEIVABLE':
            this.dateLabel = 'วันที่รับชำระ';
            this.itemsLabel = 'รายการรับชำระ';
            this.partnerLabel = 'ลูกหนี้';
            break;

        }

        this.setState({
          data: resp.data,
          detail: resp.detail,
          dateLabel: dateLabel,
          itemsLabel: itemsLabel,
          partnerLabel: partnerLabel,
          loading: false
        });

        this.matchingAccounting.farmID = this.state.data.farm_id;
        this.matchingAccounting.accountingID = this.accountingID;
        this.matchingAccounting.net = this.state.data.net;
        this.matchingAccounting.init();

      },
      error => {
        this.setState({ loading: false });
      }
    )
  }

  onVoidClick(e) {
    e.preventDefault();

    this.setState({
      canceling: true
    });

    AccountingService.voidAcc(this.accountingID).then(
      resp => {

        this.setState({
          data: resp,
          canceling: false
        });

        MessageService.SuccessEvent.next({ message: 'ยกเลิกเรียบร้อยแล้ว' });

        window.history.back();

      },
      error => {
        this.setState({ canceling: false });
      }
    )
  }

  render() {

    let rowCount = 0;

    return (
      <>
        <MainLayout>
          {
            this.state.loading &&

            <main role="main" className="container-fluid">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb no-pdd">
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to={'/my-farm'}>ฟาร์มของฉัน</Link>
                  </li>
                </ol>
              </nav>
              <div className="row">
                <div className="col-md-12 text-center">
                  <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                </div>
              </div>
            </main>
          }
          {
            !this.state.loading &&

            <main role="main" className="flex-shrink-0">
              <div className="container">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb no-pdd">
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to={'/my-farm'}>ฟาร์มของฉัน</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to={'/my-farm/' + this.state.data.farm_id + '?m=accounting'}>{this.state.data.farm_name}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{this.state.data.display_id}</li>
                  </ol>
                </nav>

                <div id="content">
                  <div className="row">
                    <div className="col-md-6">

                      <Link className="btn btn-primary btn-primary-green btn-sm" to={'/my-farm/' + this.state.data.farm_id + '?m=accounting'}>
                        <i className="fas fa-arrow-left"></i>
                      </Link>

                      &nbsp;&nbsp;&nbsp;#{this.state.data.display_id}
                      {
                        this.state.data.status === 'SUCCESS' && ' (เสร็จสิ้น)'
                      }
                      {
                        this.state.data.status === 'VOID' && ' (ยกเลิก)'
                      }
                    </div>
                    {
                      this.state.data.status === 'SUCCESS' &&
                      <div className="col-md-6 text-right">
                        {
                          (this.state.data.payment_type === 'CREDIT' && this.state.data.payment_status === 'OWED') &&
                          <>
                            {
                              /* 'BUY': 'ซื้อ',
                                'SELL': 'ขาย',
                                'EXPENSE': 'รายจ่าย',
                                'INCOME': 'รายรับ',
                                'WITHDRAW': 'เบิกของ',
                                'WITHDRAW_EXPIRED': 'เบิกเน่า' */}

                            <Link className="btn btn-outline-secondary btn-sm" to={'/accounting-accept-payment/' + this.state.data.id}>
                              {
                                (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'EXPENSE') &&
                                'ชำระหนี้'
                              }
                              {
                                (this.state.data.accounting_type === 'SELL' || this.state.data.accounting_type === 'INCOME') &&
                                'รับชำระหนี้'
                              }
                            </Link>

                            &emsp;
                          </>
                        }
                        <button type="button" className="btn btn-outline-secondary btn-sm" data-toggle="modal" data-target={"#cancel"} disabled={this.state.canceling}>
                          {this.state.canceling ? <i className="fa fa-spinner  fa-spin" aria-hidden="true"></i> : <i className="fas fa-ban"></i>}
                        </button>
                      </div>
                    }
                  </div>
                </div>
                <div id="content">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mt-5">รายละเอียดบัญชี</h4>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">ประเภท:  </label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control" placeholder="" readOnly={true} value={Constant.AccountTypeDisplay[this.state.data.accounting_type]} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">{this.dateLabel}: </label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control" placeholder="" readOnly={true} value={moment(this.state.data.accounting_date).format('DD MMM YYYY')} />
                        </div>
                      </div>

                      {
                        this.state.data.plot_name &&
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">แปลง:</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.plot_name} />
                          </div>
                        </div>
                      }
                      {
                        this.state.data.crop_name &&
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">รอบปลูก:</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.crop_name} />
                          </div>
                        </div>
                      }
                      {
                        (this.state.data.accounting_type === "BUY"
                          || this.state.data.accounting_type === "SELL"
                          || this.state.data.accounting_type === "EXPENSE"
                          || this.state.data.accounting_type === "INCOME") &&
                        <>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">ประเภทภาษี:  </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" placeholder="" readOnly={true} value={Constant.VatTypeDisplay[this.state.data.vat_type]} />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">อัตราภาษี (%):  </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.vat} />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">ชำระโดย: </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" placeholder="" readOnly={true} value={Constant.PaymentTypeDisplay[this.state.data.payment_type]} />
                            </div>
                          </div>
                        </>
                      }


                      {
                        (this.state.data.accounting_type === "PAYABLE"
                          || this.state.data.accounting_type === "RECEIVABLE") &&
                        <>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">ชำระโดย: </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" placeholder="" readOnly={true} value={Constant.PaymentTypeDisplay[this.state.data.payment_type]} />
                            </div>
                          </div>
                        </>
                      }

                      {
                        this.state.data.payment_type === 'CREDIT' &&
                        <>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">วันครบกำหนด: </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" placeholder="" readOnly={true}
                                value={this.state.data.payment_date ? moment(this.state.data.payment_date).format('DD MMM YYYY') : ''} />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">{this.partnerLabel}: </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.credit_partner_name || ''} />
                            </div>
                          </div>
                        </>
                      }

                      {
                        (this.state.data.accounting_type === "PAYABLE"
                          || this.state.data.accounting_type === "RECEIVABLE") &&

                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">{this.partnerLabel}: </label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.credit_partner_name || ''} />
                          </div>
                        </div>

                      }


                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                          <h4>รายการซื้อ </h4>
                          <table className="table">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" style={{ maxWidth: '150px' }}>#</th>
                                <th scope="col">รายการ</th>

                                {
                                  (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL') &&
                                  <>
                                    <th scope="col" className="text-right">ราคา/หน่วย (บาท)</th>
                                    <th scope="col" className="text-right">จำนวน</th>
                                    <th scope="col" className="text-right">ส่วนลด (บาท)</th>
                                    <th scope="col" className="text-right">มูลค่ารวม (บาท)</th>
                                  </>
                                }

                                {
                                  (this.state.data.accounting_type === 'INCOME' || this.state.data.accounting_type === 'EXPENSE') &&
                                  <th scope="col" className="text-right">มูลค่า (บาท)</th>
                                }

                                {
                                  (this.state.data.accounting_type === 'WITHDRAW' || this.state.data.accounting_type === 'WITHDRAW_EXPIRED') &&
                                  <>
                                    <th scope="col" className="text-right">ราคา/หน่วย (บาท)</th>
                                    <th scope="col" className="text-right">จำนวน</th>
                                    <th scope="col" className="text-right">มูลค่ารวม (บาท)</th>
                                  </>
                                }
                                {
                                  (this.state.data.accounting_type === 'HARVEST') &&
                                  <>
                                    <th scope="col" className="text-right">จำนวน</th>
                                  </>
                                }

                                {
                                  (this.state.data.accounting_type === "PAYABLE"
                                    || this.state.data.accounting_type === "RECEIVABLE") &&
                                  <>
                                    <th scope="col" className="text-right">ยอดชำระ</th>
                                  </>
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                (this.state.data.accounting_type !== "PAYABLE"
                                  && this.state.data.accounting_type !== "RECEIVABLE") &&
                                this.state.detail.map(d => {
                                  rowCount += 1;
                                  return (
                                    <tr key={d.id}>
                                      <td scope="row">{rowCount}</td>
                                      <td>{d.name}</td>


                                      {
                                        (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL') &&
                                        <>
                                          <td className="text-right">{parseFloat(d.price).toLocaleString()}</td>
                                          <td className="text-right">{parseFloat(d.qty).toLocaleString()}</td>
                                          <td className="text-right">{parseFloat((d.discount || 0)).toLocaleString()}</td>
                                          <td className="text-right">
                                            {parseFloat(d.total).toLocaleString()}
                                            &emsp;
                                            <a href="javascript:void(0);" className="btn btn-outline-secondary btn-sm" onClick={(e) => { this.matchingAccounting.showDialog() }}>
                                              <i className="fas fa-random"></i>
                                            </a>
                                          </td>

                                        </>
                                      }

                                      {
                                        (this.state.data.accounting_type === 'WITHDRAW' || this.state.data.accounting_type === 'WITHDRAW_EXPIRED') &&
                                        <>
                                          <td className="text-right">{parseFloat(d.price).toLocaleString()}</td>
                                          <td className="text-right">{parseFloat(d.qty).toLocaleString()}</td>
                                          <td className="text-right">
                                            {parseFloat(d.total).toLocaleString()}
                                            &emsp;
                                            <a href="javascript:void(0);" className="btn btn-outline-secondary btn-sm" onClick={(e) => { this.matchingAccounting.showDialog() }}>
                                              <i className="fas fa-random"></i>
                                            </a>
                                          </td>

                                        </>
                                      }

                                      {
                                        (this.state.data.accounting_type === 'INCOME' || this.state.data.accounting_type === 'EXPENSE') &&
                                        <>
                                          <td className="text-right">
                                            {parseFloat(d.price).toLocaleString()}
                                            &emsp;
                                            <a href="javascript:void(0);" className="btn btn-outline-secondary btn-sm" onClick={(e) => { this.matchingAccounting.showDialog() }}>
                                              <i className="fas fa-random"></i>
                                            </a>
                                          </td>

                                        </>
                                      }
                                      {
                                        (this.state.data.accounting_type === 'HARVEST') &&
                                        <>
                                          <td className="text-right">{parseFloat(d.qty).toLocaleString()}</td>
                                        </>
                                      }
                                    </tr>
                                  )
                                })
                              }

                              {
                                (this.state.data.accounting_type === "PAYABLE"
                                  || this.state.data.accounting_type === "RECEIVABLE") &&
                                <tr>
                                  <td scope="row">1</td>
                                  <td>{Constant.AccountTypeDisplay[this.state.data.accounting_type]}&nbsp;
                                    {moment(this.state.data.accounting_date).format('DD MMM YYYY')}
                                    &nbsp;({this.state.data.display_ref_id})</td>
                                  <td className="text-right">
                                    {parseFloat(this.state.data.net).toLocaleString()}
                                    &emsp;
                                    <a href="javascript:void(0);" className="btn btn-outline-secondary btn-sm" onClick={(e) => { this.matchingAccounting.showDialog() }}>
                                      <i className="fas fa-random"></i>
                                    </a>
                                  </td>

                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 offset-md-2">

                          {
                            (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL') &&
                            <>
                              <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">ราคารวม</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" id="" placeholder="" readOnly={true} value={parseFloat(this.state.data.total_price).toLocaleString()} />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">ส่วนลด</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" id="" placeholder="" readOnly={true} value={parseFloat(this.state.data.total_discount).toLocaleString()} />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">ส่วนลดเพิ่มเติม</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" id="" placeholder="" readOnly={true} value={parseFloat(this.state.data.other_discount).toLocaleString()} />
                                </div>
                              </div>

                            </>
                          }
                        </div>

                        <div className="col-md-5">
                          {
                            (this.state.data.accounting_type === 'EXPENSE' || this.state.data.accounting_type === 'INCOME') &&
                            <>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">มูลค่ารวม</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_price || 0)).toLocaleString()} />
                                </div>
                              </div>
                            </>
                          }
                          {
                            (this.state.data.accounting_type === 'WITHDRAW' || this.state.data.accounting_type === 'WITHDRAW_EXPIRED') &&
                            <>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">มูลค่ารวม</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_price || 0)).toLocaleString()} />
                                </div>
                              </div>
                            </>
                          }

                          {
                            (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL'
                              || this.state.data.accounting_type === 'EXPENSE' || this.state.data.accounting_type === 'INCOME') &&
                            <>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">ราคารวมก่อนภาษี</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_price_before_vat || 0)).toLocaleString()} />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">ภาษี</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_vat || 0)).toLocaleString()} />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">ราคาสุทธิ</label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.net || 0)).toLocaleString()} />
                                </div>
                              </div>

                            </>
                          }

                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">หมายเหตุ:</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" placeholder="หมายเหตุ" readOnly={true} value={this.state.data.note || ''}></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          }
          <ConfirmDialog title="ยืนยัน" body="ยกเลิกบัญชี ?" refID="cancel" onConfirm={this.onVoidClick} />
          {
            this.state.data &&
            <MatchingAccounting ref={instance => { this.matchingAccounting = instance; }} />
          }

        </MainLayout>
      </>
    );
  }
}

export default AccountingDetail;
