import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const FarmService = {
    getAll,
    getFarm,
    save,
    upload,
    deleteFarm
};

export function getAll() {
    return fetch(`${config.ApiEndpoint}/api/farm`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function getFarm(farmID) {
    return fetch(`${config.ApiEndpoint}/api/farm/${farmID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function save(data) {
    return fetch(`${config.ApiEndpoint}/api/farm`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}

export function upload(id, data) {

    let auth = JSON.parse(localStorage.getItem('auth'));
    let headers = null;
    if (auth) {
        headers = {
            'Authorization': auth.token_type + ' ' + auth.access_token
        };
    }

    return fetch(`${config.ApiEndpoint}/api/farm/${id}/upload`, {
        method: 'POST',
        headers: headers,
        body: data
    }).then(HttpHelper.handleResponse);
}

export function deleteFarm(farmID) {
    return fetch(`${config.ApiEndpoint}/api/farm/${farmID}`, HttpHelper.httpDelete()).then(HttpHelper.handleResponse);
}

