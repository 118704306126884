import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { CropService } from "../services/crop_service";
import Manage from '../main/manage_layout.js';
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';

class HarvestCrop extends Component {


  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      init: true,
      loading: true,
      deleting: false,
      data: {
      },
      harvest: [],
      errors: [],
      pageName: 'เพิ่มบัญชี - เก็บเกี่ยว',
      harvestDate: null,
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onValidate = this.onValidate.bind(this);

    moment.locale('th');
  }

  componentDidMount() {

    CropService.getDetail(this.props.match.params.id).then(
      (resp) => {

        let pageName = this.state.pageName;
        let edit = false;
        if (resp.detail.status === "HARVESTED") {
          pageName = "ปรับปรุงบัญชี - เก็บเกี่ยว";
          edit = true;
        }

        console.log(resp)

        this.setState({
          loading: false,
          init: false,
          data: resp.detail,
          harvest: resp.harvest_detail,
          pageName: pageName,
          edit: edit,
          harvestDate: resp.detail.end_date ? new Date(resp.detail.end_date): null,
        });

      },
      (error) => {
        this.setState({ deleting: false });
      }
    );

  }



  handleFieldChange(item, event) {

    if (item === 'harvest') {

      let index = parseInt(event.target.getAttribute('index'));

      let harvest = this.state.harvest;
      harvest[index][event.target.name] = event.target.value;
      this.setState({
        harvest: harvest
      });

    } else {

      let data = this.state.data ? this.state.data : {};
      data[event.target.name] = event.target.value;

      this.setState({
        data: data
      });

    }
  }

  handleDateChange(name, date) {
    let data = this.state.data ? this.state.data : {};
    const selectedDate = moment(date).toDate()
    data[name] = selectedDate;
    this.setState({ data: data, harvestDate: selectedDate});
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onValidate() {

    let errors = {};
    if (!this.state.data.end_date) {
      errors["end_date"] = "โปรดระบุวันที่เก็บเกี่ยว";
    }

    for (var i = 0; i < this.state.harvest.length; i++) {
      let h = this.state.harvest[i];
      if (!h.qty) {
        errors["qty_" + i] = "โปรดระบุจำนวน";
      }
    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }
  }

  handleSaveClick(event) {
    event.preventDefault();

    this.setState({ loading: true });

    let data = {
      end_date: this.state.data.end_date,
      note: this.state.data.note,
      detail: this.state.harvest
    }

    CropService.harvest(this.props.match.params.id, data).then(
      (resp) => {
        MessageService.SuccessEvent.next({ message: this.state.pageName + ' เรียบร้อยแล้ว' });
        this.props.history.push('/detail-harvest/' + this.props.match.params.id);
      },
      (error) => {
        this.setState({ loading: false });
      }
    )

  }

  render() {

    let index = -1;

    return (
      <>
        <Manage edit={this.state.edit}
          pageName={this.state.pageName}
          onSaveClick={this.onValidate}
          deleting={this.state.deleting}
          init={this.state.init}
          loading={this.state.loading}>
          <br /><br />
          <div className="container" id="content">

            {

              this.state.loading &&

              <main role="main" className="container-fluid">
                <nav aria-label="breadcrumb ">
                  <ol className="breadcrumb no-pdd">
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to={'/my-farm'}>ฟาร์มของฉัน</Link>
                    </li>
                  </ol>
                </nav>

                <div className="row">
                  <div className="col-md-12 text-center">
                    <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                  </div>
                </div>
              </main>
            }

            {

              !this.state.loading &&

              <>
                <div className="row" >
                  <div className="col-md-12">
                    <h4 className="mt-2">รายละเอียดบัญชี</h4>
                    <div className="row">
                      <div className="col-md-12">
                        <form>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">วันที่เก็บเกี่ยว:</label>
                            <div className="col-sm-4">
                              <DatePicker
                                name="end_date"
                                placeholderText="เลือกวันที่"
                                dateFormat="dd MMM yyyy"
                                className="form-control"
                                selected={this.state.harvestDate}
                                onChange={(e) => this.handleDateChange("end_date", e)}></DatePicker>
                              {this.renderErrorFor('end_date')}
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mt-5">รายการเก็บเกี่ยว</h4>
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" width="10%">#</th>
                          <th scope="col" width="70%">รายการ</th>
                          <th scope="col" width="20%">จำนวน</th>
                        </tr>
                      </thead>
                      <tbody>

                        {

                          this.state.harvest.map(h => {

                            index += 1;

                            return (
                              <tr key={h.item_id}>
                                <td scope="row">{index + 1}</td>
                                <td>
                                  เก็บเกี่ยว - {this.state.data.crop_name} ({h.name})
                                </td>
                                <td>
                                  <input type="number" className="form-control" placeholder="0.00" name="qty" index={index}
                                    value={h.qty || ''} onChange={(e) => this.handleFieldChange('harvest', e)} />
                                  {this.renderErrorFor(`qty_${index}`)}
                                </td>
                              </tr>

                            )
                          })
                        }

                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">หมายเหตุ:</label>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" placeholder="หมายเหตุ" name="note"
                        value={this.state.data.note || ''} onChange={(e) => this.handleFieldChange('data', e)}></textarea>
                    </div>
                  </div>
                </div>
              </>
            }

          </div>
          <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.state.pageName} ?`} refID="confirm" onConfirm={this.handleSaveClick} />
        </Manage>
      </>
    );
  }
}

export default HarvestCrop;
