import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ReportService } from '../services/report_service';
import Constant from '../helpers/constant'
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Config from '../helpers/config';

class Report02 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: {

      },
      criteria: {
        loading: true,
        months: [],
        years: []
      },
      seleced: {
        date_type: "MONTH"
      }
    }

    this.onDateTypeChange = this.onDateTypeChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onCriteriaChange = this.onCriteriaChange.bind(this);

    moment.locale('th');
  }

  componentDidMount() {
    let criteria = this.state.criteria;
    criteria.loading = true;

    this.setState({
      criteria: criteria
    });

    ReportService.getRpt02Criteria(this.props.farm.id).then(
      resp => {

        criteria.loading = false;
        criteria.months = resp.months;
        criteria.years = resp.years;

        let seleced = this.state.seleced;
        seleced.month = criteria.months[0].month;
        seleced.year = criteria.months[0].year;

        this.setState({
          criteria: criteria,
          seleced: seleced
        });

        this.getData(seleced);

      },
      error => {
        criteria.loading = false;
        this.setState({
          criteria: criteria
        });

      }
    )
  }

  onDateTypeChange(e) {
    let seleced = {
      date_type: e.target.value
    };
    if (this.state.criteria.years.length > 0) {
      switch (e.target.value) {
        case "MONTH":
          seleced.month = this.state.criteria.months[0].month;
          seleced.year = this.state.criteria.months[0].year;
          break;

        case "QUARTER":
          seleced.quarter = "1";
          seleced.year = this.state.criteria.years[0].year;
          break;

        case "YEAR":
          seleced.year = this.state.criteria.years[0].year;
          break;
      }
    }

    this.setState({
      seleced: seleced
    });

    this.getData(seleced);
  }

  onMonthChange(e) {
    let seleced = this.state.seleced;
    seleced.month = this.state.criteria.months[parseInt(e.target.value)].month;
    seleced.year = this.state.criteria.months[parseInt(e.target.value)].year;

    this.setState({
      seleced: seleced
    });

    this.getData(seleced);
  }

  onCriteriaChange(e) {

    let seleced = this.state.seleced;
    seleced[e.target.name] = e.target.value;
    this.setState({
      seleced: seleced
    });
    this.getData(seleced);
  }

  getData(seleced) {

    let from = null;
    let to = null;

    switch (seleced.date_type) {
      case 'MONTH':

        from = moment(`${seleced.year}-${parseInt(seleced.month) < 10 ? `0${seleced.month}` : seleced.month}-01T00:00:00`);
        to = moment(from).endOf('month');

        break;
      case 'QUARTER':

        let q = [
          [moment(`${seleced.year}-01-01`, 'YYYY-MM-DD'), moment(`${seleced.year}-03-01`, 'YYYY-MM-DD').endOf('month')]
          , [moment(`${seleced.year}-04-01`, 'YYYY-MM-DD'), moment(`${seleced.year}-06-01`, 'YYYY-MM-DD').endOf('month')]
          , [moment(`${seleced.year}-07-01`, 'YYYY-MM-DD'), moment(`${seleced.year}-09-01`, 'YYYY-MM-DD').endOf('month')]
          , [moment(`${seleced.year}-10-01`, 'YYYY-MM-DD'), moment(`${seleced.year}-12-01`, 'YYYY-MM-DD').endOf('month')]
        ]

        let s = q[parseInt(seleced.quarter) - 1];

        from = s[0];
        to = s[1];

        break;
      case 'YEAR':

        from = moment(`${seleced.year}-01-01T00:00:00`);
        to = moment(from).endOf('year');

        break;
    }

    this.setState({
      displayDate: { from: from, to: to },
      loading: true
    });

    ReportService.getRpt02(this.props.farm.id, { from: from.format("YYYY-MM-DDTHH:mm:ss"), to: to.format("YYYY-MM-DDTHH:mm:ss") }).then(
      resp => {
        this.setState({
          loading: false,
          data: resp
        });
      },
      error => {
        this.setState({
          loading: false
        });

      }
    )
  }

  render() {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-2">
            <select className="form-control" name="date_type" value={this.state.seleced.date_type} disabled={this.state.criteria.loading} onChange={this.onDateTypeChange}>
              <option value="MONTH">เดือน</option>
              <option value="QUARTER">ไตรมาส</option>
              <option value="YEAR">ปี</option>
            </select>
          </div>
          {
            (this.state.seleced.date_type === 'MONTH' && this.state.criteria.months.length > 0) &&

            <div className="col-md-4">
              <select className="form-control" name="month" onChange={this.onMonthChange}>
                {
                  this.state.criteria.months.map((d, i) => {
                    return (
                      <option key={i} value={i}>{Constant.MonthNameDisplay[d.month]} {d.year} </option>
                    )
                  })
                }
              </select>
            </div>
          }

          {
            (this.state.seleced.date_type === 'QUARTER' && this.state.criteria.years.length > 0) &&
            <>
              <div className="col-md-2">
                <select className="form-control" name="quarter" onChange={this.onCriteriaChange}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>

              <div className="col-md-2">
                <select className="form-control" name="year" onChange={this.onCriteriaChange}>
                  {
                    this.state.criteria.years.map((d, i) => {
                      return (
                        <option key={i} value={i}> {d.year} </option>
                      )
                    })
                  }
                </select>
              </div>
            </>
          }

          {
            (this.state.seleced.date_type === 'YEAR' && this.state.criteria.years.length > 0) &&

            <div className="col-md-4">
              <select className="form-control" name="year" onChange={this.onCriteriaChange}>
                {
                  this.state.criteria.years.map((d, i) => {
                    return (
                      <option key={i} value={i}> {d.year} </option>
                    )
                  })
                }
              </select>

            </div>
          }
          {
            !this.state.loading &&
            <div className="col-md-6 text-right">
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-02/${this.props.farm.id}/export/xlsx?from=${this.state.displayDate.from.format("YYYY-MM-DD")}&to=${this.state.displayDate.to.format("YYYY-MM-DD")}`}
                className="btn btn-primary btn-csv btn-primary-green btn-120"><i className="fas fa-file-csv"></i> Excel
              </a>
              &emsp;
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-02/${this.props.farm.id}/export/pdf?from=${this.state.displayDate.from.format("YYYY-MM-DD")}&to=${this.state.displayDate.to.format("YYYY-MM-DD")}`}
                className="btn btn-primary btn-pdf btn-danger btn-120"><i className="fas fa-file-pdf"></i> PDF
              </a>
            </div>
          }
        </div>

        <div className="row mt-3">
          {
            this.state.displayDate &&
            <div className="col-md-12">
              <p className="searchTitle">
                ข้อมูล <strong>{this.props.farm.name} </strong>
                , รายงาน <strong> งบกำไรขาดทุน </strong>
                วันที่ {this.state.displayDate.from.format("D MMMM YYYY")} - {this.state.displayDate.to.format("D MMMM YYYY")}
              </p>
            </div>
          }
        </div>
        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }

        {
          !this.state.loading &&

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  งบกำไรขาดทุน
                </div>
                <div className="card-body">
                  <p className="card-title">รายได้จากการดำเนินงาน </p>
                  <div className="report-list">
                    {
                      this.state.data.group_1 &&
                      this.state.data.group_1.map((d, index) => {
                        return (
                          <div className="row" key={index}>
                            <div className="col-md-8 text-muted">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right text-muted">
                              {
                                d.total < 0
                                  ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                  : d.total.toLocaleString()
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="row summary mt-3">
                    <div className="col-md-8"><p>รวมรายได้ </p></div>
                    <div className="col-md-4 text-right">
                      <p>
                        <u>
                          {
                            this.state.data.group_1 &&
                              this.state.data.group_1_sum < 0
                              ? '(' + Math.abs(this.state.data.group_1_sum).toLocaleString() + ')'
                              : this.state.data.group_1_sum.toLocaleString()
                          }
                        </u>
                      </p>
                    </div>
                  </div>
                  <p>หัก ค่าใช้จ่ายผันแปร</p>
                  <div className="report-list">
                    {
                      this.state.data.group_2 &&
                      this.state.data.group_2.map((d, index) => {
                        return (
                          <div className="row" key={index}>
                            <div className="col-md-8 text-muted">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right text-muted">
                              {
                                d.total < 0
                                  ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                  : d.total.toLocaleString()
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="row summary mt-3">
                    <div className="col-md-8"><p>กำไรส่วนเกิน</p></div>
                    <div className="col-md-4 text-right">
                      <p>
                        <u>
                          {
                            this.state.data.group_2 &&
                              this.state.data.group_2_sum < 0
                              ? '(' + Math.abs(this.state.data.group_2_sum).toLocaleString() + ')'
                              : this.state.data.group_2_sum.toLocaleString()
                          }
                        </u>
                      </p>
                    </div>
                  </div>
                  <p>หัก ต้นทุนคงที่ </p>
                  <div className="report-list">
                    <div className="report-list">
                      {
                        this.state.data.group_3 &&
                        this.state.data.group_3.map((d, index) => {
                          return (
                            <div className="row" key={index}>
                              <div className="col-md-8 text-muted">&emsp;{d.name}</div>
                              <div className="col-md-4 text-right text-muted">
                                {
                                  d.total < 0
                                    ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                    : d.total.toLocaleString()
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className="row sub-summary mt-3">
                    <div className="col-md-8"><p>กำไรสุทธิ (ขาดทุนสุทธิ)</p></div>
                    <div className="col-md-4 text-right">
                      <p>
                        {
                          this.state.data.group_3_sum < 0
                            ? '(' + Math.abs(this.state.data.group_3_sum).toLocaleString() + ')'
                            : this.state.data.group_3_sum.toLocaleString()
                        }
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        }

      </>
    );
  }
}
export default Report02;
