import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ReportService } from '../services/report_service';
import Constant from '../helpers/constant'
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Config from '../helpers/config';
import { PlotService } from '../services/plot_service';

class Report03 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      criteria: {
        loading: true
      },
      selected: {

      }
    }

    this.onSelectedChange = this.onSelectedChange.bind(this);

    moment.locale('th');
  }

  componentDidMount() {

    let criteria = this.state.criteria;

    PlotService.getByFarmID(this.props.farm.id).then(
      resp => {
        criteria.loading = false;
        criteria.plots = resp;

        this.setState({
          criteria: criteria
        });
      },
      error => {
        criteria.loading = false;
        this.setState({
          criteria: criteria
        })
      }
    )

  }

  onSelectedChange(e) {
    let selected = this.state.selected;
    selected[e.target.name] = e.target.value;

    this.setState({
      selected: selected
    });
    this.getReportData(selected);
  }

  getReportData(selected) {

    if (selected.plot_id) {
      this.setState({
        loading: true
      });
      ReportService.getRpt03(this.props.farm.id, selected).then(
        resp => {

          this.setState({
            loading: false,
            data: resp
          });
        },
        error => {
          this.setState({
            loading: false
          })
        }
      )
    } else {
      this.setState({
        data: null
      });
    }

  }

  getCrop(e) {
    let criteria = this.state.criteria;
    let selected = this.state.selected;
    selected.crop_id = null;
    if (!e.target.value) {

      criteria.crops = null;
      criteria.loading = false;
      this.setState({
        criteria: criteria,
        selected: selected
      })
      return;
    }

    criteria.loading = true;

    PlotService.getCrops(e.target.value).then(
      resp => {
        criteria.loading = false;
        criteria.crops = resp;
        this.setState({
          criteria: criteria
        });
      },
      error => {
        criteria.loading = false;
        this.setState({
          criteria: criteria
        })
      }
    )

  }

  render() {
    return (
      <>
        <div className="row mt-3">

          <div className="col-md-3">

            <select className="form-control" name="plot_id"
              disabled={this.state.criteria.loading || this.state.loading} value={this.state.selected.plot_id || ''}
              onChange={e => { this.onSelectedChange(e); this.getCrop(e) }}>
              <option value="">เลือกแปลง</option>
              {
                this.state.criteria.plots &&
                this.state.criteria.plots.map((d, i) => {
                  return (
                    <option key={i} value={d.id}>{d.name}</option>
                  )
                })
              }
            </select>

          </div>
          <div className="col-md-3">
            <select className="form-control" name="crop_id" value={this.state.selected.crop_id || ''} disabled={this.state.criteria.loading || this.state.loading}
              onChange={e => { this.onSelectedChange(e); }}>
              <option value="">เลือกรอบปลูก</option>
              {
                this.state.criteria.crops &&
                this.state.criteria.crops.map((d, i) => {
                  return (
                    <option key={i} value={d.id}>{d.name}</option>
                  )
                })
              }
            </select>
          </div>

          {
            (!this.state.loading && this.state.data) &&
            <div className="col-md-6 text-right">
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-03/${this.props.farm.id}/export/xlsx?plot_id=${this.state.selected.plot_id}${this.state.selected.crop_id ? '&crop_id=' + this.state.selected.crop_id : ''}`}
                className="btn btn-primary btn-csv btn-primary-green btn-120"><i className="fas fa-file-csv"></i> Excel
              </a>

              &emsp;
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-03/${this.props.farm.id}/export/pdf?plot_id=${this.state.selected.plot_id}${this.state.selected.crop_id ? '&crop_id=' + this.state.selected.crop_id : ''}`}
                className="btn btn-primary btn-pdf btn-danger btn-120"><i className="fas fa-file-pdf"></i> PDF
              </a>
            </div>
          }
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <p className="searchTitle">ข้อมูล <strong>{this.props.farm.name} </strong>, รายงาน
             <strong> งบต้นทุนผลิต</strong>
              {
                this.state.selected.plot_id &&
                this.state.criteria.plots.filter(d => {
                  return d.id === parseInt(this.state.selected.plot_id)
                }).map(d => {
                  return " " + d.name
                })
              }
              {
                this.state.selected.crop_id &&
                this.state.criteria.crops.filter(d => {
                  return d.id === parseInt(this.state.selected.crop_id)
                }).map(d => {
                  return " " + d.name
                })
              }
            </p>
          </div>
        </div>

        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }
        {
          (!this.state.loading && this.state.data) &&
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  งบต้นทุนผลิต
              </div>
                <div className="card-body">
                  <p className="card-title">วัตถุดิบใช้ไป</p>
                  <div className="report-list">
                    {
                      this.state.data.group_1.map((d, i) => {
                        return (
                          <div key={i} className="row">
                            <div className="col-md-8 text-muted">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right text-muted">
                              {
                                d.total < 0
                                  ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                  : d.total.toLocaleString()
                              }
                            </div>
                          </div>
                        )
                      })
                    }


                  </div>
                  <hr />
                  <div className="row summary">
                    <div className="col-md-8 report-debt-summary-text"><h5>ต้นทุนผลิตรวม</h5></div>
                    <div className="col-md-4 text-right report-debt-summary-value">
                      <h5>
                        <u>
                          {
                            this.state.data.group_1_sum < 0
                              ? '(' + Math.abs(this.state.data.group_1_sum).toLocaleString() + ')'
                              : this.state.data.group_1_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        }
      </>
    );
  }
}
export default Report03;
