import React, { Component } from 'react';
import Manage from '../main/manage_layout.js';
import { AccountChartService } from '../services/account_chart_service';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';


class ManageAccountChart extends Component {

  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      init: true,
      loading: true,
      deleting: false,
      root: null,
      parent: null,
      data: null,
      errors: [],
      pageName: 'ผังบัญชี'
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onValidate = this.onValidate.bind(this);

  }

  componentDidMount() {

    this.isAdd = this.props.match.params.parentID !== undefined;
    this.id = this.isAdd ? this.props.match.params.parentID : this.props.match.params.id;

    AccountChartService.getParent(this.id).then(
      (resp) => {

        let loading = false;
        let init = false;

        if (!this.isAdd) {
          loading = true;
          init = true;
          this.getByID(this.id);
        }

        this.setState({
          root: resp,
          loading: loading,
          init: init
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    )

  }

  getByID(id) {
    AccountChartService.getByID(id).then(
      (resp) => {
        this.setState({
          data: resp,
          edit: true,
          loading: false,
          init: false
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    )
  }

  handleFieldChange(event) {

    let data = this.state.data ? this.state.data : {};
    data[event.target.name] = event.target.value;

    this.setState({
      data: data
    });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onValidate() {

    let errors = {};
    if (!this.state.data || this.state.data.code.length === 0) {
      errors["code"] = "โปรดระบุรหัสผังบัญชี";
    }
    if (!this.state.data || this.state.data.name.length === 0) {
      errors["name"] = "โปรดระบุชื่อผังบัญชี";
    }
    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  handleSaveClick(event) {
    event.preventDefault();
    let data = null;

    this.setState({ loading: true });
    if (this.isAdd) {
      data = {
        code: this.state.data.code,
        name: this.state.data.name,
        farm_id: this.props.match.params.farmID,
        parent_id: this.props.match.params.parentID,
        is_restrict: false
      };
    } else {
      data = this.state.data;
    }
    AccountChartService.save(data).then(
      (resp) => {
        this.success();
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  success() {
    this.setState({ loading: false });
    MessageService.SuccessEvent.next({ message: this.state.pageName + 'เรียบร้อยแล้ว' });
    window.history.back();
  }

  handleDeleteClick(event) {
    this.setState({ deleting: true });
    AccountChartService.deleteByID(this.props.match.params.id).then(
      (resp) => {
        MessageService.SuccessEvent.next({ message: 'ลบผังบัญชีเรียบร้อยแล้ว' });
        this.setState({ deleting: false });
        window.history.back();
      },
      (error) => {
        this.setState({ deleting: false });
      }
    );
  }

  render() {

    return (
      <Manage edit={this.state.edit}
        pageName={this.state.pageName}
        onSaveClick={this.onValidate}
        handleDeleteClick={this.handleDeleteClick}
        deleting={this.state.deleting}
        init={this.state.init}
        loading={this.state.loading}>

        <div className="container">
          <div className="row" id="content-add">
            <div className="col-md-12">
              <h4 className="mt-5">ข้อมูลผังบัญชี </h4>
              <div className="row">

                {!this.state.loading &&
                  <div className="col-md-12">
                    <form>
                      <div className="form-group">
                        <label htmlFor="">รหัสประเภทผังบัญชี:</label>
                        <input type="text" className="form-control" aria-describedby="" placeholder="" value={this.state.root ? this.state.root.code : ''} disabled={true}></input>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">ประเภท:</label>
                        <input type="text" className="form-control" aria-describedby="" placeholder="" value={this.state.root ? this.state.root.name : ''} disabled={true}></input>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">รหัสผังบัญชี:</label>
                        <input type="text" className="form-control" autoComplete="off" name="code" aria-describedby="" placeholder="รหัสผังบัญชี"
                          value={this.state.data ? this.state.data.code : ''} onChange={this.handleFieldChange}></input>
                        {this.renderErrorFor('code')}
                      </div>
                      <div className="form-group">
                        <label htmlFor="">ชื่อผังบัญชี:</label>
                        <input type="text" className="form-control" autoComplete="off" name="name" aria-describedby="" placeholder="ชื่อผังบัญชี"
                          value={this.state.data ? this.state.data.name : ''} onChange={this.handleFieldChange}></input>
                        {this.renderErrorFor('name')}
                      </div>
                    </form>
                  </div>
                }
                {this.state.loading &&
                  <div className="col-md-12 text-center">
                    <span><i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></span>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.state.edit ? 'ปรับปรุง' : 'เพิ่ม'}${this.state.pageName} ?`} refID="confirm" onConfirm={this.handleSaveClick} />
      </Manage >
    );
  }
}

export default ManageAccountChart;
