import React, { Component } from 'react';
import ManagePartner from './manage_partner';

class ManagePayable extends Component {

  render() {

    const partnerID = this.props.match.params.id;
    const farmID = this.props.match.params.farmID;

    return (
      <>
        <ManagePartner partnerType={"PAYABLE"} isAdd={partnerID === undefined} partnerID={partnerID} farmID={farmID} />
      </>
    );
  }
}

export default ManagePayable;
