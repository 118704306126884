import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import { AccountingService } from '../services/accounting_service';
import MessageService from '../services/message_service.js';
import Constant from '../helpers/constant';
import ConfirmDialog from '../main/confirm_dialog';
import { Link } from "react-router-dom";
import FooterLayout from '../main/footer_layout';

class AccountingDebt extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      pageName: null,
      data: {
        accounting_id: this.props.match.params.id
      },
      accounting: {},
      detail: [],
      errors: []
    }

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    moment.locale('th');

  }

  handleFieldChange(event) {

    let data = this.state.data ? this.state.data : {};
    if (event.target.name === 'payment_value') {
      let value = parseFloat(event.target.value);
      if (value > this.state.accounting.outstanding_debt) {
        value = this.state.accounting.outstanding_debt;
      }
      data[event.target.name] = value;
    }
    else {
      data[event.target.name] = event.target.value;
    }

    this.setState({
      data: data
    });
  }

  handleDateChange(field, date) {
    let event = {
      target: {
        name: field,
        value: moment(date).toDate()
      }
    }
    this.handleFieldChange(event);
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  componentDidMount() {
    AccountingService.getPayment(this.state.data.accounting_id).then(
      resp => {
        let pageName = null;
        switch (resp.data.accounting_type) {
          case 'BUY':
          case 'EXPENSE':
            pageName = 'ชำระหนี้';
            break;

          case 'SELL':
          case 'INCOME':
            pageName = 'รับชำระหนี้';
            break;
        }
        let data = this.state.data;
        data.note = resp.data.note;
        this.setState({
          accounting: resp.data,
          detail: resp.detail,
          pageName: pageName,
          data: data,
          loading: false
        })

      },
      error => {
        this.setState({
          loading: false
        })
      }
    );
  }

  onValidate(e) {

    e.preventDefault();
    let errors = {};


    if (!this.state.data.payment_type) {
      errors["payment_type"] = "โปรดระบุชำระโดย";
    }

    if (!this.state.data.payment_date) {
      errors["payment_date"] = "โปรดระบุวันที่ชำระ";
    }

    if (!this.state.data.payment_value) {
      errors["payment_value"] = "โปรดระบุยอดชำระ";
    }


    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true
    })

    AccountingService.savePayment(this.state.data).then(
      resp => {
        let data = {};
        data.note = resp.data.note;
        data.accounting_id = resp.data.id;
        this.setState({
          accounting: resp.data,
          detail: resp.detail,
          data: data,
          loading: false
        });
        MessageService.SuccessEvent.next({ message: 'เพิ่มบัญชี - ' + this.state.pageName + 'เรียบร้อยแล้ว' });
        window.history.back();
      },
      error => {
        this.setState({
          loading: false
        })
      }
    );
  }

  render() {

    let itemIndex = -1;

    return (
      <>
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow-sm">
            <Link className="navbar-brand" to={'/accounting-detail/' + this.state.accounting.id}>
              <span aria-hidden="true">&times;</span>
            </Link>
            <div className="collapse navbar-collapse justify-content-md-center" id="navbarsExample08">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="#">{this.state.loading ?

                    <>
                      <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                      &emsp;
                      <span>
                        กำลังโหลด...
                     </span>
                    </>

                    : 'เพิ่มบัญชี - ' + this.state.pageName}

                  </a>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav ml-auto">
              {
                this.state.accounting.payment_status !== "SUCCESS" &&
                <button type="button" className="btn btn-primary btn-primary-green" disabled={this.state.initCirteria || this.state.loading}
                  onClick={this.onValidate}>
                  {this.state.loading && <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&emsp;</>}เพิ่ม
                </button>
              }

            </ul>
          </nav>
        </header>

        <main role="main" className="flex-shrink-0" style={{ marginTop: '10vh' }}>
          <div id="content" className="container">
            <div className="row" >
              <div className="col-md-12">
                <h4 className="mt-5">รายละเอียดบัญชี</h4>

                {
                  this.state.accounting.payment_status !== "SUCCESS" &&
                  <div className="row">
                    <div className="col-md-12">
                      <form>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            วันที่ชำระ
                        </label>
                          <div className="col-sm-4">
                            <DatePicker
                              locale="th"
                              placeholderText="เลือกวันที่"
                              dateFormat="dd MMM yyyy"
                              className="form-control"
                              selected={this.state.data.payment_date || null}
                              onChange={e => this.handleDateChange('payment_date', e)} />
                            {this.renderErrorFor('payment_date')}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">ชำระโดย</label>
                          <div className="col-md-4">
                            <select className="form-control" name="payment_type" onChange={this.handleFieldChange} value={this.state.data.payment_type || ''}>
                              <option value={""}>เลือกวิธีชำระเงิน</option>
                              <option value={"CASH"}>{Constant.PaymentTypeDisplay["CASH"]}</option>
                              <option value={"BANK"}>{Constant.PaymentTypeDisplay["BANK"]}</option>
                            </select>
                            {this.renderErrorFor('payment_type')}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                }

              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <h4 className="mt-5">{this.itemsLabel}</h4>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">รายการ</th>
                      <th scope="col" style={{ width: '15%' }}>ค้างชำระ</th>
                      <th scope="col" style={{ width: '15%' }}>ยอดชำระ (บาท)</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row" style={{ verticalAlign: 'middle' }}>
                        {this.state.pageName} - {Constant.AccountTypeDisplay[this.state.accounting.accounting_type]}&nbsp;
                        {moment(this.state.accounting.accounting_date).format('DD MMM YYYY')}
                        &nbsp;({this.state.accounting.display_id})
                        </td>
                      <td scope="row" style={{ verticalAlign: 'middle' }}>
                        {parseFloat(this.state.accounting.outstanding_debt).toLocaleString()}
                      </td>

                      <td scope="row">
                        <input type="number" className="form-control" min="0" placeholder="0" name="payment_value" index={itemIndex} value={this.state.data.payment_value || ''} onChange={this.handleFieldChange} />
                        {this.renderErrorFor('payment_value')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>หมายเหตุ:</label>
                  <textarea className="form-control" rows="6" placeholder="หมายเหตุ" name="note" value={this.state.data.note || ''} onChange={this.handleFieldChange}></textarea>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`เพิ่มบัญชี - ${this.state.pageName} ?`} refID="confirm" onConfirm={this.onSubmit} />

        <FooterLayout />

      </>
    );
  }
}

export default AccountingDebt;
