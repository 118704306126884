import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CropService } from "../services/crop_service";
import MainLayout from '../main/main_layout';
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';
import MatchingCrop from '../matching/matching_crop';

class DetailCrop extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: null,
      harvest: []
    }

    moment.locale('th');

  }

  componentDidMount() {

    CropService.getDetail(this.props.match.params.id).then(
      (resp) => {
        this.setState({
          loading: false,
          data: resp.detail,
          harvest: resp.harvest_detail
        });


        this.matchingCrop.farmID = this.state.data.farm_id;
        this.matchingCrop.cropID = this.state.data.id;
        this.matchingCrop.init();

      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }

  voidCrop() {
    this.setState({
      loading: true
    });
    CropService.voidCrop(this.props.match.params.id).then(
      (resp) => {

        MessageService.SuccessEvent.next({ message: 'ลบรอบปลูกเรียบร้อยแล้ว' });
        this.goBack();

      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  goBack() {
    this.props.history.push('/my-farm/' + this.state.data.farm_id + '?m=crop');
  }

  render() {

    let rowIndex = 0;

    return (
      <>
        <MainLayout >
          {

            this.state.loading &&

            <main role="main" className="container-fluid">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb no-pdd">
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to={'/my-farm'}>ฟาร์มของฉัน</Link>
                  </li>
                </ol>
              </nav>

              <div className="row">
                <div className="col-md-12 text-center">
                  <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                </div>
              </div>
            </main>
          }
          {

            !this.state.loading &&

            <main role="main" className="container-fluid">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb no-pdd">
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to={'/my-farm'}>ฟาร์มของฉัน</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="#" onClick={(e) => { this.goBack(); }} >{this.state.data.farm_name} </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">{this.state.data.crop_name}</li>
                </ol>
              </nav>


              <div id="content">
                <div className="row">
                  <div className="col-md-6">
                    <a href="#" onClick={(e) => { this.goBack(); }} className="btn btn-primary btn-primary-green btn-sm">
                      <i className="fas fa-arrow-left"></i>
                    </a>
                    &emsp;
                    {this.state.data.crop_name}
                    {this.state.data.status === 'HARVESTED' && ' (เสร็จสิ้น) '}
                    {this.state.data.status === 'VOID' && ' (ยกเลิก) '}
                  </div>
                  <div className="col-md-6 text-right">
                    {
                      this.state.data.status === 'AWAITING_HARVEST' &&
                      <>
                        <Link to={`/harvest-crop/${this.state.data.id}`} className="btn btn-outline-secondary btn-sm">เก็บเกี่ยว</Link>
                        &emsp;
                        <Link to={`/edit-crop/${this.state.data.farm_id}/${this.state.data.id}`} className="btn btn-outline-secondary btn-sm"><i className="fas fa-pencil-alt"></i></Link>
                      </>
                    }
                    {
                      this.state.data.status === 'HARVESTED' &&
                      <>
                        <Link to={`/harvest-crop/${this.state.data.id}`} className="btn btn-outline-secondary btn-sm"><i className="fas fa-pencil-alt"></i></Link>
                        &emsp;
                        <button type="button" className="btn btn-outline-secondary btn-sm" data-toggle="modal" data-target={"#cancel"}>
                          <i className="fa fa-ban" aria-hidden="true"></i>
                        </button>
                      </>
                    }
                  </div>
                </div>

              </div>
              <div id="content">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mt-3">รายละเอียดรอบปลูก </h4>
                    <div className="form-group row">
                      <label htmlFor="inputPassword" className="col-sm-2 col-form-label">ชื่อรอบปลูก:  </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputPassword" placeholder="" readOnly={true} value={this.state.data.crop_name} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputPassword" className="col-sm-2 col-form-label">แปลง: </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputPassword" placeholder="" readOnly={true} value={this.state.data.plot_name} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputPassword" className="col-sm-2 col-form-label">สินค้า:  </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputPassword" placeholder="" readOnly={true} value={this.state.data.item_name} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputPassword" className="col-sm-2 col-form-label">วันที่เริ่มปลูก:  </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputPassword" placeholder="" readOnly={true} value={moment(this.state.data.start_date).format('DD MMM YYYY')} />
                      </div>
                    </div>
                    {

                      this.state.data.status === 'HARVESTED' &&
                      <>
                        <hr className="mt-3" />
                        <h4 className="mt-3">รายการเก็บเกี่ยว</h4>
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" width="10%">#</th>
                              <th scope="col" width="75%">รายการ</th>
                              <th scope="col" width="10%" className="text-right">จำนวน</th>
                              <th width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.harvest.map(h => {
                                rowIndex += 1;
                                return (
                                  <tr key={h.harvest_id}>
                                    <td>{rowIndex}</td>
                                    <td>เก็บเกี่ยว - {this.state.data.crop_name} ({h.name}) </td>
                                    <td className="text-right">{h.qty}</td>
                                    <td><a href="javascript:void(0);" className="btn btn-outline-secondary btn-sm" onClick={e => this.matchingCrop.showDialog()}><i className="fas fa-random"></i></a></td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </>
                    }

                    <div className="form-group row mt-5">
                      <label htmlFor="inputPassword" className="col-sm-2 col-form-label">หมายเหตุ: </label>
                      <div className="col-sm-10">
                        <textarea placeholder="" className="form-control" rows="6" readOnly={true} value={this.state.data.note || ''}></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </main>

          }

          <ConfirmDialog title="ยืนยัน" body="ลบรอบปลูก ?" refID="cancel" onConfirm={() => this.voidCrop()} />
          {
            this.state.data &&
            <MatchingCrop ref={instance => { this.matchingCrop = instance; }} />
          }
        </MainLayout>
      </>
    );
  }
}

export default DetailCrop;
