import React, { Component } from 'react';
import AccountingManage from "./accounting_manage";
import { PartnerService } from "../services/partner_service";

class AccountingExpense extends Component {

  constructor(props) {
    super(props)


    this.farmID = this.props.match.params.farmID;
    this.accountingType = "EXPENSE";


    this.state = {
      init: true,
      partners: []
    }
  }

  componentDidMount() {
    PartnerService.getByFarmID("PAYABLE", this.farmID).then(
      (resp) => {
        this.setState({
          init: false,
          partners: resp
        })
      }
    );
  }

  render() {

    return (
      <>
        <AccountingManage
          history={this.props.history}
          accountingType={this.accountingType}
          isAdd={this.isAdd}
          farmID={this.farmID}
          pageName={"เพิ่มบัญชี - รายจ่าย"}
          partners={this.state.partners}
          init={this.state.init}
          partnerLabel={"เจ้าหนี้"} />
      </>
    );
  }
}

export default AccountingExpense;
