import React, { Component } from 'react';
import '../assets/css/register.css';
import { Link } from "react-router-dom";
import HttpHelper from '../helpers/http_helper.js';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';
import { AuthService } from '../services/auth_service.js';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: "",
      errors: []
    }

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  handleFieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleCheckChange(event) {
    this.setState({
      [event.target.name]: event.target.checked ? event.target.value : ''
    });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onSubmit() {
    this.setState({
      loading: true
    });
    AuthService.forgotPassword({
      email: this.state.email
    }).then(
      resp => {
        HttpHelper.setAuth(resp);
        this.setState({ loading: false });
        MessageService.SuccessEvent.next({ message: 'ส่งอีเมลตั้งรหัสผ่านใหม่เรียบร้อยแล้ว' });
        this.props.history.push('/');
      },
      error => {
        this.setState({
          loading: false
        });
      }
    )
  }

  onValidate() {
    let errors = {};

    if (this.state.email.length === 0) {
      errors["email"] = "โปรดระบุอีเมล";
    } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email))) {
      errors["email"] = "ระบุอีเมลไม่ถูกต้อง";
    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }



  render() {
    return (

      <form className="form-register" ref={form => this.formEl = form} onSubmit={this.onSubmit} noValidate autoComplete="off">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4>ลืมรหัสผ่าน?</h4>
            <p className="text-muted">กรุณาระบุอีเมลที่ถูกใช้งานในระบบเพื่อทำการตั้งรหัสผ่านใหม่</p>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">อีเมล:</label>
          <input type="text" className="form-control" id="email" name="email" onChange={this.handleFieldChange} placeholder="อีเมล"></input>
          {this.renderErrorFor('email')}
        </div>
        <br />
        <button className="btn btn-primary btn-block btn-primary-green" type="button" disabled={this.state.loading} onClick={this.onValidate}>
          {this.state.loading && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &emsp;</span>}ตั้งรหัสผ่านใหม่
        </button>
        <div className="row">
          <div className="col-md-12 text-center" id="login-link">
            <Link to={""} >เข้าสู่ระบบ</Link>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body="ส่งอีเมลตั้งรหัสผ่านใหม่" refID="confirm" onConfirm={this.onSubmit} />
      </form>


    );
  }
}

export default ForgotPassword;
