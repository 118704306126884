import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CropService } from "../services/crop_service";
import MainLayout from '../main/main_layout';
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';

class DetailHarvest extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: null,
      harvest: []
    }

    moment.locale('th');
  }

  componentDidMount() {

    CropService.getDetail(this.props.match.params.id).then(
      (resp) => {
        this.setState({
          loading: false,
          data: resp.detail,
          harvest: resp.harvest_detail
        });

      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }

  goBack() {
    this.props.history.push('/my-farm/' + this.state.data.farm_id + '?m=crop');
  }

  render() {

    let rowIndex = 0;

    return (
      <>
        <MainLayout >
          {

            this.state.loading &&

            <main role="main" className="container-fluid">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb no-pdd">
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to={'/my-farm'}>ฟาร์มของฉัน</Link>
                  </li>
                </ol>
              </nav>

              <div className="row">
                <div className="col-md-12 text-center">
                  <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                </div>
              </div>
            </main>
          }
          {

            !this.state.loading &&

            <main role="main" className="container-fluid">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb no-pdd">
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to={'/my-farm'}>ฟาร์มของฉัน</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="#" onClick={(e) => { this.goBack(); }} >{this.state.data.farm_name} </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">{this.state.data.crop_name}</li>
                </ol>
              </nav>


              <div id="content">
                <div className="row">
                  <div className="col-md-6">
                    <a href="#" onClick={(e) => { this.goBack(); }} className="btn btn-primary btn-primary-green btn-sm">
                      <i className="fas fa-arrow-left"></i></a>&emsp;{this.state.data.crop_name} {this.state.data.status === 'HARVESTED' && ' (เสร็จสิ้น) '}
                  </div>
                  <div className="col-md-6 text-right">
                    <Link to={`/harvest-crop/${this.state.data.id}`} className="btn btn-outline-secondary btn-sm"><i className="fas fa-pencil-alt"></i></Link>
                  </div>
                </div>

              </div>
              <div id="content">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mt-3">รายละเอียดรอบปลูก </h4>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">ชื่อรอบปลูก:  </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.crop_name} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">แปลง: </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.plot_name} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">สินค้า:  </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.item_name} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">วันที่เริ่มปลูก:  </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={moment(this.state.data.start_date).format('DD MMM YYYY')} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">หมายเหตุ: </label>
                      <div className="col-sm-10">
                        <textarea placeholder="" className="form-control" rows="6" readOnly={true} value={this.state.data.note}></textarea>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div id="content" className="mt-1">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mt-3">รายละเอียดการเก็บเกี่ยว</h4>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">วันที่เก็บเกี่ยว:</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={moment(this.state.data.end_date).format('DD MMM YYYY')} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">อ้างอิง: </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={this.state.data.item_name} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

          }



        </MainLayout>
      </>
    );
  }
}

export default DetailHarvest;
