import React, { Component } from 'react';
import ConfirmDialog from '../main/confirm_dialog';

class Manage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: []
    }

    this.handleBackClick = this.handleBackClick.bind(this);

  }

  handleBackClick(event) {
    window.history.back();
  }

  render() {

    const { pageName, onSaveClick, handleDeleteClick, loading, edit, init, deleting } = this.props;

    return (
      <>
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow-sm">
            <a className="navbar-brand" href="javascript:void(0);" onClick={this.handleBackClick}>
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </a>
            <div className="collapse navbar-collapse justify-content-md-center" id="navbarsExample08">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" >{(init) ? 'กำลังโหลด' : pageName}</a>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav ml-auto">
              <button type="button" className="btn btn-primary btn-primary-green" disabled={loading} onClick={onSaveClick}>
                {loading && !init && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &emsp;</span>} {!edit ? "เพิ่ม" : "ปรับปรุง"}
              </button>
            </ul>
          </nav>
        </header>
        <main role="main" className="flex-shrink-0" style={{ "paddingTop": "50px" }}>

          {this.props.children}

          {
            (edit && handleDeleteClick) &&
            <div className="row">
              <div className="col-md-12 text-center mt-4">
                <a href="javascript:void(0);" disabled={deleting} data-toggle="modal" data-target="#cancel" >{deleting && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>} ลบ{pageName}นี้</a>
              </div>
            </div>
          }

          <br />
          <br />
        </main>
        <ConfirmDialog title="ยืนยัน" body={`ลบ${pageName}นี้ ?`} refID="cancel" onConfirm={handleDeleteClick} />
      </>
    );
  }
}

export default Manage;
