import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Config from '../helpers/config';

import { ReportService } from '../services/report_service';

class Report01 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      criteria: {
        accounting_date: new Date()
      },
      data: {}
    }
    moment.locale('th');


    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.getReportData()
  }

  getReportData() {
    this.setState({
      loading: true
    })
    ReportService.getRpt01(this.props.farm.id, this.state.criteria).then(
      resp => {
        this.setState({
          data: resp,
          loading: false
        })
      },
      error => {
        this.setState({
          loading: false
        })
      }
    );

  }

  handleDateChange(e) {
    let criteria = this.state.criteria;
    criteria.accounting_date = e;
    this.setState({
      criteria: criteria
    });
    this.getReportData();
  }

  render() {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-6">
            <DatePicker
              locale="th"
              placeholderText="เลือกวันที่"
              dateFormat="dd MMM yyyy"
              className="form-control"
              selected={this.state.criteria.accounting_date || null}
              onChange={e => this.handleDateChange(e)}
            />
          </div>
          {
            !this.state.loading &&
            <div className="col-md-6 text-right">
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-01/${this.props.farm.id}/export/xlsx?date=${moment(this.state.criteria.accounting_date).format("YYYY-MM-DD")}`}
                className="btn btn-primary btn-pdf btn-primary-green btn-120"><i className="fas fa-file-csv"></i> Excel
              </a>
              &emsp;
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-01/${this.props.farm.id}/export/pdf?date=${moment(this.state.criteria.accounting_date).format("YYYY-MM-DD")}`}
                className="btn btn-primary btn-pdf btn-danger btn-120"><i className="fas fa-file-pdf"></i> PDF
              </a>
            </div>
          }

        </div>
        <div className="row  mt-3">
          <div className="col-md-12">
            <p className="searchTitle">ข้อมูล <strong>{this.props.farm.name} </strong>, รายงาน <strong>งบแสดงฐานะทางการเงิน</strong> วันที่ {moment(this.state.criteria.accounting_date).format("DD MMM YYYY")}</p>
          </div>
        </div>

        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }
        {
          !this.state.loading &&
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  สินทรัพย์
              </div>
                <div className="card-body">
                  <h5 className="card-title">สินทรัพย์หมุนเวียน</h5>
                  <div className="report-list">
                    {
                      !this.state.loading &&
                      this.state.data.group_1.map(d => {
                        return (
                          <div className="row  text-muted" key={"group_1_" + d.name}>
                            <div className="col-md-8">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right">{
                              d.total < 0
                                ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                : d.total.toLocaleString()
                            }</div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="row summary mt-2">
                    <div className="col-md-8"><h5>รวมสินทรัพย์หมุนเวียน</h5></div>
                    <div className="col-md-4 text-right">
                      <h5>
                        <u>
                          {
                            !this.state.loading &&
                              this.state.data.group_1_sum < 0
                              ? '(' + Math.abs(this.state.data.group_1_sum).toLocaleString() + ')'
                              : this.state.data.group_1_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                  <hr />
                  <h5>สินทรัพย์หมุนเวียน</h5>
                  <div className="report-list">
                    {
                      !this.state.loading &&
                      this.state.data.group_2.map(d => {
                        return (
                          <div className="row  text-muted" key={"group_2_" + d.name}>
                            <div className="col-md-8">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right">
                              {
                                d.total < 0
                                  ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                  : d.total.toLocaleString()
                              }
                            </div>
                          </div>
                        )
                      })
                    }

                  </div>
                  <div className="row summary mt-2">
                    <div className="col-md-8"><h5>รวมสินทรัพย์หมุนเวียน</h5></div>
                    <div className="col-md-4 text-right">
                      <h5>
                        <u>
                          {
                            !this.state.loading &&
                              this.state.data.group_2_sum < 0
                              ? '(' + Math.abs(this.state.data.group_2_sum).toLocaleString() + ')'
                              : this.state.data.group_2_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                  <hr />
                  <div className="row summary mt-2">
                    <div className="col-md-8 report-asset-summary-text"><h5>รวมสินทรัพย์</h5></div>
                    <div className="col-md-4 text-right report-asset-summary-value">
                      <h5>
                        <u>
                          {
                            !this.state.loading &&
                              this.state.data.group_1_2_sum < 0
                              ? '(' + Math.abs(this.state.data.group_1_2_sum).toLocaleString() + ')'
                              : this.state.data.group_1_2_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  หนี้สินและทุน
                      </div>
                <div className="card-body">
                  <h5 className="card-title">สินทรัพย์หมุนเวียน</h5>
                  <div className="report-list">
                    {
                      !this.state.loading &&
                      this.state.data.group_3.map(d => {
                        return (
                          <div className="row text-muted" key={"group_3_" + d.name}>
                            <div className="col-md-8">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right">
                              {
                                d.total < 0
                                  ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                  : d.total.toLocaleString()
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="row summary mt-2">
                    <div className="col-md-8"><h5>รวมสินทรัพย์หมุนเวียน</h5></div>
                    <div className="col-md-4 text-right">
                      <h5>
                        <u>
                          {
                            !this.state.loading &&
                              this.state.data.group_3_sum < 0
                              ? '(' + Math.abs(this.state.data.group_3_sum).toLocaleString() + ')'
                              : this.state.data.group_3_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                  <hr />
                  <h5 className="card-title">หนี้สินไม่หมุนเวียน</h5>
                  <div className="report-list">
                    {
                      !this.state.loading &&
                      this.state.data.group_4.map(d => {
                        return (
                          <div className="row text-muted" key={"group_4_" + d.name}>
                            <div className="col-md-8">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right">
                              {
                                d.total < 0
                                  ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                  : d.total.toLocaleString()
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="row summary mt-2">
                    <div className="col-md-8"><h5>รวมหนี้สิน</h5></div>
                    <div className="col-md-4 text-right">
                      <h5>
                        <u>
                          {
                            !this.state.loading &&
                              this.state.data.group_4_sum < 0
                              ? '(' + Math.abs(this.state.data.group_4_sum).toLocaleString() + ')'
                              : this.state.data.group_4_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                  <hr />
                  <h5 className="card-title">ส่วนของผู้ถือหุ้น</h5>
                  <div className="report-list">
                    {
                      !this.state.loading &&
                      this.state.data.group_5.map(d => {
                        return (
                          <div className="row text-muted" key={"group_5_" + d.name}>
                            <div className="col-md-8">&emsp;{d.name}</div>
                            <div className="col-md-4 text-right">
                              {
                                d.total < 0
                                  ? '(' + Math.abs(d.total).toLocaleString() + ')'
                                  : d.total.toLocaleString()
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="row summary mt-2">
                    <div className="col-md-8"><h5>รวมส่วนชองผู้ถือหุ้น</h5></div>
                    <div className="col-md-4 text-right">
                      <h5>
                        <u>
                          {
                            !this.state.loading &&
                              this.state.data.group_5_sum < 0
                              ? '(' + Math.abs(this.state.data.group_5_sum).toLocaleString() + ')'
                              : this.state.data.group_5_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                  <hr />
                  <div className="row summary mt-2">
                    <div className="col-md-8 report-debt-summary-text"><h5>รวมหนี้สินและส่วนของผู้ถือหุ้น</h5></div>
                    <div className="col-md-4 text-right report-debt-summary-value">
                      <h5>
                        <u>
                          {
                            !this.state.loading &&
                              this.state.data.group_3_4_5_sum < 0
                              ? '(' + Math.abs(this.state.data.group_3_4_5_sum).toLocaleString() + ')'
                              : this.state.data.group_3_4_5_sum.toLocaleString()
                          }
                        </u>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        }
      </>
    );
  }
}

export default Report01;
