import React, { Component } from 'react';
import Manage from '../main/manage_layout.js';
import { PartnerService } from '../services/partner_service';
import { MasterService } from '../services/master_service';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';

class ManagePartner extends Component {

  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      init: true,
      loading: true,
      deleting: false,
      data: {
        farm_id: '',
        partner_type: '',
        person_type: '',
        name_th: '',
        name_en: '',
        image: '',
        partner_code: '',
        id_card: '',
        email: '',
        tel: '',
        fax: '',
        address: '',
        amphure_id: '',
        district_id: '',
        province_id: '',
        zipcode: '',
        registered_capital: ''
      },
      errors: [],
      pageName: this.props.partnerType === 'PAYABLE' ? 'เจ้าหนี้' : 'ลูกหนี้',
      selectMode: 1
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  componentDidMount() {

    let loading = false;
    let init = false;
    let province = [];

    MasterService.getProvinces().then(
      (resp) => {

        province = resp;

        if (!this.props.isAdd) {
          loading = true;
          init = true;
          this.getByID(this.props.partnerID);
        }

        this.setState({
          province: province,
          loading: loading,
          init: init
        });

      },
      (error) => {
        this.setState({
          loading: false,
          init: false
        });
      }
    );

  }

  getByID(id) {
    PartnerService.getByID(id).then(
      (resp) => {

        this.setState({
          data: resp,
          edit: true,
          loading: false,
          init: false
        });

        this.getAmphures();
        this.getDistricts();

      },
      (error) => {
        this.setState({ loading: false });
      }
    )
  }

  handleFieldChange(event) {

    let data = this.state.data ? this.state.data : {};
    data[event.target.name] = event.target.value;

    if (event.target.name === 'image') {
      let fileUpload = document.getElementById("image");
      let fileName = null;
      if (fileUpload && fileUpload.files.length > 0) {
        fileName = fileUpload.files[0].name;
        this.file = fileUpload.files[0];
      }
      data[event.target.name] = fileName;
    } else if (event.target.name === 'person_type' && event.target.value === 'JURISTIC_PERSON') {
      data.registered_capital = '';
    }

    this.setState({
      data: data
    });

  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onProvinceChange() {

    let data = this.state.data;
    data.zipcode = "";
    data.amphure_id = "";
    data.district_id = "";

    this.setState({ amphure: null, district: null, data: data });
    this.getAmphures();
  }

  getAmphures() {

    MasterService.getAmphures(this.state.data.province_id).then(
      (resp) => {
        this.setState({ amphure: resp });
      },
      (error) => {

      }
    );
  }

  onAmphureChange() {

    let data = this.state.data;
    data.zipcode = "";
    data.district_id = "";

    this.setState({ district: null, data: data });
    this.getDistricts();

  }

  getDistricts() {

    MasterService.getDistricts(this.state.data.amphure_id).then(
      (resp) => {
        this.setState({ district: resp });
      },
      (error) => {

      }
    );
  }

  getZipCode() {

    if (this.state.data.district_id) {
      const dist = this.state.district.filter((d) => {
        return d.id === parseInt(this.state.data.district_id);
      })[0];

      MasterService.getZipCode(dist.code).then(
        (resp) => {
          let data = this.state.data;
          data.zipcode = resp.zipcode;
          this.setState({ data: data });
        },
        (error) => {

        }
      );
    }
  }

  onValidate() {

    let errors = {};
    if (!this.state.data.name_th) {
      errors["name_th"] = `โปรดระบุชื่อ${this.state.pageName} (ไทย)`;
    }
    if (!this.state.data.id_card) {
      errors["id_card"] = "โปรดระบุรหัสประจำตัวผู้เสียภาษี";
    }

    if (!this.state.data.partner_code) {
      errors["partner_code"] = "โปรดระบุรหัสเจ้าหนี้";
    }
    if (!this.state.data.person_type) {
      errors["person_type"] = "โปรดกรุณาเลือกสถานะบุคคล";
    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  handleSaveClick(event) {
    event.preventDefault();
    this.setState({ loading: true });
    let data = this.state.data;
    if (!this.props.isAdd) {
      data.id = this.props.partnerID;
    } else {
      data.farm_id = this.props.farmID;
      data.partner_type = this.props.partnerType;
    }

    PartnerService.save(data).then(
      (resp) => {
        if (this.file) {
          this.upload(resp, this.file);
        } else {
          this.success();
        }
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  upload(resp, fileUpload) {

    let formData = new FormData();
    formData.append('file', fileUpload);

    PartnerService.upload(resp.id, formData).then(
      (resp) => {
        this.success();
      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }

  success() {
    this.setState({ loading: false });
    MessageService.SuccessEvent.next({ message: this.state.pageName + 'เรียบร้อยแล้ว' });
    window.history.back();
  }

  handleDeleteClick(event) {
    this.setState({ deleting: true });
    PartnerService.deleteByID(this.props.partnerID).then(
      (resp) => {
        MessageService.SuccessEvent.next({ message: `ลบ${this.state.pageName}เรียบร้อยแล้ว` });
        this.setState({ deleting: false });
        window.history.back();
      },
      (error) => {
        this.setState({ deleting: false });
      }
    );
  }


  convert(value) {
    if (value === undefined) {
      return 0;
    }
    if (isNaN(value)) {
      return 0;
    }
    return parseFloat(value);
  }

  onRenderAccountChartItem() {
    return this.state.accountCharts.map(a => {
      return (
        <option key={a.id} value={a.id} >{a.name}</option>
      )
    });
  }

  render() {

    return (
      <Manage edit={this.state.edit}
        pageName={this.state.pageName}
        onSaveClick={this.onValidate}
        handleDeleteClick={this.handleDeleteClick}
        deleting={this.state.deleting}
        init={this.state.init}
        loading={this.state.loading}>

        <div className="container">
          <div className="row" id="content-add">
            <div className="col-md-12">
              <h4 className="mt-5">ข้อมูล{this.state.pageName}</h4>
              <div className="row">

                {!this.state.loading &&
                  <div className="col-md-12">

                    <form autoComplete="off">
                      <div className="form-group">
                        <label htmlFor="">สถานะบุคคล:</label><br />
                        <div className="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="customRadioInline1" className="custom-control-input" name="person_type"
                            value="NATURAL_PERSON" checked={this.state.data.person_type === 'NATURAL_PERSON'} onChange={this.handleFieldChange} />
                          <label className="custom-control-label" htmlFor="customRadioInline1">บุคคลธรรมดา</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="customRadioInline2" className="custom-control-input" name="person_type"
                            value="JURISTIC_PERSON" checked={this.state.data.person_type === 'JURISTIC_PERSON'} onChange={this.handleFieldChange} />
                          <label className="custom-control-label" htmlFor="customRadioInline2">นิติบุคคล</label>
                        </div>
                        {this.hasErrorFor('person_type') && <span><br />{this.renderErrorFor('person_type')}</span>}
                      </div>
                      <h4>ข้อมูลบุคคล</h4>
                      <div className="form-group">
                        <label >ชื่อ{this.state.pageName} (ไทย):</label>
                        <input type="text" className="form-control" placeholder={`ชื่อ${this.state.pageName} (ไทย)`}
                          name="name_th" value={this.state.data.name_th || ''} onChange={this.handleFieldChange} />
                        {this.renderErrorFor('name_th')}
                      </div>
                      <div className="form-group">
                        <label >ชื่อ{this.state.pageName} (อังกฤษ): </label>
                        <input type="text" className="form-control" placeholder={`ชื่อ${this.state.pageName} (อังกฤษ)`}
                          name="name_en" value={this.state.data.name_en || ''} onChange={this.handleFieldChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">รูปภาพ:</label>
                        <div className="custom-file">
                          <input type="file" id="image" className="custom-file-input" name="image" accept="image/*" onChange={e => { this.handleFieldChange(e); }}></input>
                          <label id="fileName" className="custom-file-label" htmlFor="image">{this.state.data.image ? this.state.data.image : 'เลือกไฟล์'}</label>
                        </div>
                      </div>
                      <div className="form-group">
                        <label >รหัส{this.state.pageName}:</label>
                        <input type="text" className="form-control" placeholder={`รหัส${this.state.pageName}`}
                          name="partner_code" value={this.state.data.partner_code || ''} onChange={this.handleFieldChange} />
                        {this.renderErrorFor('partner_code')}
                      </div>
                      <div className="form-group">
                        <label >รหัสประจำตัวผู้เสียภาษี:</label>
                        <input type="text" className="form-control" placeholder="รหัสประจำตัวผู้เสียภาษี"
                          name="id_card" value={this.state.data.id_card || ''} onChange={this.handleFieldChange} />
                        {this.renderErrorFor('id_card')}
                      </div>
                      {
                        this.state.data.person_type === 'JURISTIC_PERSON' &&
                        <div className="form-group">
                          <label>ทุนจดทะเบียน:</label>
                          <input type="number" className="form-control" placeholder="ทุนจดทะเบียน"
                            name="registered_capital" value={this.state.data.registered_capital || ''} onChange={this.handleFieldChange} />
                        </div>
                      }
                      <h4>ติดต่อ</h4>
                      <div className="form-group">
                        <label >อีเมล:</label>
                        <input type="text" className="form-control" placeholder="อีเมล"
                          name="email" value={this.state.data.email || ''} onChange={this.handleFieldChange} />
                      </div>
                      <div className="form-group">
                        <label >โทรศัพท์:</label>
                        <input type="text" className="form-control" placeholder="โทรศัพท์"
                          name="tel" value={this.state.data.tel || ''} onChange={this.handleFieldChange} />
                      </div>
                      <div className="form-group">
                        <label >แฟกซ์:</label>
                        <input type="text" className="form-control" placeholder="แฟกซ์"
                          name="fax" value={this.state.data.fax || ''} onChange={this.handleFieldChange} />
                      </div>
                      <h4>ที่อยู่</h4>
                      <div className="form-group">
                        <label >ที่อยู่:</label>
                        <input type="text" className="form-control" placeholder="ที่อยู่"
                          name="address" value={this.state.data.address || ''} onChange={this.handleFieldChange} />
                      </div>
                      <div className="form-group" >
                        <label >จังหวัด:</label>
                        <select className="form-control" name="province_id" value={this.state.data.province_id || ''} onChange={(e) => { this.handleFieldChange(e); this.onProvinceChange() }}>
                          <option value="">จังหวัด</option>
                          {
                            this.state.province.map(p => {
                              return (
                                <option key={p.id} value={p.id} >{p.name_th}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      {
                        this.state.data.province_id &&
                        <div className="form-group">
                          <label >อำเภอ/เขต:</label>
                          <select className="form-control" name="amphure_id" disabled={!this.state.amphure} value={this.state.data.amphure_id || ''} onChange={(e) => { this.handleFieldChange(e); this.onAmphureChange() }}>
                            <option value="">อำเภอ/เขต</option>
                            {
                              this.state.amphure &&
                              this.state.amphure.map(a => {
                                return (
                                  <option key={a.id} value={a.id} >{a.name_th}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      }
                      {
                        this.state.data.amphure_id &&
                        <div className="form-group">
                          <label >ตำบล:</label>
                          <select className="form-control" name="district_id" disabled={!this.state.district} value={this.state.data.district_id || ''} onChange={(e) => { this.handleFieldChange(e); this.getZipCode() }}>
                            <option value="">ตำบล</option>
                            {
                              this.state.district &&
                              this.state.district.map(d => {
                                return (
                                  <option key={d.id} value={d.id} >{d.name_th}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      }

                      {
                        this.state.data.district_id &&
                        <div className="form-group">
                          <label >ไปรษณีย์:</label>
                          <input type="text" className="form-control" readOnly value={this.state.data.zipcode || ''} placeholder="ไปรษณีย์" />
                        </div>
                      }

                    </form>
                  </div>
                }
                {
                  this.state.loading &&
                  <div className="col-md-12 text-center">
                    <span><i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></span>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.state.edit ? 'ปรับปรุง' : 'เพิ่ม'}${this.state.pageName} ?`} refID="confirm" onConfirm={this.handleSaveClick} />
      </Manage >
    );
  }
}

export default ManagePartner;
