class Constant {
    static AccountTypeDisplay = {
        'BUY': 'ซื้อ',
        'SELL': 'ขาย',
        'EXPENSE': 'รายจ่าย',
        'INCOME': 'รายรับ',
        'WITHDRAW': 'เบิกของ',
        'WITHDRAW_EXPIRED': 'เบิกเน่า',
        'HARVEST': 'เก็บเกี่ยว',
        'PAYABLE': 'ชำระหนี้',
        'RECEIVABLE': 'รับชำระหนี้'
    }

    static StatusDisplay = {
        'SUCCESS': 'เสร็จสิ้น',
        'VOID': 'ยกเลิก'
    }

    static VatTypeDisplay = {
        'EXCLUDE_VAT': 'มูลค่าไม่รวมภาษี',
        'INCLUDE_VAT': 'ไม่มีภาษี/มูลค่ารวมภาษี'
    }
    static PaymentTypeDisplay = {
        'CASH': 'เงินสด',
        'BANK': 'เงินฝากธนาคาร',
        'CREDIT': 'เงินเชื่อ'
    }

    static MonthNameDisplay = {
        1: "มกราคม",
        2: "กุมภาพันธ์",
        3: "มีนาคม",
        4: "เมษายน",
        5: "พฤษภาคม",
        6: "มิถุนายน",
        7: "กรกฎาคม",
        8: "สิงหาคม",
        9: "กันยายน",
        10: "ตุลาคม",
        11: "พฤศจิกายน",
        12: "ธันวาคม"
    };

}

export default Constant;