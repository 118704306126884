import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const ReportService = {
    getRpt01,
    getRpt02Criteria,
    getRpt02,
    getRpt03,
    getRpt04,
    getRpt05,
    getRpt06,
    getRpt07,
    getRpt08,
    getRpt09
};

export function getRpt01(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-01/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}

export function getRpt02Criteria(farmID) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-02/${farmID}/criteria`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function getRpt02(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-02/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}
export function getRpt03(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-03/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}
export function getRpt04(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-04/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}

export function getRpt05(farmID) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-05/${farmID}`, HttpHelper.httpPost()).then(HttpHelper.handleResponse);
}

export function getRpt06(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-06/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}
export function getRpt07(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-07/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}

export function getRpt08(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-08/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}

export function getRpt09(farmID, search) {
    return fetch(`${config.ApiEndpoint}/api/report/rpt-09/${farmID}`, HttpHelper.httpPost(search)).then(HttpHelper.handleResponse);
}