import React, { Component } from 'react';
import Manage from '../main/manage_layout.js';
import { CropService } from '../services/crop_service';
import { PlotService } from '../services/plot_service';
import { ItemService } from '../services/item_service';
import MessageService from '../services/message_service.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import ConfirmDialog from "../main/confirm_dialog";

class ManageCrop extends Component {

  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      init: true,
      loading: true,
      deleting: false,
      items: [],
      data: {

        name: '',
        start_date: null,
        end_date: '',
        plot_id: '',
        item_id: '',
        note: '',

      },
      errors: [],
      pageName: 'รอบปลูก',
      selectMode: 1
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.isAdd = this.props.match.params.id === undefined;

    moment.locale('th');
  }

  componentDidMount() {


    ItemService.getByFarmID(this.props.match.params.farmID).then(
      (resp) => {
        this.items = resp;
        this.checksuccess();
      },
      (error) => {
        this.setState({ loading: false });
      }
    )

    PlotService.getByFarmID(this.props.match.params.farmID).then(
      (resp) => {
        this.plots = resp;
        this.checksuccess();
      },
      (error) => {
        this.setState({ loading: false });
      }
    )


  }

  checksuccess() {

    if (this.items && this.plots) {
      if (!this.isAdd) {

        this.getByID(this.props.match.params.id);

      } else {

        this.setState({
          items: this.items,
          plots: this.plots,
          loading: false,
          init: false
        });

      }
    }

  }

  getByID(id) {
    CropService.getByID(id).then(
      (resp) => {


        resp.start_date = moment(resp.start_date).toDate();

        this.setState({
          data: resp,
          items: this.items,
          plots: this.plots,
          edit: true,
          loading: false,
          init: false
        });

      },
      (error) => {
        this.setState({ loading: false });
      }
    )
  }

  handleFieldChange(event) {

    let data = this.state.data ? this.state.data : {};
    data[event.target.name] = event.target.value;

    this.setState({
      data: data
    });

  }

  handleDateChange(date) {
    let data = this.state.data ? this.state.data : {};
    data.start_date = moment(date).toDate();
    this.setState({ data: data });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onValidate() {

    let errors = {};
    if (!this.state.data.name) {
      errors["name"] = "โปรดระบุชื่อรอบปลูก";
    }
    if (!this.state.data.start_date) {
      errors["start_date"] = "โปรดระบุวันที่เริ่มปลูก";
    }
    if (!this.state.data.plot_id) {
      errors["plot_id"] = "โปรดระบุแปลง";
    }
    if (!this.state.data.item_id) {
      errors["item_id"] = "โปรดระบุสินค้า";
    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  handleSaveClick(event) {
    event.preventDefault();

    this.setState({ loading: true });
    let data = this.state.data;
    if (!this.isAdd) {
      data.id = this.props.match.params.id;
    }
    else {
      data.farm_id = this.props.match.params.farmID;
    }

    data.start_date = moment(data.start_date).add(7, "hour").toDate();

    CropService.save(data).then(
      (resp) => {

        MessageService.SuccessEvent.next({ message: this.state.pageName + 'เรียบร้อยแล้ว' });
        this.props.history.push(`/detail-crop/${resp.id}`);
      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }




  handleDeleteClick(event) {
    this.setState({ deleting: true });
    CropService.deleteByID(this.props.match.params.id).then(
      (resp) => {
        MessageService.SuccessEvent.next({ message: 'ลบรอบปลูกเรียบร้อยแล้ว' });
        this.setState({ deleting: false });
        this.props.history.push(`/my-farm/${this.props.match.params.farmID}?m=crop`);
      },
      (error) => {
        this.setState({ deleting: false });
      }
    );
  }

  render() {

    return (
      <Manage edit={this.state.edit}
        pageName={this.state.pageName}
        onSaveClick={this.onValidate}
        handleDeleteClick={this.handleDeleteClick}
        deleting={this.state.deleting}
        init={this.state.init}
        loading={this.state.loading}>

        <div className="container">
          <div className="row" id="content-add">
            <div className="col-md-12">
              <h4 className="mt-5">ข้อมูลรอบปลูก</h4>
              <div className="row">

                {!this.state.loading &&
                  <div className="col-md-12">
                    <form autoComplete="off">
                      <div className="form-group">
                        <label >ชื่อรอบปลูก:</label>
                        <input type="text" className="form-control" id="" aria-describedby="" placeholder="ชื่อรอบปลูก" value={this.state.data.name || ''}
                          name="name" onChange={this.handleFieldChange} />
                        {this.renderErrorFor('name')}
                      </div>
                      <div className="form-group">
                        <label >แปลง:</label>
                        <select className="form-control" value={this.state.data.plot_id || ''} name="plot_id"
                          onChange={this.handleFieldChange}>
                          <option value={''} >เลือกแปลง</option>
                          {
                            this.state.plots.map(p => {
                              return (
                                <option key={p.id} value={p.id} >{p.name}</option>
                              )
                            })
                          }
                        </select>
                        {this.renderErrorFor('plot_id')}
                      </div>
                      <div className="form-group">
                        <label >สินค้า:</label>
                        <select className="form-control" value={this.state.data.item_id || ''} name="item_id"
                          onChange={this.handleFieldChange}>
                          <option value={''} >เลือกสินค้า</option>
                          {
                            this.state.items.map(a => {
                              return (
                                <option key={a.id} value={a.id} >{a.name}</option>
                              )
                            })
                          }
                        </select>
                        {this.renderErrorFor('item_id')}
                      </div>
                      <div className="form-group">
                        <label >วันที่เริ่มปลูก:</label>
                        <DatePicker
                          name="start_date"
                          placeholderText="วันที่เริ่มปลูก"
                          dateFormat="dd MMM yyyy"
                          className="form-control"
                          selected={this.state.data.start_date}
                          onChange={this.handleDateChange}
                        />
                        {this.renderErrorFor('start_date')}
                      </div>
                      <div className="form-group">
                        <label >หมายเหตุ:</label>
                        <textarea placeholder="หมายเหตุ" className="form-control" rows="6" value={this.state.data.note || ''}
                          name="note" onChange={this.handleFieldChange} ></textarea>
                      </div>
                    </form>
                  </div>
                }
                {
                  this.state.loading &&
                  <div className="col-md-12 text-center">
                    <span><i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></span>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.state.edit ? 'ปรับปรุง' : 'เพิ่ม'}${this.state.pageName} ?`} refID="confirm" onConfirm={this.handleSaveClick} />
      </Manage >
    );
  }
}

export default ManageCrop;
