import React, { Component } from 'react';
import MainLayout from '../main/main_layout';
import { AuthService } from '../services/auth_service';
import HttpHelper from '../helpers/http_helper.js';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';

class EditProfile extends Component {

  constructor(props) {
    super(props)

    this.state = {
      prefix: "",
      full_name: "",
      phone_number: "",
      errors: []
    }

    this.onPrefixChange = this.onPrefixChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  onPrefixChange(event) {
    this.setState({
      'prefix': event.target.text
    });

  }

  handleFieldChange(event) {
    if (event.target.name === "phone_number") {
      if (!isNaN(parseInt(event.target.value)) && isFinite(event.target.value) || event.target.value === "") {
        this.setState({
          [event.target.name]: event.target.value.trim()
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  }

  handleCheckChange(event) {
    this.setState({
      [event.target.name]: event.target.checked ? event.target.value : ''
    });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    AuthService.update({
      prefix: this.state.prefix,
      full_name: this.state.full_name,
      phone_number: this.state.phone_number
    }).then(
      (resp) => {
        this.setState({ loading: false });
        MessageService.ProfileEvent.next(resp);
        MessageService.SuccessEvent.next({ message: 'ปรับปรุงข้อมูลผู้ใช้เรียบร้อยแล้ว' });
        window.history.back();
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  onValidate() {

    let errors = {};
    if (this.state.full_name.length === 0 || this.state.prefix.length === 0) {
      errors["full_name"] = "โปรดระบุชื่อ-สกุล";
    }

    if (this.state.phone_number.length === 0) {
      errors["phone_number"] = "โปรดระบุโทรศัพท์";
    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  onBackClick(event) {
    event.preventDefault();
    window.history.back();
  }

  componentDidMount() {
    let user = HttpHelper.getAuthUser();
    this.setState({
      id: user.id,
      prefix: user.prefix,
      full_name: user.full_name,
      phone_number: user.phone_number,
    });
  }

  render() {

    return (
      <MainLayout >
        <div className="container">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb no-pdd">
              <li className="breadcrumb-item" aria-current="page">ข้อมูลผู้ใช้</li>
              <li className="breadcrumb-item" aria-current="page"></li>
            </ol>
          </nav>
          <div id="content">
            <div className="col-md-6 offset-md-3">
              <h4 className="mt-5">ข้อมูลผู้ใช้</h4>
              <div className="row">
                <div className="col-md-12">
                  <form noValidate autoComplete="off">
                    <div className="form-group">
                      <label>ชื่อ-สกุล:</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            disabled={this.state.loading}>
                            {this.state.prefix === "" ? 'โปรดเลือก...' : this.state.prefix}
                          </button>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={this.onPrefixChange}>นาย</a>
                            <a className="dropdown-item" onClick={this.onPrefixChange}>นาง</a>
                            <a className="dropdown-item" onClick={this.onPrefixChange}>นางสาว</a>
                          </div>
                        </div>
                        <input type="text" className="form-control" id="full_name" name="full_name" placeholder="ชื่อ-สกุล"
                          value={this.state.full_name} onChange={this.handleFieldChange} disabled={this.state.loading}></input>
                      </div>
                      {this.renderErrorFor('full_name')}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone_number">โทรศัพท์:</label>
                      <input type="text" className="form-control" id="phone_number" name="phone_number" placeholder="โทรศัพท์"
                        value={this.state.phone_number} onChange={this.handleFieldChange} disabled={this.state.loading}></input>
                      {this.renderErrorFor('phone_number')}
                    </div>
                    <div className="form-group text-right">
                      <button type="button" className="btn btn-outline-secondary btn-120" onClick={this.onBackClick} disabled={this.state.loading}>ยกเลิก</button>&emsp;
                      <button type="button" className="btn btn-primary btn-primary-green btn-120" onClick={this.onValidate} disabled={this.state.loading}>
                        {this.state.loading && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &emsp;</span>}ตกลง
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body="ปรับปรุงข้อมูลผู้ใช้" refID="confirm" onConfirm={this.onSubmit} />
      </MainLayout>
    );
  }
}

export default EditProfile;
