import React, { Component } from 'react';
import { FarmService } from '../services/farm_service.js';
import MainLayout from '../main/main_layout';
import FarmMenuLayout from '../main/farm_menu_layout';
import SelectedFarm from './selected_farm';
import SettingFarm from './setting_farm';
import AccountChartList from '../account_chart/account_chart_list';
import ItemList from '../item/item_list';
import AssetList from '../asset/asset_list';
import PlotList from '../plot/plot_list';
import PayableList from '../partner/payable_list';
import ReceivableList from '../partner/receivable_list';
import CropList from '../crop/crop_list';
import AccountingList from '../accounting/accounting_list';
import Report from '../report/rpt';

import { Link } from "react-router-dom";

class ManageFarm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      farm: null,
      selectedMenu: null
    }

    this.onMenuSelected = this.onMenuSelected.bind(this);

  }


  componentDidMount() {
    this.setState({ loading: true });
    FarmService.getFarm(this.props.match.params.id).then(
      (resp) => {
        this.setState({ loading: false, farm: resp });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }

  onMenuSelected(menu) {
    this.setState({ selectedMenu: menu });
    window.history.pushState(null, "menage_farm", "?m=" + menu);
  }

  renderConponent() {

    switch (this.state.selectedMenu) {

      case 'accounting':
        return (<AccountingList farm={this.state.farm} history={this.props.history} />);
      case 'setting':
        return (<SettingFarm farm={this.state.farm} history={this.props.history} />);
      case 'account-chart':
        return (<AccountChartList farm={this.state.farm} history={this.props.history} />);
      case 'item':
        return (<ItemList farm={this.state.farm} history={this.props.history} />);
      case 'asset':
        return (<AssetList farm={this.state.farm} history={this.props.history} />);
      case 'plot':
        return (<PlotList farm={this.state.farm} history={this.props.history} />);
      case 'payable':
        return (<PayableList farm={this.state.farm} history={this.props.history} />);
      case 'receivable':
        return (<ReceivableList farm={this.state.farm} history={this.props.history} />);
      case 'crop':
        return (<CropList farm={this.state.farm} history={this.props.history} />);
      case 'report':
        return (<Report farm={this.state.farm} history={this.props.history} />);

    }

    return (
      <SelectedFarm history={this.props.history} />
    );
  }

  render() {

    if (this.state.loading) {
      return (

        <MainLayout>
          <div className="row" style={{ marginTop: '150px' }}>
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>

        </MainLayout>

      );
    } else {
      return (

        <MainLayout>

          <main role="main" className="container-fluid">

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb no-pdd">
                <li className="breadcrumb-item" aria-current="page"><Link to="/my-farm">ฟาร์มของฉัน</Link> </li>
                <li className="breadcrumb-item active" aria-current="page">{this.state.farm && this.state.farm.name}</li>
              </ol>
            </nav>
            <div id="content">

              <h4 className="mt-3">{this.state.farm && this.state.farm.name}</h4>
              <div className="row">
                <FarmMenuLayout farmID={this.props.farmID} onMenuSelected={this.onMenuSelected} />
                <div className="col-md-9" id="web-content">
                  {this.renderConponent()}
                </div>
              </div>

            </div>
          </main>
        </MainLayout>

      );
    }
  }
}

export default ManageFarm;
