import React, { Component } from 'react';

class SelectedFarm extends Component {

  constructor(props) {
    super(props)
  }


  componentDidMount() {

  }

  render() {

    return (
      <> </>
    );

  }
}

export default SelectedFarm;
