import React, { Component } from 'react';
import { Link } from "react-router-dom";

class SettingFarm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }

  }

  componentDidMount() {

  }

  render() {

    const farm = this.props.farm;

    return (

      <>
        <h4>ตั้งค่าฟาร์ม</h4>
        <div className="row">
          <div className="col-md-12">
            <div className="border rounded setting-content container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="mt-3">ข้อมูลฟาร์ม
                    <Link to={"/edit-farm/" + farm.id} className="btn btn-outline-secondary pull-right btn-sm" role="button">
                      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">ชื่อฟาร์ม:</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" id="staticEmail" readOnly={true} value={farm.name}></input>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-2 col-form-label">Tags: </label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" id="" placeholder="" readOnly={true} value={farm.tags}></input>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">รูปภาพ:</label>
                    <div className="col-sm-10">
                      <a className="form-control" id="staticEmail" readOnly={true} href={farm.image} target="__blank">ดูรูปภาพ</a>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">หมายเหตุ:</label>
                    <div className="col-sm-10">
                      <textarea className="form-control" readOnly={true} rows="6" value={farm.desc ? farm.desc : ''}></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>


    );

  }
}

export default SettingFarm;
