import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import { AccountingService } from '../services/accounting_service';
import MessageService from '../services/message_service.js';
import Constant from '../helpers/constant';
import ConfirmDialog from '../main/confirm_dialog';
import { PlotService } from '../services/plot_service';
import FooterLayout from '../main/footer_layout';

class AccountingManage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: {
        farm_id: this.props.farmID,
        vat: this.props.accountingType == 'WITHDRAW' ? 0 : 7,
        other_discount: 0.00,
        accounting_type: this.props.accountingType
      },
      detail: [],
      criteria: [],
      chk_other_discount: false,
      initCirteria: true,
      errors: [],
      loading: false
    }

    this.onVatTypeChange = this.onVatTypeChange.bind(this);
    this.onPaymentTypeChange = this.onPaymentTypeChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onDiscountChange = this.onDiscountChange.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onPlotChange = this.onPlotChange.bind(this);

    moment.locale('th');


    switch (this.props.accountingType) {
      case 'SELL':
        this.dateLabel = 'วันที่ขาย';
        this.itemsLabel = 'รายการขาย';
        break;
      case 'BUY':
        this.dateLabel = 'วันที่ซื้อ';
        this.itemsLabel = 'รายการซื้อ';
        break;
      case 'EXPENSE':
        this.dateLabel = 'วันที่จ่าย';
        this.itemsLabel = 'รายการรายจ่าย';
        break;

      case 'INCOME':
        this.dateLabel = 'วันที่รับ';
        this.itemsLabel = 'รายการรายรับ';
        break;

      case 'WITHDRAW':
      case 'WITHDRAW_EXPIRED':
        this.dateLabel = 'วันที่เบิก';
        this.itemsLabel = 'รายการรายเบิก';
        break;

      case 'HARVEST':
        this.dateLabel = 'วันที่เก็บเกี่ยว';
        this.itemsLabel = 'รายการเก็บเกี่ยว';
        break;

    }

  }


  onDiscountChange(e) {

    let checked = !this.state.chk_other_discount;
    let data = this.state.data;

    if (!checked) {
      data.other_discount = 0.00;
    }

    this.setState({
      chk_other_discount: checked,
      data: data
    });

    this.calc();

  }

  handleDateChange(field, date) {
    let event = {
      target: {
        name: field,
        value: moment(date).toDate()
      }
    }
    this.handleFieldChange(event);
  }

  handleFieldChange(event) {

    let data = this.state.data ? this.state.data : {};
    data[event.target.name] = event.target.value;

    this.setState({
      data: data
    });

    if (event.target.name === 'vat' || event.target.name === 'other_discount') {
      this.calc();
    }

    if (this.props.accountingType === 'HARVEST' && event.target.name === 'crop_id') {
      if (event.target.value) {
        let data = this.state.criteria.filter(c => {
          return c.crop_id === parseInt(event.target.value)
        });

        let detail = [];

        data.forEach(d => {
          detail.push({
            id: d.id,
            item_id: d.id,
            price: 0,
            name: 'เก็บเกี่ยว - ' + d.name
          });
        });

        this.setState({
          detail: detail
        });

      } else {

        this.setState({
          detail: []
        });


      }
    }
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onPaymentTypeChange(event) {
    this.handleFieldChange(event);
  }

  onVatTypeChange(event) {
    this.handleFieldChange(event);
    this.calc();
  }

  onPlotChange(event) {
    this.handleFieldChange(event);

    if (this.state.data.plot_id) {
      let data = this.state.data;
      data.crop_id = null;
      let loadingCrop = false;

      this.setState({
        loadingCrop: true,
        data
      });

      PlotService.getCrops(this.state.data.plot_id).then(
        resp => {
          this.setState({
            loadingCrop: false,
            crops: resp
          })
        },
        error => {
          this.setState({ loadingCrop: false });
        }
      )
    }

  }

  componentDidMount() {
    AccountingService.getCriteria(this.props.accountingType, this.props.farmID).then(
      resp => {
        this.setState({
          initCirteria: false,
          criteria: resp
        })
      },
      error => {
        this.setState({ initCirteria: false })
      }
    )
  }

  onValidate(e) {

    e.preventDefault();
    let errors = {};
    if (!this.state.data.accounting_date) {
      errors["accounting_date"] = "โปรดระบุ" + this.dateLabel;
    }

    if (this.state.data.accounting_type === 'WITHDRAW') {
      if (this.state.data.plot_id && !this.state.data.crop_id) {
        errors["crop_id"] = "โปรดระบุรอบปลูก";
      }
    } else if (this.state.data.accounting_type === 'HARVEST') {

      if (!this.state.data.plot_id) {
        errors["plot_id"] = "โปรดระบุแปลง";
      }
      if (!this.state.data.crop_id) {
        errors["crop_id"] = "โปรดระบุรอบปลูก";
      }

    } else if (this.state.data.accounting_type === 'WITHDRAW_EXPIRED') {


    } else {
      if (!this.state.data.vat_type) {
        errors["vat_type"] = "โปรดระบุประเภทภาษี";
      }
      if (this.state.data.vat_type && !this.state.data.vat) {
        errors["vat"] = "โปรดระบุอัตราภาษี";
      }

      if (!this.state.data.payment_type) {
        errors["payment_type"] = "โปรดระบุชำระโดย";
      }
      if (this.state.data.payment_type === 'CREDIT' && !this.state.data.payment_date) {
        errors["payment_date"] = "โปรดระบุวันครบกำหนด";
      }
      if (this.state.data.payment_type === 'CREDIT' && !this.state.data.credit_partner_id) {
        errors["credit_partner_id"] = "โปรดระบุ" + this.partnerLabel;
      }
    }


    if (this.state.chk_other_discount && (!this.state.data.other_discount || parseFloat(this.state.data.other_discount) <= 0)) {
      errors["other_discount"] = "โปรดระบุส่วนลดเพิ่มเติม";
    }

    if (this.state.detail.length === 0) {
      errors["detail"] = "โปรดระบุรายการ";
    }

    for (var i = 0; i < this.state.detail.length; i++) {
      let d = this.state.detail[i];
      if (!d.item_id) {
        errors["item_id_" + i] = "โปรดระบุรายการ";
      }
      if (this.props.accountingType !== 'HARVEST') {
        if (parseFloat(d.price || 0) === 0) {
          errors["price_" + i] = "โปรดระบุราคา/หน่วย";
        }
      }

      if (parseFloat(d.qty || 0) === 0) {
        errors["qty_" + i] = "โปรดระบุจำนวน";
      }
    }


    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  onAddClick(e) {

    e.preventDefault();

    let detail = this.state.detail;
    let emptyItems = detail.filter(d => {
      return !d.item_id || parseFloat(d.price || 0) == 0 || parseFloat(d.qty || 0) == 0;
    });

    if (emptyItems.length === 0) {
      if (this.state.data.accounting_type === 'EXPENSE' || this.state.data.accounting_type === 'INCOME') {
        detail.push({
          qty: 1
        });
      }
      else {
        detail.push({});
      }
      this.setState({ detail: detail })
    }

  }

  onDetailChange(e) {

    let detail = this.state.detail;
    let d = detail[parseInt(e.target.getAttribute('index'))];
    d[e.target.name] = e.target.value;

    let price = d.price || 0;

    if(e.target.name === "item_id"){
			const itemCriteria = this.state.criteria[parseInt(e.target.value)]
			d[e.target.name] = itemCriteria.id;
			if (this.state.data.accounting_type === "SELL" || this.state.data.accounting_type === "WITHDRAW_EXPIRED") {
				d.harvest_id = null;
				if(itemCriteria.harvest_id){
					d.harvest_id = itemCriteria.harvest_id;
				}
			}
		}

    if (this.state.data.accounting_type === "WITHDRAW" && e.target.name === "item_id") {

      let selectedItem = this.state.criteria.find(c => {
        return c.id === parseInt(e.target.value);
      });

      d.price = price = parseFloat(selectedItem.price);

    }

    let qty = d.qty || 0;
    let discount = d.discount || 0;
    d.total = (parseFloat(price) * parseFloat(qty)) - parseFloat(discount);

    console.log(d)
    this.setState({
      detail: detail
    });

    this.calc();

  }

  onDelete(index) {

    let detail = this.state.detail;
    detail.splice(index, 1);

    this.setState({
      detail: detail
    });

    this.calc();

  }

  calc() {

    let total_price = 0;
    let total_price_before_vat = 0;
    let total_discount = 0;
    let total_vat = 0;


    if (this.state.data.accounting_type === 'WITHDRAW') {

      this.state.detail.forEach(d => {
        total_price += parseFloat(d.price || 0) * parseFloat(d.qty || 0);
      });

      total_price_before_vat = total_price;

    } else {

      this.state.detail.forEach(d => {
        total_price += parseFloat(d.price || 0) * parseFloat(d.qty || 0);
        total_discount += parseFloat(d.discount || 0);
      });

      total_price_before_vat = total_price - total_discount - parseFloat(this.state.data.other_discount || 0);

      if (this.state.data.vat_type) {
        total_vat = ((parseFloat(this.state.data.vat) / 100.0) * total_price_before_vat);
        if (this.state.data.vat_type === "INCLUDE_VAT") {
          total_price_before_vat -= total_vat;
        }
      }

    }

    let data = this.state.data ? this.state.data : {};
    data.total_price = total_price;
    data.total_price_before_vat = total_price_before_vat;
    data.total_discount = total_discount;
    data.total_vat = total_vat;
    data.net = total_price_before_vat + total_vat;

    this.setState({
      data: data
    });

  }

  onBack(e) {
    e.preventDefault();
    window.history.back();
  }

  onSubmit(e) {
    e.preventDefault();

    let data = {
      data: this.state.data,
      detail: this.state.detail
    }
    this.setState({
      loading: true
    });

    AccountingService.save(data).then(
      resp => {

        this.setState({
          loading: false
        })

        MessageService.SuccessEvent.next({ message: this.props.pageName + 'เรียบร้อยแล้ว' });
        this.props.history.push('/accounting-detail/' + resp.id);

      },
      error => {
        this.setState({ loading: false })
      }
    )

  }

  render() {

    let itemIndex = -1;

    return (
      <>
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow-sm">
            <a className="navbar-brand" href="javascript:void(0)">
              <button type="button" className="close" aria-label="Close" onClick={this.onBack}>
                <span aria-hidden="true">&times;</span>
              </button>
            </a>
            <div className="collapse navbar-collapse justify-content-md-center" id="navbarsExample08">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="javascript:void(0)">{this.state.initCirteria ?

                    <>
                      <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                      &emsp;
                      <span>
                        กำลังโหลด...
                     </span>
                    </>

                    : this.props.pageName}

                  </a>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav ml-auto">
              <button type="button" className="btn btn-primary btn-primary-green" disabled={this.state.initCirteria || this.state.loading}
                onClick={this.onValidate}>
                {this.state.loading && <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&emsp;</>}เพิ่ม
              </button>
            </ul>
          </nav>
        </header>

        <main role="main" className="flex-shrink-0" style={{ marginTop: '10vh' }}>
          <div id="content" className="container">
            <div className="row" >
              <div className="col-md-12">
                <h4 className="mt-5">รายละเอียดบัญชี</h4>
                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          {this.dateLabel}:
                        </label>
                        <div className="col-sm-4">
                          <DatePicker
                            locale="th"
                            placeholderText="เลือกวันที่"
                            dateFormat="dd MMM yyyy"
                            className="form-control"
                            selected={this.state.data.accounting_date || null}
                            onChange={e => this.handleDateChange('accounting_date', e)} />
                          {this.renderErrorFor('accounting_date')}
                        </div>
                      </div>

                      {
                        (this.state.data.accounting_type === 'WITHDRAW' || this.state.data.accounting_type === 'HARVEST') &&
                        <>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">แปลง{this.state.data.accounting_type !== 'HARVEST' ? '(Optional)' : ''}:</label>
                            <div className="col-md-4">
                              <select className="form-control" name="plot_id" onChange={this.onPlotChange} value={this.state.data.plot_id || ''} disabled={this.state.loadingCrop}>
                                <option value={""}>เลือกแปลง</option>
                                {
                                  this.props.plots &&
                                  this.props.plots.map(c => {
                                    return (
                                      <option key={c.id} value={c.id}>{c.name}</option>
                                    )
                                  })
                                }
                              </select>
                              {this.renderErrorFor('plot_id')}
                            </div>
                          </div>

                          {
                            this.state.data.plot_id &&
                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label">รอบปลูก:</label>
                              <div className="col-md-4">
                                <select className="form-control" name="crop_id" onChange={(e) => this.handleFieldChange(e)} value={this.state.data.crop_id || ''} disabled={this.state.loadingCrop}>
                                  <option value={""}>เลือกรอบปลูก</option>
                                  {
                                    this.state.crops &&
                                    this.state.crops.map(c => {
                                      return (
                                        <option key={c.id} value={c.id}>{c.name}</option>
                                      )
                                    })
                                  }
                                </select>
                                {this.renderErrorFor('crop_id')}
                              </div>
                            </div>
                          }

                        </>
                      }

                      {
                        (this.state.data.accounting_type !== 'WITHDRAW' && this.state.data.accounting_type !== 'WITHDRAW_EXPIRED' && this.state.data.accounting_type !== 'HARVEST') &&
                        <>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">ประเภทภาษี:</label>
                            <div className="col-md-4">
                              <select className="form-control" name="vat_type" onChange={this.onVatTypeChange} value={this.state.data.vat_type}>
                                <option value={""}>เลือกประเภท</option>
                                <option value={"EXCLUDE_VAT"}>{Constant.VatTypeDisplay["EXCLUDE_VAT"]}</option>
                                <option value={"INCLUDE_VAT"}>{Constant.VatTypeDisplay["INCLUDE_VAT"]}</option>
                              </select>
                              {this.renderErrorFor('vat_type')}
                            </div>
                          </div>
                          {
                            this.state.data.vat_type &&
                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label">อัตราภาษี (%):</label>
                              <div className="col-md-4">
                                <input type="number" className="form-control" placeholder="0.00" name="vat" value={this.state.data.vat} onChange={this.handleFieldChange} />
                                {this.renderErrorFor('vat')}
                              </div>
                            </div>
                          }

                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">ชำระโดย</label>
                            <div className="col-md-4">
                              <select className="form-control" name="payment_type" onChange={this.onPaymentTypeChange} value={this.state.data.payment_type}>
                                <option value={""}>เลือกวิธีชำระเงิน </option>
                                <option value={"CASH"}>{Constant.PaymentTypeDisplay["CASH"]}</option>
                                <option value={"BANK"}>{Constant.PaymentTypeDisplay["BANK"]}</option>
                                {
                                  this.state.data.accounting_type !== 'INCOME' &&
                                  <option value={"CREDIT"}>{Constant.PaymentTypeDisplay["CREDIT"]}</option>
                                }

                              </select>
                              {this.renderErrorFor('payment_type')}
                            </div>
                          </div>

                          {
                            this.state.data.payment_type === "CREDIT" &&
                            <>
                              <div className="form-group row">
                                <label className="col-sm-2 col-form-label">วันครบกำหนด:</label>
                                <div className="col-sm-4">
                                  <DatePicker
                                    locale="th"
                                    placeholderText="เลือกวันที่"
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    selected={this.state.data.payment_date || null}
                                    onChange={e => this.handleDateChange('payment_date', e)} />
                                  {this.renderErrorFor('payment_date')}
                                </div>

                              </div>
                              <div className="form-group row">
                                <label className="col-sm-2 col-form-label">{this.props.partnerLabel}:</label>
                                <div className="col-md-4">
                                  <select className="form-control" name="credit_partner_id" onChange={this.handleFieldChange}>
                                    <option value={""}>เลือก{this.props.partnerLabel}</option>
                                    {
                                      this.props.partners.map(p => {
                                        return (
                                          <option key={p.id} value={p.id}>{p.name_th}</option>
                                        )
                                      })
                                    }
                                  </select>
                                  {this.renderErrorFor('credit_partner_id')}
                                </div>
                              </div>
                            </>
                          }

                        </>
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <h4 className="mt-5">{this.itemsLabel}</h4>
                <table className="table">
                  <thead className="thead-light">
                    <tr>

                      {
                        this.state.data.accounting_type !== 'HARVEST' &&
                        <th scope="col" style={{ width: '80px' }}>#</th>
                      }

                      <th scope="col">รายการ</th>

                      {
                        (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL') &&
                        <>
                          <th scope="col" style={{ width: '15%' }}>ราคา/หน่วย (บาท)</th>
                          <th scope="col" style={{ width: '15%' }}>จำนวน</th>
                          <th scope="col" style={{ width: '15%' }}>ส่วนลด (บาท)</th>
                          <th scope="col" style={{ width: '15%' }}>มูลค่ารวม (บาท)</th>
                        </>
                      }
                      {
                        (this.state.data.accounting_type === 'WITHDRAW' || this.state.data.accounting_type === 'WITHDRAW_EXPIRED') &&
                        <>
                          <th scope="col" style={{ width: '15%' }}>ราคา/หน่วย (บาท)</th>
                          <th scope="col" style={{ width: '15%' }}>จำนวน</th>
                          <th scope="col" style={{ width: '15%' }}>มูลค่ารวม (บาท)</th>
                        </>
                      }
                      {
                        (this.state.data.accounting_type === 'INCOME' || this.state.data.accounting_type === 'EXPENSE') &&
                        <th scope="col" style={{ width: '15%' }}>
                          มูลค่า (บาท)
                        </th>
                      }
                      {
                        this.state.data.accounting_type === 'HARVEST' &&
                        <th scope="col" style={{ width: '15%' }}>
                          จำนวน
                        </th>
                      }

                    </tr>
                  </thead>
                  <tbody>
                    {

                      this.state.detail.map(d => {
                        itemIndex += 1;
                        return (
                          <tr key={itemIndex}>

                            {
                              this.props.accountingType !== 'HARVEST' &&
                              <>
                                <td scope="row" style={{ verticalAlign: 'middle' }}>
                                  <span style={{ cursor: 'pointer' }} index={itemIndex} onClick={(e) => this.onDelete(itemIndex)}>
                                    <i className="fas fa-minus-circle fail-dot"></i> {itemIndex + 1}
                                  </span>
                                </td>
                                <td>
                                  <select className="form-control" name="item_id" index={itemIndex} value={d.id} onChange={(e) => this.onDetailChange(e)} >
                                    {
                                      !d.item_id &&
                                      <option>เลือกรายการ </option>
                                    }
                                    {
                                      this.state.criteria.map((c,i) => {
                                        return (
                                          <option key={i} value={i}>{c.name}</option>
                                        )
                                      })
                                    }
                                  </select>
                                  {this.renderErrorFor('item_id_' + itemIndex)}
                                </td>
                                <td>
                                  <input type="number" className="form-control" placeholder="0.00" name="price"
                                    index={itemIndex} value={d.price || ''} onChange={(e) => this.onDetailChange(e)} />
                                  {this.renderErrorFor('price_' + itemIndex)}
                                </td>
                                {
                                  (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL') &&
                                  <>
                                    <td>
                                      <input type="number" className="form-control" placeholder="0" name="qty" index={itemIndex} value={d.qty || ''} onChange={(e) => this.onDetailChange(e)} />
                                      {this.renderErrorFor('qty_' + itemIndex)}
                                    </td>
                                    <td>
                                      <input type="number" className="form-control" placeholder="0.00" name="discount" index={itemIndex} value={d.discount || ''} onChange={(e) => this.onDetailChange(e)} />
                                    </td>
                                    <td>
                                      <input type="text" className="form-control" placeholder="0.00" name="total" value={parseFloat((d.total || 0)).toLocaleString()} readOnly={true} />
                                    </td>
                                  </>
                                }
                                {
                                  (this.state.data.accounting_type === 'WITHDRAW' || this.state.data.accounting_type === 'WITHDRAW_EXPIRED') &&
                                  <>
                                    <td>
                                      <input type="number" className="form-control" placeholder="0" name="qty" index={itemIndex} value={d.qty || ''} onChange={(e) => this.onDetailChange(e)} />
                                      {this.renderErrorFor('qty_' + itemIndex)}
                                    </td>
                                    <td>
                                      <input type="text" className="form-control" placeholder="0.00" name="total" value={parseFloat((d.total || 0)).toLocaleString()} readOnly={true} />
                                    </td>
                                  </>
                                }
                              </>
                            }
                            {
                              this.props.accountingType === 'HARVEST' &&
                              <>
                                <td>
                                  {d.name}
                                </td>
                                <td>
                                  <input type="number" className="form-control" placeholder="0" name="qty" index={itemIndex} value={d.qty || ''} onChange={(e) => this.onDetailChange(e)} />
                                  {this.renderErrorFor('qty_' + itemIndex)}
                                </td>
                              </>
                            }
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              {
                this.props.accountingType !== 'HARVEST' &&
                <div className="col-md-12">
                  <a onClick={this.onAddClick} className="btn btn-outline-secondary btn-sm">เพิ่มรายการ</a>
                </div>
              }
              <div className="col-md-12">
                {this.renderErrorFor('detail')}
              </div>
            </div>
            {
              (this.state.data.accounting_type === 'WITHDRAW' || this.state.data.accounting_type === 'WITHDRAW_EXPIRED') &&
              <div className="row">
                <div className="col-md-5 offset-md-7">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">มูลค่าสุทธิ</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.net || 0)).toLocaleString()} />
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-5 offset-md-2">

                {
                  (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL') &&
                  <>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">ราคารวม</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_price || 0)).toLocaleString()} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">ส่วนลด</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_discount || 0)).toLocaleString()} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-check col-sm-4 col-form-label">
                        <input className="form-check-input" type="checkbox" id="chk_other_discount" onChange={this.onDiscountChange} checked={this.state.chk_other_discount} />
                        <label className="form-check-label" htmlFor="chk_other_discount" >
                          ส่วนลดเพิ่มเติม
                          </label>
                      </div>
                      <div className="col-sm-8">
                        <input type="number" className="form-control" placeholder="" name="other_discount"
                          onChange={this.handleFieldChange} readOnly={!this.state.chk_other_discount} value={this.state.data.other_discount} />
                        {this.renderErrorFor('other_discount')}
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="col-md-5">
                {
                  (this.state.data.accounting_type === 'EXPENSE' || this.state.data.accounting_type === 'INCOME') &&
                  <>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">มูลค่ารวม</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_price || 0)).toLocaleString()} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">มูลค่ารวมก่อนภาษี</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_price_before_vat || 0)).toLocaleString()} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">ภาษี</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_vat || 0)).toLocaleString()} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">มูลค่าสุทธิ</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.net || 0)).toLocaleString()} />
                      </div>
                    </div>

                  </>
                }

                {
                  (this.state.data.accounting_type === 'BUY' || this.state.data.accounting_type === 'SELL') &&
                  <>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">ราคารวมก่อนภาษี</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_price_before_vat || 0)).toLocaleString()} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">ภาษี</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.total_vat || 0)).toLocaleString()} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">ราคาสุทธิ</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder="" readOnly={true} value={parseFloat((this.state.data.net || 0)).toLocaleString()} />
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>หมายเหตุ:</label>
                  <textarea className="form-control" rows="6" placeholder="หมายเหตุ" maxLength="255" name="note" value={this.state.data.note || ''} onChange={this.handleFieldChange}></textarea>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.props.pageName} ?`} refID="confirm" onConfirm={this.onSubmit} />
        <FooterLayout />
      </>
    );
  }
}

export default AccountingManage;
