import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { PlotService } from "../services/plot_service";

class PlotList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: []
    }
    this.onSearchChange = this.onSearchChange.bind(this);

  }

  componentDidMount() {

    PlotService.getByFarmID(this.props.farm.id).then(
      (resp) => {
        this.setState({
          data: resp,
          loading: false,
        });

        this.dataTable = window.$('.table').DataTable({
          dom: 'frtlip',
          ordering: false,
          language: {
            emptyTable: "ไม่พบข้อมูล",
            zeroRecords: "ไม่พบข้อมูล",
            paginate: {
              previous: '‹',
              next: '›'
            },
            sLengthMenu: "แสดง _MENU_ รายการ/หน้า",
            aria: {
              paginate: {
                previous: 'ก่อนหน้า',
                next: 'ถัดไป'
              }
            }
          }
        });

        window.$(".dataTables_length").css('clear', 'none');
        window.$(".dataTables_length").css('margin-right', '20px');
        window.$(".dataTables_info").css('display', 'none');
        window.$(".dataTables_filter").css('display', 'none');
        window.$(".dataTables_paginate").css('margin-top', '-40px');
      }
    );

  }


  onRenderItem() {

    return this.state.data.map((d) => {
      return (
        <tr key={d.id}>
          <td scope="row">
            <img style={{ width: '32px', height: '32px' }} src={d.image} ></img>&emsp;
            <Link to={"/edit-plot/" + d.id}  >
              {d.name}
            </Link>
          </td>
          <td scope="row" className="text-right">
            {(d.area ? d.area : 0).toLocaleString()}&emsp;&emsp;&emsp;
          </td>

        </tr>
      )
    });

  }

  onSearchChange(event) {
    this.dataTable.search(event.target.value);
    this.dataTable.draw();
  }

  render() {
    return (
      <>
        <h4>จัดการแปลง</h4>
        <div className="row">
          <div className="col-md-4">
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="ค้นหา"
                aria-label="Recipient's " aria-describedby="basic-addon2" onChange={this.onSearchChange}></input>
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
              </div>
            </div>
          </div>
          <div className="col-md-8 text-right">
            <Link to={"/add-plot/" + this.props.farm.id} className="btn btn-primary btn-primary-green">เพิ่มแปลง</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table id="example" className="table table-hover" width="100%">
              <thead className="thead-light">
                <tr>
                  <th scope="col">แปลง</th>
                  <th scope="col" width="10%">พื้นที่ (ตร.ม.)</th>
                </tr>
              </thead>
              <tbody>
                {
                  !this.state.loading ? this.onRenderItem() :
                    <tr className="col-md-3 text-center">
                      <td colSpan="99" className="text-center">
                        <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

      </>
    );
  }
}

export default PlotList;
