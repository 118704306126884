import React, { Component } from 'react';
import MainLayout from '../main/main_layout';
import SetupPassword from '../public/setup_password';

class ChangePassword extends Component {

  constructor(props) {
    super(props)

    this.state = {

    }

  }

  componentDidMount() {

  }

  render() {

    return (
      <MainLayout >
        <div className="container">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb no-pdd">
              <li className="breadcrumb-item" aria-current="page">ตั้งรหัสผ่านใหม่</li>
              <li className="breadcrumb-item" aria-current="page"></li>
            </ol>
          </nav>
          <div id="content">
            <div className="col-md-6 offset-md-3 mt-4">
              <SetupPassword history={this.props.history} />
            </div>

          </div>
        </div>
      </MainLayout>
    );

  }
}

export default ChangePassword;
