import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ReportService } from '../services/report_service';
import Constant from '../helpers/constant'
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Config from '../helpers/config';

class Report06 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      selected: {}
    }

    this.onSelectedChange = this.onSelectedChange.bind(this);

    moment.locale('th');
  }

  componentDidMount() {

  }

  onSelectedChange(e) {
    let selected = this.state.selected;
    selected[e.target.name] = e.target.value;
    this.setState({
      selected: selected
    });
    if (e.target.value) {
      this.getReportData(selected);
    } else {
      this.setState({
        data: null
      });
    }
  }

  getReportData(selected) {
    this.setState({
      loading: true
    })
    ReportService.getRpt06(this.props.farm.id, selected).then(
      resp => {
        this.setState({
          data: resp,
          loading: false
        })
      },
      error => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render() {
    return (
      <>
        <div className="row mt-3">

          <div className="col-md-6">
            <select className="form-control" name="day_range" value={this.state.selected.day_range || ''} disabled={this.state.loading}
              onChange={this.onSelectedChange}>
              <option value="">เลือครบกำหนดชำระใน</option>
              <option value="30">ครบกำหนดชำระใน 30 วัน</option>
              <option value="31-45">ครบกำหนดชำระใน 31-45 วัน</option>
              <option value="46">ครบกำหนดชำระเกิน 45 วัน</option>
            </select>

          </div>

        </div>

        <div className="row  mt-3">
          <div className="col-md-7">
            <p className="searchTitle">ข้อมูล <strong>{this.props.farm.name} </strong>, รายงาน
             <strong> รายงานลูกหนี้รับ </strong><br />
              {
                this.state.selected.day_range === '30' &&
                'ครบกำหนดชำระใน 30 วัน'
              }
              {
                this.state.selected.day_range === '31-45' &&
                'ครบกำหนดชำระใน 31-45 วัน'
              }
              {
                this.state.selected.day_range === '46' &&
                'ครบกำหนดชำระเกิน 45 วัน'
              }
            </p>
          </div>

          {
            (!this.state.loading && this.state.data) &&
            <div className="col-md-5 text-right">
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-06/${this.props.farm.id}/export/xlsx?day_range=${this.state.selected.day_range}`}
                className="btn btn-primary btn-csv btn-primary-green btn-120"><i className="fas fa-file-csv"></i> Excel
              </a>
              &emsp;
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-06/${this.props.farm.id}/export/pdf?day_range=${this.state.selected.day_range}`}
                className="btn btn-primary btn-pdf btn-danger btn-120"><i className="fas fa-file-pdf"></i> PDF
              </a>
            </div>
          }

        </div>

        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }
        {
          (!this.state.loading && this.state.data) &&
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">รหัสลูกหนี้</th>
                <th scope="col">ชื่อลูกหนี้ </th>
                <th scope="col" className="text-right">วันที่ออกบิล</th>
                <th scope="col" className="text-right">วันที่ครบกำหนดชำระ</th>
                <th scope="col" className="text-right">หนี้สินคงเหลือ</th>
              </tr>
            </thead>
            <tbody>

              {
                this.state.data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-center">{d.partner_code}</td>
                      <td>{d.name_th}</td>
                      <td className="text-right">{moment(d.created_at).format("D MMMM YYYY")}</td>
                      <td className="text-right">{moment(d.payment_date).format("D MMMM YYYY")}</td>
                      <td className="text-right">
                        {
                          parseFloat(d.outstanding_debt) < 0
                            ? '(' + Math.abs(parseFloat(d.outstanding_debt)).toLocaleString() + ')'
                            : parseFloat(d.outstanding_debt).toLocaleString()
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        }
      </>
    );
  }
}
export default Report06;
