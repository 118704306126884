import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ReportService } from '../services/report_service';
import Constant from '../helpers/constant'
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Config from '../helpers/config';

class Report07 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,

    }

    moment.locale('th');
  }

  componentDidMount() {
    this.setState({
      loading: true
    })
    ReportService.getRpt07(this.props.farm.id).then(
      resp => {
        this.setState({
          data: resp.data,
          date: resp.date,
          loading: false
        })
      },
      error => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render() {
    return (
      <>
        <div className="row mt-3">

          <div className="col-md-7">

            <p className="searchTitle">ข้อมูล <strong>{this.props.farm.name} </strong>, รายงาน
             <strong> การ์ดเจ้าหนี้โดยสรุป </strong><br />
              {
                this.state.date &&

                (
                  <span>
                    {moment(this.state.date.from).format("D MMMM YYYY")} - {moment(this.state.date.to).format("D MMMM YYYY")}
                  </span>
                )

              }
            </p>


          </div>
          {
            (!this.state.loading && this.state.data) &&
            <div className="col-md-5 text-right">
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-07/${this.props.farm.id}/export/xlsx`}
                className="btn btn-primary btn-csv btn-primary-green btn-120"><i className="fas fa-file-csv"></i> Excel
              </a>
              &emsp;
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-07/${this.props.farm.id}/export/pdf`}
                className="btn btn-primary btn-pdf btn-danger btn-120"><i className="fas fa-file-pdf"></i> PDF
              </a>
            </div>
          }
        </div>

        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }
        {
          (!this.state.loading && this.state.data) &&
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">รหัสเจ้าหนี้</th>
                <th scope="col">ชื่อเจ้าหนี้</th>
                <th scope="col" className="text-right">ยอดยกมา</th>
                <th scope="col" className="text-right">ยอดซื้อ</th>
                <th scope="col" className="text-right">รับชำระเงิน</th>
                <th scope="col" className="text-right">คงเหลือ</th>
              </tr>
            </thead>
            <tbody>

              {
                this.state.data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-center">{d.partner_code}</td>
                      <td>{d.name_th}</td>
                      <td className="text-right">
                        {
                          parseFloat(d.outstanding) < 0
                            ? '(' + Math.abs(parseFloat(d.outstanding)).toLocaleString() + ')'
                            : parseFloat(d.outstanding).toLocaleString()
                        }
                      </td>
                      <td className="text-right">
                        {
                          parseFloat(d.net) < 0
                            ? '(' + Math.abs(parseFloat(d.net)).toLocaleString() + ')'
                            : parseFloat(d.net).toLocaleString()
                        }
                      </td>
                      <td className="text-right">
                        {
                          parseFloat(d.payment_value) < 0
                            ? '(' + Math.abs(parseFloat(d.payment_value)).toLocaleString() + ')'
                            : parseFloat(d.payment_value).toLocaleString()
                        }
                      </td>
                      <td className="text-right">
                        {
                          parseFloat(d.balance) < 0
                            ? '(' + Math.abs(parseFloat(d.balance)).toLocaleString() + ')'
                            : parseFloat(d.balance).toLocaleString()
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        }
      </>
    );
  }
}
export default Report07;
