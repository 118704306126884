import React, { Component } from 'react';
import HttpHelper from '../helpers/http_helper';
import { Link } from "react-router-dom";
import MessageService from '../services/message_service.js';
import FooterLayout from './footer_layout';

class Main extends Component {

  constructor(props) {
    super(props)

    this.state = {
      full_name: '',
    }

    this.onLogout = this.onLogout.bind(this);
  }

  onLogout(event) {
    HttpHelper.setAuth(null);
    window.location.reload();
  }

  componentDidMount() {
    let user = HttpHelper.getAuthUser();
    this.setState({ full_name: user.full_name });
    MessageService.ProfileEvent.asObservable().subscribe(data => {
      if (data) {
        user = HttpHelper.getAuth();
        user.info = data;
        HttpHelper.setAuth(user);
        this.setState({ full_name: data.full_name });
      }
    });
  }

  render() {

    return (
      <>
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow-sm nav-green ">
            <Link to={"/my-farm"} className="navbar-brand" >
              <img src="../../assets/images/nav-logo.png" width="187px" height="44px" alt=""></img>
            </Link>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle white" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fas fa-user"></i>&emsp;{this.state.full_name}</a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                  <Link to={"/edit-profile"} className="dropdown-item" >ข้อมูลผู้ใช้</Link>
                  <Link to={"/change-password"} className="dropdown-item" >เปลี่ยนรหัสผ่าน</Link>
                  <a className="dropdown-item" href="javascript:void(0)" onClick={this.onLogout}>ออกจากระบบ</a>
                </div>
              </li>
            </ul>
          </nav>
        </header>
        <main role="main" className="flex-shrink-0" style={{ "paddingTop": "110px" }}>
          <div className="container">
            {this.props.children}
          </div>
        </main>
        <FooterLayout />
      </>
    );
  }
}

export default Main;
