import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { AccountChartService } from "../services/account_chart_service";

class AccountChartList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: []
    }
    this.onSearchChange = this.onSearchChange.bind(this);

  }

  componentDidMount() {

    AccountChartService.getAccountChart(this.props.farm.id).then(
      (resp) => {
        this.setState({
          data: resp,
          loading: false,
        });

        this.dataTable = window.$('.table').DataTable({
          dom: 'frtlip',
          ordering: false,
          language: {
            emptyTable: "ไม่พบข้อมูล",
            zeroRecords: "ไม่พบข้อมูล",
            paginate: {
              previous: '‹',
              next: '›'
            },
            sLengthMenu: "แสดง _MENU_ รายการ/หน้า",
            aria: {
              paginate: {
                previous: 'ก่อนหน้า',
                next: 'ถัดไป'
              }
            }
          }
        });

        window.$(".dataTables_length").css('clear', 'none');
        window.$(".dataTables_length").css('margin-right', '20px');
        window.$(".dataTables_info").css('display', 'none');
        window.$(".dataTables_filter").css('display', 'none');
        window.$(".dataTables_paginate").css('margin-top', '-40px');
      }
    );

  }


  getSpace(count) {
    let space = "";
    for (let i = 1; i < count; i++) {
      space += "&emsp;";
    }
    return (<span dangerouslySetInnerHTML={{ __html: space }} />);
  }

  onRenderItem() {

    return this.state.data.map((d) => {
      return (
        <tr key={d.id}>
          <td scope="row">
            {d.code}
          </td>
          <td className="pl-4">{this.getSpace(d.level)} {d.level > 0 && '├─'}  {d.name}</td>
          <td>{this.getRoot(d)}</td>
          <td className="text-center">
            <Link to={"/add-account-chart/" + this.props.farm.id + "/" + d.id} className="btn btn-light btn-sm" >
              <img src="../assets/images/ic-add-child.png" className="img-fluid" width="18" height="18"></img>
            </Link>
            <button type="button" className="btn btn-light btn-sm" disabled={d.is_restrict} onClick={() => { this.props.history.push("/edit-account-chart/" + this.props.farm.id + "/" + d.id) }}>
              <i className="fas fa-pencil-alt"></i>
            </button>
          </td>
        </tr>
      )
    });

  }

  getRoot(item) {
    let root = this.state.data.filter((d) => {
      return d.id == item.root_id;
    })[0];
    return root.name;
  }

  onSearchChange(event) {
    this.dataTable.search(event.target.value);
    this.dataTable.draw();
  }

  render() {
    return (
      <>
        <h4>ผังบัญชี </h4>
        <div className="row">
          <div className="col-md-4">
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="ค้นหา"
                aria-label="Recipient's " aria-describedby="basic-addon2" onChange={this.onSearchChange}></input>
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
              </div>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-12">
            <table id="example" className="table table-hover" width="100%">
              <thead className="thead-light">
                <tr>
                  <th scope="col" width="20%">รหัสผังบัญชี </th>
                  <th scope="col" width="40%">ชื่อผังบัญชี</th>
                  <th scope="col" width="20%">ประเภท</th>
                  <th scope="col" width="20%"></th>
                </tr>
              </thead>
              <tbody>
                {
                  !this.state.loading ? this.onRenderItem() :
                    <tr className="col-md-3 text-center">
                      <td colSpan="99" className="text-center">
                        <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

      </>
    );
  }
}

export default AccountChartList;
