import React, { Component } from 'react';
import { AuthService } from '../services/auth_service';
import MessageService from '../services/message_service.js';
import SetupPassword from './setup_password';
import HttpHelper from '../helpers/http_helper.js';

class ProcessForgotPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      valid: true,
      loading: true
    }

  }

  componentDidMount() {
    HttpHelper.setAuth(null);
    if (!this.props.match.params.token) {
      this.setState({
        valid: false,
        loading: false
      });
    } else {

      HttpHelper.setAuth({
        token_type: 'bearer',
        access_token: this.props.match.params.token
      });

      AuthService.check().then(
        resp => {
          this.setState({
            valid: true,
            loading: false
          });
        },
        error => {
          this.setState({
            valid: false,
            loading: false
          });
        }
      );

    }
  }

  render() {

    return (
      <div className="form-register" >
        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }
        {
          (!this.state.valid && !this.state.loading) &&
          <h3 className="text-center">ไม่พบข้อมูล</h3>
        }
        {
          (this.state.valid && !this.state.loading) &&
          <SetupPassword history={this.props.history} forgot={true} />
        }
      </div>
    );
  }
}

export default ProcessForgotPassword;
