import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const AccountingService = {
    getCriteria,
    save,
    getList,
    getDetail,
    getPayment,
    savePayment,
    getAccMapping,
    saveAccMapping,
    voidAcc
};

export function getList(farmID) {
    return fetch(`${config.ApiEndpoint}/api/accounting/farm/${farmID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function getDetail(id) {
    return fetch(`${config.ApiEndpoint}/api/accounting/${id}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}
export function getPayment(id) {
    return fetch(`${config.ApiEndpoint}/api/accounting/${id}/payment`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}
export function getAccMapping(id) {
    return fetch(`${config.ApiEndpoint}/api/accounting/${id}/mapping`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}
export function saveAccMapping(id, data) {
    return fetch(`${config.ApiEndpoint}/api/accounting/${id}/mapping`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}
export function getCriteria(accountingType, farmID) {
    return fetch(`${config.ApiEndpoint}/api/accounting/criteria/${accountingType}/${farmID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function save(data) {
    return fetch(`${config.ApiEndpoint}/api/accounting`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}

export function savePayment(data) {
    return fetch(`${config.ApiEndpoint}/api/accounting/payment`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}

export function voidAcc(id) {
    return fetch(`${config.ApiEndpoint}/api/accounting/void/${id}`, HttpHelper.httpPost()).then(HttpHelper.handleResponse);
}