import React, { Component } from 'react';
import './app.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Login from './public/login.js';
import Register from './public/register.js';
import MessageService from './services/message_service.js';
import HttpHelper from './helpers/http_helper.js';
import MyFarm from './farm/my_farm.js';
import AddFarm from './farm/add_farm';
import ManageFarm from './farm/manage_farm';
import EditProfile from './user/edit_profile';
import ManageAccountChart from './account_chart/manage_account_chart';
import ManageItem from './item/manage_item';
import ManageAsset from './asset/manage_asset';
import ManagePlot from './plot/manage_plot';
import ManagePayable from './partner/manage_payable';
import ManageReceivable from './partner/manage_receivable';
import ManageCrop from './crop/manage_crop';
import DetailCrop from './crop/detail_crop';
import HarvestCrop from './crop/harvest_crop';
import DetailHarvest from './crop/detail_harvest';
import AccountingSell from './accounting/accounting_sell';
import AccountingDetail from './accounting/accounting_detail';
import AccountingBuy from './accounting/accounting_buy';
import AccountingExpense from './accounting/accounting_expense';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import th from 'date-fns/locale/th';
import AccountingIncome from './accounting/accounting_income';
import AccountingWithdraw from './accounting/accounting_withdraw';
import AccountingWithdrawExpired from './accounting/accounting_withdraw_expired';
import AccountingDebt from './accounting/accounting_debt';
import ChangePassword from './user/change_password';
import ForgotPassword from './public/forgot_password';
import ProcessForgotPassword from './public/process_forgot_password';
import AccountingHarvest from './accounting/accounting_harvest';

registerLocale('th', th);
setDefaultLocale('th');

class App extends Component {

  constructor(props) {
    super(props)

  }

  componentDidMount() {
    MessageService.ErrorEvent.asObservable().subscribe(error => {
      if (error.message) {
        toast.error(error.message);
      }
    });
    MessageService.SuccessEvent.asObservable().subscribe(success => {
      if (success.message) {
        toast.success(success.message);
      }
    });
  }

  render() {
    return (
      <>
        <Router>
          <Switch>

            <Route path="/" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/setup-password/:token" exact component={ProcessForgotPassword} />

            <PrivateRoute path="/my-farm/:id" component={ManageFarm} />
            <PrivateRoute path="/my-farm" component={MyFarm} />
            <PrivateRoute path="/add-farm" component={AddFarm} />
            <PrivateRoute path="/edit-farm/:id" component={AddFarm} />
            <PrivateRoute path="/edit-profile" component={EditProfile} />
            <PrivateRoute path="/change-password" component={ChangePassword} />
            <PrivateRoute path="/add-account-chart/:farmID/:parentID" component={ManageAccountChart} />
            <PrivateRoute path="/edit-account-chart/:farmID/:id" component={ManageAccountChart} />
            <PrivateRoute path="/add-item/:farmID" component={ManageItem} />
            <PrivateRoute path="/edit-item/:farmID/:id" component={ManageItem} />
            <PrivateRoute path="/add-asset/:farmID" component={ManageAsset} />
            <PrivateRoute path="/edit-asset/:farmID/:id" component={ManageAsset} />
            <PrivateRoute path="/add-plot/:farmID" component={ManagePlot} />
            <PrivateRoute path="/edit-plot/:id" component={ManagePlot} />
            <PrivateRoute path="/add-payable/:farmID" component={ManagePayable} />
            <PrivateRoute path="/edit-payable/:id" component={ManagePayable} />
            <PrivateRoute path="/add-receivable/:farmID" component={ManageReceivable} />
            <PrivateRoute path="/edit-receivable/:id" component={ManageReceivable} />
            <PrivateRoute path="/add-crop/:farmID" component={ManageCrop} />
            <PrivateRoute path="/edit-crop/:farmID/:id" component={ManageCrop} />
            <PrivateRoute path="/detail-crop/:id" component={DetailCrop} />
            <PrivateRoute path="/detail-harvest/:id" component={DetailHarvest} />
            <PrivateRoute path="/harvest-crop/:id" component={HarvestCrop} />
            <PrivateRoute path="/add-accounting-sell/:farmID" component={AccountingSell} />
            <PrivateRoute path="/add-accounting-buy/:farmID" component={AccountingBuy} />
            <PrivateRoute path="/add-accounting-expense/:farmID" component={AccountingExpense} />
            <PrivateRoute path="/add-accounting-income/:farmID" component={AccountingIncome} />
            <PrivateRoute path="/add-accounting-withdraw/:farmID" component={AccountingWithdraw} />
            <PrivateRoute path="/add-accounting-withdraw-expired/:farmID" component={AccountingWithdrawExpired} />
            <PrivateRoute path="/add-accounting-harvest/:farmID" component={AccountingHarvest} />
            <PrivateRoute path="/accounting-detail/:id" component={AccountingDetail} />
            <PrivateRoute path="/accounting-accept-payment/:id" component={AccountingDebt} />
            <PrivateRoute path="/report/:farmID" component={AccountingDebt} />
          </Switch>
        </Router>
        <>
          <ToastContainer autoClose={2000}></ToastContainer>
        </>
      </>

    );
  }

}

function PrivateRoute({ component: Component, ...rest }) {

  const isAuth = HttpHelper.getAuthUser();

  let selectedMenu = rest.menu;
  return (
    <Route
      {...rest}
      render={props => isAuth ? (<Component {...props} menu={selectedMenu} />) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
      }
    />
  );
}

export default App;
