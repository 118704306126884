import React, { Component } from 'react';

class FooterLayout extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }


  render() {

    return (
      <>
        <footer className="footer mt-auto py-3">
          <div className="container text-center">
            <span>©2019 ลิขสิทธ์ถูกต้อง, มหาวิทยาลัยแม่โจ้</span>
          </div>
        </footer>
      </>
    );
  }
}

export default FooterLayout;
