import React, { Component } from 'react';
import MainLayout from '../main/main_layout';
import { AuthService } from '../services/auth_service';
import HttpHelper from '../helpers/http_helper.js';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';

class SetupPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      errors: []
    }

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onPrefixChange(event) {
    this.setState({
      'prefix': event.target.text
    });

  }

  handleFieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleCheckChange(event) {
    this.setState({
      [event.target.name]: event.target.checked ? event.target.value : ''
    });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onSubmit() {
    this.setState({
      loading: true
    });
    AuthService.setupPassword({
      password: this.state.password
    }).then(
      resp => {
        HttpHelper.setAuth(resp);
        this.setState({ loading: false });
        MessageService.SuccessEvent.next({ message: 'ตั้งรหัสผ่านใหม่เรียบร้อยแล้ว' });

        if (this.props.forgot) {
          this.props.history.push('/')
        }

      },
      error => {
        this.setState({
          loading: false
        });
      }
    )

  }

  onValidate() {
    let errors = {};
    if (!this.state.password) {
      errors["password"] = "โปรดระบุรหัสผ่าน";
    }

    if (!this.state.confirm_password) {
      errors["confirm_password"] = "โปรดยืนยันรหัสผ่าน";
    }

    if (window.$.isEmptyObject(errors) && this.state.password !== this.state.confirm_password) {
      errors["confirm_password"] = "รหัสผ่านไม่ตรงกัน";
    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  onBackClick(event) {
    event.preventDefault();
    window.history.back();
  }

  componentDidMount() {

  }

  render() {

    return (

      <>
        <h4 className="mt-2">ตั้งรหัสผ่านใหม่</h4>
        <div className="row">
          <div className="col-md-12">
            <form noValidate autoComplete="off">
              <div className="form-group">
                <label htmlFor="password">รหัสผ่าน:</label>
                <input type="password" className="form-control" id="password" name="password" aria-describedby="password" placeholder="รหัสผ่าน" autoComplete="off"
                  onChange={this.handleFieldChange} />
                {this.renderErrorFor('password')}
              </div>
              <div className="form-group">
                <label htmlFor="confirm_password">ยืนยันรหัสผ่าน:</label>
                <input type="password" className="form-control" id="confirm_password" name="confirm_password" aria-describedby="confirm_password" autoComplete="off"
                  placeholder="ยืนยันรหัสผ่าน" onChange={this.handleFieldChange} />
                {this.renderErrorFor('confirm_password')}
              </div>
              <div className="form-group text-right">
                {
                  !this.props.forgot &&
                  <>
                    <button type="button" className="btn btn-outline-secondary btn-120" onClick={this.onBackClick} disabled={this.state.loading}>ยกเลิก</button>&emsp;
                    <button type="button" className="btn btn-primary btn-primary-green btn-120" disabled={this.state.loading} onClick={this.onValidate}>
                      {this.state.loading && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &emsp;</span>}ตกลง
                    </button>
                  </>
                }
                {
                  this.props.forgot &&
                  <>
                    <button type="button" className="btn btn-primary btn-primary-green" style={{ width: '100%' }} disabled={this.state.loading} onClick={this.onValidate}>
                      {this.state.loading && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &emsp;</span>}ตกลง
                    </button>
                  </>
                }

              </div>
            </form>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body="ตั้งรหัสผ่าน" refID="confirm" onConfirm={this.onSubmit} />
      </>

    );
  }
}

export default SetupPassword;
