import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const CropService = {
    getByFarmID,
    getDetail,
    getByID,
    deleteByID,
    save,
    harvest,
    voidCrop,
    getAccMapping,
    saveAccMapping,
};

export function getByFarmID(farmID) {
    return fetch(`${config.ApiEndpoint}/api/crop/farm/${farmID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function getDetail(id) {
    return fetch(`${config.ApiEndpoint}/api/crop/detail/${id}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function save(data) {
    return fetch(`${config.ApiEndpoint}/api/crop`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}

export function harvest(cropID, data) {
    return fetch(`${config.ApiEndpoint}/api/crop/harvest/${cropID}`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}

export function voidCrop(cropID) {
    return fetch(`${config.ApiEndpoint}/api/crop/void/${cropID}`, HttpHelper.httpPost()).then(HttpHelper.handleResponse);
}

export function getByID(id) {
    return fetch(`${config.ApiEndpoint}/api/crop/${id}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function deleteByID(id) {
    return fetch(`${config.ApiEndpoint}/api/crop/${id}`, HttpHelper.httpDelete()).then(HttpHelper.handleResponse);
}

export function getAccMapping(id) {
    return fetch(`${config.ApiEndpoint}/api/crop/${id}/mapping`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}
export function saveAccMapping(id, data) {
    return fetch(`${config.ApiEndpoint}/api/crop/${id}/mapping`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}