import React, { Component } from 'react';

class FarmMenu extends Component {

  constructor(props) {
    super(props)

    this.state = {
      menu: '',
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const menu = params.get('m');
    if (menu) {
      this.menuSelected(menu);
    }
  }

  menuSelected(menu) {
    this.props.onMenuSelected(menu);
    this.setState({ menu: menu });
  }

  render() {
    return (
      <>
        <div className="col-md-3" id="menu-item">
          <p style={{ marginTop: '20px' }} className="text-muted">จัดการบัญชี </p>
          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a onClick={() => this.menuSelected('accounting')} className={`nav-link ${this.state.menu === 'accounting' && 'active'}`}>บัญชี</a>
            <a onClick={() => this.menuSelected('item')} className={`nav-link ${this.state.menu === 'item' && 'active'}`}>สินค้า</a>
            <a onClick={() => this.menuSelected('asset')} className={`nav-link ${this.state.menu === 'asset' && 'active'}`}>สินทรัพย์</a>
            <a onClick={() => this.menuSelected('account-chart')} className={`nav-link ${this.state.menu === 'account-chart' && 'active'}`}>ผังบัญชี</a>
            <a onClick={() => this.menuSelected('payable')} className={`nav-link ${this.state.menu === 'payable' && 'active'}`}>เจ้าหนี้</a>
            <a onClick={() => this.menuSelected('receivable')} className={`nav-link ${this.state.menu === 'receivable' && 'active'}`}>ลูกหนี้</a>
            <a onClick={() => this.menuSelected('report')} className={`nav-link ${this.state.menu === 'report' && 'active'}`}>รายงาน</a>
          </div>
          <p style={{ marginTop: '20px' }} className="text-muted">การเพาะปลูก</p>
          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a onClick={() => this.menuSelected('crop')} className={`nav-link ${this.state.menu === 'crop' && 'active'}`}>จัดการรอบปลูก</a>
            <a onClick={() => this.menuSelected('plot')} className={`nav-link ${this.state.menu === 'plot' && 'active'}`}>จัดการแปลง</a>
          </div>
          <p style={{ marginTop: '20px' }} className="text-muted">ตั้งค่า</p>
          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a onClick={() => this.menuSelected('setting')} className={`nav-link ${this.state.menu === 'setting' && 'active'}`}>ตั้งค่าฟาร์ม</a>
          </div>
        </div>
      </>
    );
  }
}



export default FarmMenu;
