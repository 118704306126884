import React, { Component } from 'react';
import ManagePartner from './manage_partner';

class ManageReceivable extends Component {

  render() {

    const partnerID = this.props.match.params.id;
    const farmID = this.props.match.params.farmID;


    return (
      <>
        <ManagePartner partnerType={"RECEIVABLE"} isAdd={partnerID === undefined} partnerID={partnerID} farmID={farmID} />
      </>
    );
  }
}

export default ManageReceivable;
