import React, { Component } from 'react';
import MainLayout from '../main/main_layout';
import { FarmService } from '../services/farm_service.js';
import { Link } from "react-router-dom";

class MyFarm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: []
    }

  }

  componentDidMount() {
    this.setState({ loading: true });
    FarmService.getAll().then(
      resp => {
        this.setState({
          data: resp,
          loading: false
        });
      },
      error => {
        this.setState({ loading: false });
      }
    )
  }


  onRenderItem() {
    const data = this.state.data.map(d => {
      return (
        <div key={d.id} className="col-md-3 col-sm-2">
          <figure className="figure full-width">
            <a href="javascript:void(0)" onClick={() => this.onSelectedFarm(d)} >
              <img src={d.image} className="rounded" style={{ width: '240px', height: '160px' }} alt="..."></img>
              <figcaption className="figure-caption text-center text-dark mt-3">{d.name}</figcaption>
              <figcaption className="figure-caption text-center">{d.tags}</figcaption>
            </a>
          </figure>
        </div>
      );
    });
    return data;
  }

  onSelectedFarm(farm) {
    this.props.history.push({
      pathname: `/my-farm/${farm.id}?m=accounting`,
      state: { farm: farm }
    })
  }


  render() {
    return (
      <MainLayout >

        <main role="main" className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb no-pdd">
              <li className="breadcrumb-item" aria-current="page">ฟาร์มของฉัน</li>
              <li className="breadcrumb-item" aria-current="page"></li>
            </ol>
          </nav>
          <div id="content">

            <h4 className="mt-3">ฟาร์มของฉัน</h4>
            <div className="row">
              {
                !this.state.loading ? this.onRenderItem() :
                  <div className="col-md-3 text-center" style={{ paddingTop: '60px' }} >
                    <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                  </div>
              }

              <div className="col-md-3 col-sm-2" >
                <figure className="figure full-width">
                  <Link to={"add-farm"}>
                    <img src="assets/images/add.png" style={{ cursor: 'pointer', width: '240px', height: '160px' }} className="rounded figure-img-add" alt="..."></img>
                    <figcaption className="figure-caption text-center mt-3">สร้างฟาร์มใหม่ </figcaption>
                  </Link>

                </figure>
              </div>
            </div>

          </div>
        </main>

      </MainLayout>
    );
  }
}

export default MyFarm;
