import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ReportService } from '../services/report_service';
import Constant from '../helpers/constant'
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Config from '../helpers/config';

class Report04 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      selected: {
        from: moment().startOf("year").format('YYYY-MM-DD HH:mm:ss'),
        to: moment().endOf("year").format('YYYY-MM-DD HH:mm:ss')
      }
    }

    moment.locale('th');
  }

  componentDidMount() {

    ReportService.getRpt04(this.props.farm.id, this.state.selected).then(
      resp => {
        this.setState({
          loading: false,
          data: resp
        });
      },
      error => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render() {
    return (
      <>
        <div className="row mt-3">


          <div className="col-md-7">
            <p className="searchTitle">ข้อมูล <strong>{this.props.farm.name} </strong>, รายงาน
             <strong> อัตราส่วนทางการเงินที่สำคัญ </strong>
              พ.ศ. {parseInt(moment(this.state.selected.from).format("YYYY")) + 543}
            </p>
          </div>
          {
            (!this.state.loading && this.state.data) &&
            <div className="col-md-5 text-right">
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-04/${this.props.farm.id}/export/xlsx?from=${moment(this.state.selected.from).format("YYYY-MM-DD")}&to=${moment(this.state.selected.to).format("YYYY-MM-DD")}`}
                className="btn btn-primary btn-csv btn-primary-green btn-120"><i className="fas fa-file-csv"></i> Excel
              </a>
              &emsp;
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-04/${this.props.farm.id}/export/pdf?from=${moment(this.state.selected.from).format("YYYY-MM-DD")}&to=${moment(this.state.selected.to).format("YYYY-MM-DD")}`}
                className="btn btn-primary btn-pdf btn-danger btn-120"><i className="fas fa-file-pdf"></i> PDF
              </a>
            </div>
          }
        </div>

        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }
        {
          (!this.state.loading && this.state.data) &&
          <>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    อัตราส่วนทางการเงินที่สำคัญ
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item bg-light text-muted">
                      <div className="row">
                        <div className="col-md-8">สำหรับปี/เดือน สิ้นสุดวันที่ {moment().format('DD-MM')}-{parseInt(moment(this.state.selected.from).format("YYYY")) + 543} </div>
                        <div className="col-md-4 text-right">พ.ศ. {parseInt(moment(this.state.selected.from).format("YYYY")) + 543}</div>
                      </div>
                    </li>
                  </ul>
                  <div className="card-body">
                    <p className="card-title">อัตราส่วนสภาพคล่องทางการเงิน </p>
                    <div className="row report-list">
                      <div className="col-md-8 text-muted">อัตราส่วนทุนหมุนเวียน (เท่า)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_1_1 < 0
                            ? '(' + Math.abs(this.state.data.c_1_1).toLocaleString() + ')'
                            : this.state.data.c_1_1.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">อัตราส่วนทุนหมุนเวียนเร็ว (เท่า)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_1_2 < 0
                            ? '(' + Math.abs(this.state.data.c_1_2).toLocaleString() + ')'
                            : this.state.data.c_1_2.toLocaleString()
                        }
                      </div>
                    </div>
                    <hr />
                    <p className="card-title">อัตราส่วนสภาพคล่องทางการเงิน  </p>
                    <div className="row report-list">
                      <div className="col-md-8 text-muted">อัตราการหมุนเวียนของสินค้าคงเหลือ (รอบ)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_2_1 < 0
                            ? '(' + Math.abs(this.state.data.c_2_1).toLocaleString() + ')'
                            : this.state.data.c_2_1.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">ระยะเวลาขายสินค้าเฉลี่ย (วัน)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_2_2 < 0
                            ? '(' + Math.abs(this.state.data.c_2_2).toLocaleString() + ')'
                            : Math.abs(this.state.data.c_2_2).toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">อัตราการหมุนเวียนของลูกหนี้ (รอบ)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_2_3 < 0
                            ? '(' + Math.abs(this.state.data.c_2_3).toLocaleString() + ')'
                            : this.state.data.c_2_3.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">ระยะเวลาเก็บหนี้เฉลี่ย (วัน)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_2_4 < 0
                            ? '(' + Math.abs(this.state.data.c_2_4).toLocaleString() + ')'
                            : this.state.data.c_2_4.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">อัตราการหมุนเวียนของเจ้าหนี้ (รอบ)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_2_5 < 0
                            ? '(' + Math.abs(this.state.data.c_2_5).toLocaleString() + ')'
                            : this.state.data.c_2_5.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">ระยะเวลาการชำระหนี้เฉลี่ย (วัน) </div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_2_6 < 0
                            ? '(' + Math.abs(this.state.data.c_2_6).toLocaleString() + ')'
                            : this.state.data.c_2_6.toLocaleString()
                        }
                      </div>
                    </div>
                    <hr />
                    <p className="card-title">อัตราส่วนความสามารถในการทำกำไร</p>
                    <div className="row report-list">
                      <div className="col-md-8 text-muted">อัตราส่วนกำไรขั้นต้นต่อค่าขาย (%)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_3_1 < 0
                            ? '(' + Math.abs(this.state.data.c_3_1).toLocaleString() + ')'
                            : this.state.data.c_3_1.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">อัตราส่วนกำไรสุทธิต่อค่าขาย (%)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_3_2 < 0
                            ? '(' + Math.abs(this.state.data.c_3_2).toLocaleString() + ')'
                            : this.state.data.c_3_2.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">อัตราผลตอบแทนต่อสินทรัพย์รวม (%)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_3_3 < 0
                            ? '(' + Math.abs(this.state.data.c_3_3).toLocaleString() + ')'
                            : this.state.data.c_3_3.toLocaleString()
                        }
                      </div>
                    </div>
                    <hr />
                    <p className="card-title">อัตราส่วนโครงสร้างเงินทุน</p>
                    <div className="row report-list">
                      <div className="col-md-8 text-muted">อัตราส่วนหนี้สินต่อส่วนของผู้ถือหุ้น (เท่า)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_4_1 < 0
                            ? '(' + Math.abs(this.state.data.c_4_1).toLocaleString() + ')'
                            : this.state.data.c_4_1.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">อัตราส่วนหนี้สินต่อสินทรัพย์รวม (เท่า)</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_4_2 < 0
                            ? '(' + Math.abs(this.state.data.c_4_2).toLocaleString() + ')'
                            : this.state.data.c_4_2.toLocaleString()
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="row card-buttom mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">จุดคุ้มทุน</div>
                  <div className="card-body">
                    <div className="row report-list">
                      <div className="col-md-8 text-muted">จุดคุ้มทุน</div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_5_1 < 0
                            ? '(' + Math.abs(this.state.data.c_5_1).toLocaleString() + ')'
                            : this.state.data.c_5_1.toLocaleString()
                        }
                      </div>
                      <div className="col-md-8 text-muted">อัตรากำไรส่วนเกิน (ร้อยละ) </div>
                      <div className="col-md-4 text-right text-muted">
                        {
                          this.state.data.c_5_2 < 0
                            ? '(' + Math.abs(this.state.data.c_5_2).toLocaleString() + ')'
                            : this.state.data.c_5_2.toLocaleString()
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }
}
export default Report04;
