import React, { Component } from 'react';
import { AccountChartService } from '../services/account_chart_service'
import Select from "react-dropdown-select";

class MatchingChart extends Component {

  constructor(props) {
    super(props)

    this.state = {
      charts: [],
      detail: [],
      errors: [],
      net: 0,
      loading: true,
    }

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
  }

  handleFieldChange(event) {

    let index = event.target.getAttribute('index');

    let detail = this.state.detail;
    if (!event.target.value) {
      detail[index][event.target.name] = 0;
    } else {
      detail[index][event.target.name] = event.target.value;
    }
    this.setState({
      detail: detail
    });


  }

  setValues(value, index) {

    let detail = this.state.detail;
    if (value.length > 0) {
      detail[index]["account_chart_id"] = value[0].id;
    } else {
      detail[index]["account_chart_id"] = 0;
    }
    this.setState({
      detail: detail
    });

  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  componentDidMount() {

  }

  onDelete(index) {
    let detail = this.state.detail;
    let d = detail[index];
    if (d.id) {
      d.deleted = true;
    } else {
      detail.splice(index, 1);
    }

    this.setState({
      detail: detail
    })

  }

  onValidate(e) {

    e.preventDefault();
    let errors = {};

    let debit = 0;
    let credit = 0;

    let i = 0;
    this.state.detail.forEach(d => {

      if (!d.deleted) {
        if (!d.account_chart_id) {
          errors["account_chart_id_" + i] = "โปรดระบุผังบัญชี";
        }

        // if (!d.debit) {
        //   errors["debit_" + i] = "โปรดระบุเดบิต";
        // }

        // if (!d.credit) {
        //   errors["credit_" + i] = "โปรดระบุเครดิต";
        // }

        debit += parseFloat(d.debit || 0);
        credit += parseFloat(d.credit || 0);

        i += 1;

      }


    });

    if (window.$.isEmptyObject(errors)) {

      // if (this.state.net) {
      //   if (debit !== credit || debit !== parseFloat(this.state.net)) {
      //     errors["credit_debit"] = "ระบุเครดิตและเดบิตไม่ถูกต้อง";
      //   }
      // } else {
      //   if (debit !== credit) {
      //     errors["credit_debit"] = "ระบุเครดิตและเดบิตไม่ถูกต้อง";
      //   }
      // }
      if (debit !== credit) {
        errors["credit_debit"] = "ระบุเครดิตและเดบิตไม่ถูกต้อง";
      }

    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.onSubmitCallback(this.state.detail);
    }

  }


  setLoading(value) {
    this.setState({
      loading: value
    });
  }

  setSaving(value) {
    this.setState({
      saving: value
    });
  }

  init() {

    this.setLoading(true);

    AccountChartService.getAccountChart(this.farmID).then(
      resp => {
        this.setState({
          charts: resp
        });

        this.setLoading(false);
      },
      error => {
        this.setLoading(false);
      }
    )
  }

  onAddClick(e) {

    console.log(this.state.detail);

    let detail = this.state.detail;
    detail.push({
      debit: 0,
      credit: 0
    });
    this.setState({
      detail: detail
    });
  }

  showDialog() {
    window.$('#matching').modal({
      backdrop: 'static',
      keyboard: false
    })
  }

  closeDialog() {
    window.$('#matching').modal('hide');
  }

  render() {

    let itemIndex = -1;
    return (
      <>
        <div className="modal fade" id="matching" tabIndex="-1" role="dialog" aria-labelledby="matchingModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="matchingModalLabel">จับคู่ผังบัญชี</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={this.state.saving}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ width: '80px' }}>#</th>
                      <th scope="col">ผังบัญชี</th>
                      <th scope="col" style={{ width: '20%' }}>เดบิต</th>
                      <th scope="col" style={{ width: '20%' }}>เครดิต</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      this.state.loading &&
                      <tr>
                        <td colSpan="99" className="text-center">
                          <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                        </td>
                      </tr>
                    }

                    {
                      !this.state.loading &&
                      this.state.detail.filter(d => !d.deleted).map(d => {
                        itemIndex++;
                        return (
                          <tr key={itemIndex}>
                            <td scope="row" style={{ verticalAlign: 'middle' }}>
                              <span style={{ cursor: 'pointer' }} index={itemIndex} onClick={(e) => this.onDelete(itemIndex)}>
                                <i className="fas fa-minus-circle fail-dot"></i> {itemIndex + 1}
                              </span>
                            </td>
                            <td scope="row" >

                              <Select name="account_chart_id"
                                labelField="name"
                                searchBy="name"
                                valueField="id"
                                searchable={true}
                                multi={false}
                                noDataLabel="ไม่พบข้อมูล"
                                values={this.state.charts.find(opt => opt.id === d.account_chart_id) ? [this.state.charts.find(opt => opt.id === d.account_chart_id)] : []}
                                options={this.state.charts}
                                onChange={(values) => this.setValues(values, itemIndex)} />


                              {/* <select className="js-example-disabled-results form-control select2-hidden-accessible" value={d.account_chart_id || ''} index={itemIndex} name="account_chart_id" onChange={this.handleFieldChange}
                                onChange={this.handleFieldChange}>
                                <option value={''} >เลือกผังบัญชี</option>
                                {
                                  this.state.charts.map(a => {
                                    return (
                                      <option key={a.id} value={a.id} >{a.name}</option>
                                    )
                                  })
                                }
                              </select> */}
                              {this.renderErrorFor('account_chart_id_' + itemIndex)}
                            </td>
                            <td>
                              <input type="number" className="form-control" placeholder="0.00" name="debit" index={itemIndex} value={d.debit || ''} onChange={this.handleFieldChange} />
                              {this.renderErrorFor('debit_' + itemIndex)}
                            </td>
                            <td>
                              <input type="number" className="form-control" placeholder="0.00" name="credit" index={itemIndex} value={d.credit || ''} onChange={this.handleFieldChange} />
                              {this.renderErrorFor('credit_' + itemIndex)}
                            </td>
                          </tr>
                        )

                      })
                    }
                  </tbody>
                </table>
                <div className="col-md-6 " style={{ paddingLeft: '0px' }}>
                  <button type="button" className="btn btn-light" disabled={this.state.saving} onClick={this.onAddClick}>เพิ่มรายการ</button>
                </div>
                <div className="col-md-12 text-right">
                  {this.renderErrorFor("credit_debit")}
                </div>
              </div>

              {
                !this.state.loading &&
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" disabled={this.state.saving}>ยกเลิก</button>
                  <button type="button" className="btn btn-primary btn-primary-green" onClick={this.onValidate} disabled={this.state.saving}>
                    {
                      this.state.saving &&
                      <>
                        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>&emsp;
                      </>
                    }
                    ยืนยัน
                  </button>
                </div>
              }

            </div>
          </div>
        </div>
        {/* <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body="จับคู่ผังบัญชี ?" refID="confirm_mapping" onConfirm={this.onValidate} /> */}
      </>
    );
  }
}

export default MatchingChart;
