import React, { Component } from 'react';
import '../assets/css/login.css';
import { AuthService } from '../services/auth_service.js';
import HttpHelper from '../helpers/http_helper.js';
import { Link } from "react-router-dom";

class Login extends Component {

  constructor(props) {
    super(props)


    this.state = {
      loading: false,
      email: '',
      password: '',
      errors: []
    }
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  handleFieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.onValidate()) {
      this.doLogin();
    }
  }


  onValidate() {

    let errors = {};
    if (this.state.email.length === 0) {
      errors["email"] = "โปรดระบุอีเมล";
    }
    if (this.state.password.length === 0) {
      errors["password"] = "โปรดระบุรหัสผ่าน";
    }
    this.setState({ errors: errors });

    return window.$.isEmptyObject(errors);

  }

  doLogin() {
    this.setState({ loading: true });
    AuthService.login(this.state.email, this.state.password).then(
      resp => {
        HttpHelper.setAuth(resp);
        this.setState({ loading: false });
        this.props.history.push('/my-farm');
      },
      error => {
        this.setState({ loading: false });
      }
    )
  }

  render() {


    return (
      <form className="form-signin" ref={form => this.formEl = form} onSubmit={this.onSubmit} noValidate>
        <div className="row">
          <div className="col-md-12 text-center">
            <img className="mb-4 text-center" src="../assets/images/login-logo.png" alt="" width="240" height="240" />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">อีเมล:</label>
          <input type="email" className="form-control" id="email" name="email" placeholder="อีเมล" onChange={this.handleFieldChange} />
          {this.renderErrorFor('email')}
        </div>
        <div className="form-group">
          <label htmlFor="password">รหัสผ่าน:</label>
          <input type="password" className="form-control" id="password" name="password" placeholder="รหัสผ่าน" onChange={this.handleFieldChange} />
          {this.renderErrorFor('password')}
        </div>
        <div className="row">
          <div className="col-md-12 text-right ">
            <Link to={'forgot-password'} >ลืมรหัสผ่าน?</Link>
          </div>
        </div>
        <button className="btn btn-primary btn-block btn-primary-green mt-3" type="submit" disabled={this.state.loading}>
          {this.state.loading && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &emsp;</span>}เข้าสู่ระบบ
        </button>
        <div className="row">
          <div className="col-md-12 text-center" style={{ marginTop: "30px" }}>
            <Link to={'register'} >สมัครสมาชิก</Link>
          </div>
        </div>
      </form >
    );
  }

}

export default Login;
