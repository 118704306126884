import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const AuthService = {
    login,
    logout,
    register,
    update,
    setupPassword,
    forgotPassword,
    check
};

export function login(email, password) {
    return fetch(`${config.ApiEndpoint}/api/auth/login`, HttpHelper.httpPost({ email: email, password: password }))
        .then(HttpHelper.handleResponse);
}

export function register(data) {
    return fetch(`${config.ApiEndpoint}/api/auth/register`, HttpHelper.httpPost(data))
        .then(HttpHelper.handleResponse);
}

export function update(data) {
    return fetch(`${config.ApiEndpoint}/api/auth/update`, HttpHelper.httpPost(data))
        .then(HttpHelper.handleResponse);
}

export function setupPassword(data) {
    return fetch(`${config.ApiEndpoint}/api/auth/setup-password`, HttpHelper.httpPost(data))
        .then(HttpHelper.handleResponse);
}

export function forgotPassword(data) {
    return fetch(`${config.ApiEndpoint}/api/auth/forgot-password`, HttpHelper.httpPost(data))
        .then(HttpHelper.handleResponse);
}

export function check() {
    return fetch(`${config.ApiEndpoint}/api/auth/check`, HttpHelper.httpGet())
        .then(HttpHelper.handleResponse);
}

export function logout() {
    localStorage.removeItem('user');
}


