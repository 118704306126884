import React, { Component } from 'react';
import Report01 from './rpt_01';
import Report02 from './rpt_02';
import Report03 from './rpt_03';
import Report04 from './rpt_04';
import Report05 from './rpt_05';
import Report06 from './rpt_06';
import Report07 from './rpt_07';
import Report08 from './rpt_08';
import Report09 from './rpt_09';

class Report extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      rpt_type: 'rpt_01'
    }

    this.onRptTypeChange = this.onRptTypeChange.bind(this);

  }

  componentDidMount() {

  }

  onRptTypeChange(event) {
    this.setState({
      rpt_type: event.target.value
    });
  }

  renderConponent() {

    switch (this.state.rpt_type) {

      case 'rpt_01':
        return (<Report01 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_02':
        return (<Report02 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_03':
        return (<Report03 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_04':
        return (<Report04 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_05':
        return (<Report05 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_06':
        return (<Report06 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_07':
        return (<Report07 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_08':
        return (<Report08 farm={this.props.farm} history={this.props.history} />);
      case 'rpt_09':
        return (<Report09 farm={this.props.farm} history={this.props.history} />);

    }

    return (
      <></>
    );
  }

  render() {
    return (
      <>
        <h4>รายงาน</h4>
        <div className="row">
          <div className="col-md-6">
            <select className="form-control" name="rpt_type" value={this.state.rpt_type} onChange={this.onRptTypeChange}>
              <option value="rpt_01">งบแสดงฐานะทางการเงิน</option>
              <option value="rpt_02">งบกำไรขาดทุน</option>
              <option value="rpt_03">งบต้นทุนผลิต</option>
              <option value="rpt_04">อัตราส่วนทางการเงินที่สำคัญ</option>
              <option value="rpt_05">การ์ดลูกหนี้โดยสรุป</option>
              <option value="rpt_06">รายงานลูกหนี้รับ</option>
              <option value="rpt_07">การ์ดเจ้าหนี้โดยสรุป</option>
              <option value="rpt_08">รายงานสินค้าคงเหลือ </option>
              <option value="rpt_09">รายงานเจ้าหนี้ค้างจ่าย</option>
            </select>
          </div>

        </div>

        {this.renderConponent()}

      </>
    );
  }
}

export default Report;
