import React, { Component } from 'react';
import MatchingChart from './matching_chart';
import MessageService from '../services/message_service.js';
import { CropService } from '../services/crop_service';


class MatchingCrop extends Component {

  constructor(props) {
    super(props)

    this.state = {
      detail: []
    }

    this.onSubmitCallback = this.onSubmitCallback.bind(this);
  }

  componentDidMount() {
    this.matchingChart.onSubmitCallback = this.onSubmitCallback;
  }

  showDialog() {

    this.matchingChart.setLoading(true);
    this.matchingChart.showDialog();

    CropService.getAccMapping(this.cropID).then(
      resp => {
        this.matchingChart.setState({
          detail: resp,
          errors: [],
          net: this.net
        });
        this.matchingChart.setLoading(false);
      }
    )

  }

  init() {
    this.matchingChart.farmID = this.farmID;
    this.matchingChart.init();
  }

  onSubmitCallback(detail) {

    this.matchingChart.setSaving(true);

    CropService.saveAccMapping(this.cropID, detail).then(
      resp => {
        this.matchingChart.setSaving(false);
        this.matchingChart.closeDialog();
        MessageService.SuccessEvent.next({ message: 'จับคู่ผังบัญชีรียบร้อยแล้ว' });
      },
      error => {
        this.matchingChart.setSaving(false);
      }
    )
  }

  render() {

    return (
      <>
        {
          <MatchingChart ref={instance => { this.matchingChart = instance; }} />
        }
      </>
    );
  }
}

export default MatchingCrop;
