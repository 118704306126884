import React, { Component } from 'react';
import Manage from '../main/manage_layout.js';
import { PlotService } from '../services/plot_service';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';

class ManagePlot extends Component {

  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      init: true,
      loading: true,
      deleting: false,
      accountCharts: [],
      data: {
        name: '',
        area: '',
        image: '',
        rai: '',
        ngarn: '',
        var: ''
      },
      errors: [],
      pageName: 'แปลง',
      selectMode: 1
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  componentDidMount() {

    this.isAdd = this.props.match.params.id === undefined;
    let loading = false;
    let init = false;

    if (!this.isAdd) {
      loading = true;
      init = true;
      this.getByID(this.props.match.params.id);
    }

    this.setState({
      loading: loading,
      init: init
    });


  }

  getByID(id) {
    PlotService.getByID(id).then(
      (resp) => {

        this.setState({
          data: resp,
          edit: true,
          loading: false,
          init: false
        });


        this.clac('area', resp.area);
      },
      (error) => {
        this.setState({ loading: false });
      }
    )
  }

  handleFieldChange(event) {

    let data = this.state.data ? this.state.data : {};
    data[event.target.name] = event.target.value;

    if (event.target.name === 'image') {
      let fileUpload = document.getElementById("image");
      let fileName = null;
      if (fileUpload && fileUpload.files.length > 0) {
        fileName = fileUpload.files[0].name;
        this.file = fileUpload.files[0];
      }
      data[event.target.name] = fileName;
    }

    this.setState({
      data: data
    });

  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onValidate() {

    let errors = {};
    if (!this.state.data.name) {
      errors["name"] = "โปรดระบุชื่อแปลง";
    }
    if (!this.state.data.area) {
      errors["area"] = "โปรดระบุพื้นที่";
    }

    // if (this.isAdd) {
    //   if (this.state.data.image.length === 0) {
    //     errors["image"] = "โปรดเลือกรูปภาพแปลง";
    //   }
    // }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  handleSaveClick(event) {
    event.preventDefault();

    this.setState({ loading: true });
    let data = this.state.data;
    if (!this.isAdd) {
      data.id = this.props.match.params.id;
    }
    else {
      data.farm_id = this.props.match.params.farmID;
    }

    delete data.rai;
    delete data.ngarn;
    delete data.va;

    PlotService.save(data).then(
      (resp) => {
        if (this.file) {
          this.upload(resp, this.file);
        } else {
          this.success();
        }
      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }


  upload(resp, fileUpload) {

    let formData = new FormData();
    formData.append('file', fileUpload);

    PlotService.upload(resp.id, formData).then(
      (resp) => {
        this.success();
      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }

  success() {
    this.setState({ loading: false });
    MessageService.SuccessEvent.next({ message: this.state.pageName + 'เรียบร้อยแล้ว' });
    window.history.back();
  }

  handleDeleteClick(event) {
    this.setState({ deleting: true });
    PlotService.deleteByID(this.props.match.params.id).then(
      (resp) => {
        MessageService.SuccessEvent.next({ message: 'ลบแปลงเรียบร้อยแล้ว' });
        this.setState({ deleting: false });
        window.history.back();
      },
      (error) => {
        this.setState({ deleting: false });
      }
    );
  }

  clac(unit, value) {

    let data = this.state.data;
    data[unit] = value;

    if (unit === 'area') {

      data.rai = parseInt(value / 1600);
      let x = value % 1600;
      data.ngarn = parseInt(x / 400);
      x = x % 400;
      data.va = x / 4;

    } else {

      data.area = (this.convert(data.va) / 0.25) + ((this.convert(data.ngarn) * 100) / 0.25) + ((this.convert(data.rai) * 400) / 0.25);

    }

    this.setState({ data: data });

  }

  convert(value) {
    if (value === undefined) {
      return 0;
    }
    if (isNaN(value)) {
      return 0;
    }
    return parseFloat(value);
  }

  onRenderAccountChartItem() {
    return this.state.accountCharts.map(a => {
      return (
        <option key={a.id} value={a.id} >{a.name}</option>
      )
    });
  }

  render() {

    return (
      <Manage edit={this.state.edit}
        pageName={this.state.pageName}
        onSaveClick={this.onValidate}
        handleDeleteClick={this.handleDeleteClick}
        deleting={this.state.deleting}
        init={this.state.init}
        loading={this.state.loading}>

        <div className="container">
          <div className="row" id="content-add">
            <div className="col-md-12">
              <h4 className="mt-5">ข้อมูลแปลง</h4>
              <div className="row">

                {!this.state.loading &&
                  <div className="col-md-12">
                    <form autoComplete="off">
                      <div className="form-group">
                        <label htmlFor="">ชื่อแปลง:</label>
                        <input type="text" className="form-control" placeholder="ชื่อแปลง" name="name" value={this.state.data.name} onChange={this.handleFieldChange} />
                        {this.renderErrorFor('name')}
                      </div>
                      <div className="form-group">
                        <label htmlFor="">รูปภาพ:</label>
                        <div className="custom-file">
                          <input type="file" id="image" className="custom-file-input" name="image" accept="image/*" disabled={this.state.loading} onChange={e => { this.handleFieldChange(e); }}></input>
                          <label id="fileName" className="custom-file-label" htmlFor="image">{this.state.data.image ? this.state.data.image : 'เลือกไฟล์'}</label>
                          {this.renderErrorFor('image')}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">พื้นที่: </label>
                        <div className="btn-group btn-group-custom" role="group" aria-label="Basic example" style={{ width: '100%' }}>
                          <button type="button" style={{ width: '50%' }}
                            className={`btn btn-secondary ${this.state.selectMode === 1 ? 'active' : ''}`} onClick={() => this.setState({ selectMode: 1 })} >ตารางเมตร</button>
                          <button type="button" style={{ width: '50%' }}
                            className={`btn btn-secondary ${this.state.selectMode === 2 ? 'active' : ''}`} onClick={() => this.setState({ selectMode: 2 })}>ไร่, งาน, วา</button>
                        </div>

                        {
                          this.state.selectMode === 1 &&
                          <div className="input-group mb-3 input-group-custom-top" style={{ marginTop: '10px' }}>
                            <input type="number" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon2" name="area"
                              value={this.state.data.area} onChange={(e) => { this.clac(e.target.name, parseFloat(e.target.value)) }} />
                            <div className="input-group-append">
                              <span className="input-group-text" id="basic-addon2">ตร. ม</span>
                            </div>

                          </div>
                        }

                        {
                          this.state.selectMode === 2 &&
                          <div className="row">
                            <div className="col-md-4">
                              <div className="input-group mb-3 input-group-custom-top" style={{ marginTop: '10px' }}>
                                <input type="number" className="form-control" name="rai" value={this.state.data.rai} onChange={(e) => { this.clac(e.target.name, parseInt(e.target.value)) }} />
                                <div className="input-group-append">
                                  <span className="input-group-text" id="basic-addon2">ไร่</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="input-group mb-3 input-group-custom-top" style={{ marginTop: '10px' }}>
                                <input type="number" className="form-control" name="ngarn" value={this.state.data.ngarn} onChange={(e) => { this.clac(e.target.name, parseInt(e.target.value)) }} />
                                <div className="input-group-append">
                                  <span className="input-group-text" id="basic-addon2">งาน</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="input-group mb-3 input-group-custom-top" style={{ marginTop: '10px' }}>
                                <input type="number" className="form-control" name="va" value={this.state.data.va} onChange={(e) => { this.clac(e.target.name, parseFloat(e.target.value)) }} />
                                <div className="input-group-append">
                                  <span className="input-group-text" id="basic-addon2">วา</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 text-right">
                              = {this.state.data.area ? (parseFloat(this.state.data.area)).toLocaleString() : '0'} ตร.ม.
                            </div>
                          </div>
                        }

                        {this.renderErrorFor('area')}
                      </div>
                    </form>
                  </div>
                }
                {
                  this.state.loading &&
                  <div className="col-md-12 text-center">
                    <span><i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></span>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.state.edit ? 'ปรับปรุง' : 'เพิ่ม'}${this.state.pageName} ?`} refID="confirm" onConfirm={this.handleSaveClick} />
      </Manage >
    );
  }
}

export default ManagePlot;
