import React, { Component } from 'react';
import AccountingManage from "./accounting_manage";
import { PartnerService } from "../services/partner_service";
import { AccountingService } from '../services/accounting_service';

class AccountingWithdrawExpired extends Component {

  constructor(props) {
    super(props)


    this.farmID = this.props.match.params.farmID;
    this.accountingType = "WITHDRAW_EXPIRED";


    this.state = {
      init: false
    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <>
        <AccountingManage
          history={this.props.history}
          accountingType={this.accountingType}
          isAdd={this.isAdd}
          farmID={this.farmID}
          pageName={"เพิ่มบัญชี - เบิกเน่า"}
          init={this.state.init}
          partnerLabel={""} />
      </>
    );
  }
}

export default AccountingWithdrawExpired;
