import React, { Component } from 'react';
import AccountingManage from "./accounting_manage";
import { PlotService } from "../services/plot_service";

class AccountingWithdraw extends Component {

  constructor(props) {
    super(props)


    this.farmID = this.props.match.params.farmID;
    this.accountingType = "WITHDRAW";


    this.state = {
      init: true,
      plots: []
    }
  }

  componentDidMount() {

    PlotService.getByFarmID(this.farmID).then(
      (resp) => {
        this.setState({
          init: false,
          plots: resp
        })
      }
    );

  }

  render() {

    return (
      <>
        <AccountingManage
          history={this.props.history}
          accountingType={this.accountingType}
          isAdd={this.isAdd}
          farmID={this.farmID}
          pageName={"เพิ่มบัญชี - เบิกของ"}
          plots={this.state.plots}
          init={this.state.init} />
      </>
    );
  }
}

export default AccountingWithdraw;
