import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const MasterService = {
    getProvinces,
    getDistricts,
    getAmphures,
    getZipCode
};

export function getProvinces() {
    return fetch(`${config.ApiEndpoint}/api/master/province`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function getAmphures(districtID) {
    return fetch(`${config.ApiEndpoint}/api/master/amphure/${districtID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}


export function getDistricts(amphureID) {
    return fetch(`${config.ApiEndpoint}/api/master/district/${amphureID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function getZipCode(districtCode) {
    return fetch(`${config.ApiEndpoint}/api/master/zipcode/${districtCode}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}
