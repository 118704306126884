import React, { Component } from 'react';
import '../assets/css/register.css';
import { Link } from "react-router-dom";
import MessageService from '../services/message_service.js';
import { AuthService } from '../services/auth_service.js';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      full_name: "",
      email: "",
      password: "",
      confirm_password: "",
      prefix: "",
      phone_number: "",
      confirm: "",
      errors: []
    }

    this.onPrefixChange = this.onPrefixChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  onPrefixChange(event) {

    event.preventDefault();
    this.setState({
      'prefix': event.target.text
    });

  }

  handleFieldChange(event) {
    if (event.target.name === "phone_number") {
      if (!isNaN(parseInt(event.target.value)) && isFinite(event.target.value) || event.target.value === "") {
        this.setState({
          [event.target.name]: event.target.value.trim()
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  }

  handleCheckChange(event) {
    this.setState({
      [event.target.name]: event.target.checked ? event.target.value : ''
    });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.onValidate()) {
      this.doRegister();
    }
  }


  onValidate() {
    let errors = {};
    if (!this.state.full_name || !this.state.prefix) {
      errors["full_name"] = "โปรดระบุชื่อ-สกุล";
    }
    if (!this.state.email) {
      errors["email"] = "โปรดระบุอีเมล";
    } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email.trim()))) {
      errors["email"] = "ระบุอีเมลไม่ถูกต้อง";
    }

    if (!this.state.phone_number) {
      errors["phone_number"] = "โปรดระบุโทรศัพท์";
    }
    if (!this.state.password) {
      errors["password"] = "โปรดระบุรหัสผ่าน";
    }
    if (this.state.confirm_password) {
      if (this.state.confirm_password !== this.state.password) {
        errors["confirm_password"] = "รหัสผ่านไม่ตรงกัน";
      }
    } else {
      errors["confirm_password"] = "โปรดระบุยืนยันรหัสผ่าน";
    }

    if (!this.state.confirm) {
      errors["confirm"] = "โปรดยอมรับข้อตกลงการใช้งาน";
    }
    this.setState({ errors: errors });

    return window.$.isEmptyObject(errors);

  }

  doRegister() {
    this.setState({ loading: true });
    AuthService.register({
      full_name: this.state.full_name.trim(),
      email: this.state.email.trim(),
      password: this.state.password,
      prefix: this.state.prefix,
      phone_number: this.state.phone_number,
    }).then(
      resp => {
        MessageService.SuccessEvent.next({ message: 'สมัครสมาชิกเรียบร้อยแล้ว' });
        this.props.history.push('/');
      },
      error => {
        this.setState({ loading: false });
      }
    )
  }

  render() {
    return (

      <form className="form-register" ref={form => this.formEl = form} onSubmit={this.onSubmit} noValidate autoComplete="off">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4>สมัครสมาชิก</h4>
            <p className="text-muted">กรุณาระบุข้อมูลดังต่อไปนี้เพื่อสร้างบัญชีผู้ใช้ในระบบ</p>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">ชื่อ-สกุล:</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.prefix === "" ? 'โปรดเลือก...' : this.state.prefix}
              </button>
              <div className="dropdown-menu">
                <a href="#" className="dropdown-item" onClick={this.onPrefixChange}>นาย</a>
                <a href="#" className="dropdown-item" onClick={this.onPrefixChange}>นาง</a>
                <a href="#" className="dropdown-item" onClick={this.onPrefixChange}>นางสาว</a>
              </div>
            </div>
            <input type="text" className="form-control" id="full_name" placeholder="ชื่อ-สกุล" name="full_name" onChange={this.handleFieldChange}></input>
          </div>
          {this.renderErrorFor('full_name')}
        </div>
        <div className="form-group">
          <label htmlFor="">โทรศัพท์:</label>
          <input type="text" className="form-control" id="phone_number" name="phone_number" placeholder="โทรศัพท์"
            value={this.state.phone_number} onChange={this.handleFieldChange} disabled={this.state.loading}></input>
          {this.renderErrorFor('phone_number')}
        </div>
        <div className="form-group">
          <label htmlFor="">อีเมล:</label>
          <input type="text" className="form-control" id="email" name="email" onChange={this.handleFieldChange} placeholder="อีเมล"></input>
          {this.renderErrorFor('email')}
        </div>
        <div className="form-group">
          <label htmlFor="">รหัสผ่าน:</label>
          <input type="password" className="form-control" id="password" name="password" onChange={this.handleFieldChange} placeholder="รหัสผ่าน"></input>
          {this.renderErrorFor('password')}
        </div>
        <div className="form-group">
          <label htmlFor="">ยืนยันรหัสผ่าน:</label>
          <input type="password" className="form-control" id="confirm_password" name="confirm_password" onChange={this.handleFieldChange} placeholder="ยืนยันรหัสผ่าน"></input>
          {this.renderErrorFor('confirm_password')}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="p-3 mb-2 bg-light text-dark">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="confirm" name="confirm" onChange={this.handleCheckChange} value="confirm" ></input>
                <label className="form-check-label" htmlFor="confirm">
                  ข้าพเจ้าเข้าใจและยอมรับ <a href="#">ข้อตกลงการใช้งาน</a>
                </label>
              </div>
            </div>
            {this.renderErrorFor('confirm')}
          </div>
        </div>
        <br />
        <button className="btn btn-primary btn-block btn-primary-green" type="submit" disabled={this.state.loading}>
          {this.state.loading && <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> &emsp;</span>}สมัครสมาชิก
        </button>
        <div className="row">
          <div className="col-md-12 text-center" id="login-link">
            <Link to={""} >เข้าสู่ระบบ</Link>
          </div>
        </div>
      </form>


    );
  }
}

export default Register;
