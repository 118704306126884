import { BehaviorSubject } from 'rxjs';


class MessageService {
    static ErrorEvent = new BehaviorSubject({ errorMessage: null });
    static SuccessEvent = new BehaviorSubject({ successMessage: null });
    static ProfileEvent = new BehaviorSubject(null);
}

export default MessageService

