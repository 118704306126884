import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const AccountChartService = {
    getAccountChart,
    getParent,
    save,
    getByID,
    deleteByID
};

export function getAccountChart(farmID) {
    return fetch(`${config.ApiEndpoint}/api/account-chart/farm/${farmID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function getParent(parentID) {
    return fetch(`${config.ApiEndpoint}/api/account-chart/${parentID}/root`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function save(data) {
    return fetch(`${config.ApiEndpoint}/api/account-chart`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}
export function getByID(id) {
    return fetch(`${config.ApiEndpoint}/api/account-chart/${id}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function deleteByID(id) {
    return fetch(`${config.ApiEndpoint}/api/account-chart/${id}`, HttpHelper.httpDelete()).then(HttpHelper.handleResponse);
}