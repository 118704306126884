import React, { Component } from 'react';
import Manage from '../main/manage_layout.js';
import { FarmService } from '../services/farm_service.js';
import MessageService from '../services/message_service.js';
import ConfirmDialog from '../main/confirm_dialog';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'

class AddFarm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      init: false,
      loading: false,
      deleting: false,
      file: null,
      d: false,
      name: "",
      tags: [],
      errors: [],
      pageName: 'ฟาร์ม'
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.tagChange = this.tagChange.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({ loading: true, edit: true, init: true });
      FarmService.getFarm(id).then(
        (resp) => {
          let fileName = resp.image.split('/');
          this.setState({
            id: id,
            file: fileName[fileName.length - 1],
            name: resp.name,
            tags: resp.tags.split(','),
            desc: resp.desc,
            loading: false,
            init: false
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      )

    }
  }

  handleFieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });

    if (event.target.name === 'file') {
      let fileUpload = document.getElementById("file");
      let fileName = null;
      if (fileUpload && fileUpload.files.length > 0) {
        fileName = fileUpload.files[0].name;
      }

      this.setState({
        file: fileName
      });
    }
  }

  tagChange(tags) {
    this.setState({
      tags: tags
    });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onValidate() {

    let errors = {};
    if (this.state.name.length === 0) {
      errors["name"] = "โปรดระบุชื่อฟาร์ม";
    }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }


  handleSaveClick(event) {
    event.preventDefault();

    this.setState({ loading: true });
    FarmService.save({
      id: this.state.id,
      name: this.state.name,
      tags: this.state.tags.join(','),
      desc: this.state.desc,
    }).then(

      (resp) => {
        let fileUpload = document.getElementById("file");

        if (fileUpload.files.length > 0) {
          let formData = new FormData();
          formData.append('file', fileUpload.files[0]);

          FarmService.upload(resp.id, formData).then(
            (resp) => {
              this.success();
            },
            (error) => {
              this.setState({ loading: false });
            }
          );
        } else {
          this.success();
        }

      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }

  success() {
    this.setState({ loading: false });
    if (this.state.edit) {
      MessageService.SuccessEvent.next({ message: 'ปรับปรุงฟาร์มเรียบร้อยแล้ว' });
    } else {
      MessageService.SuccessEvent.next({ message: 'สร้างฟาร์มเรียบร้อยแล้ว' });
    }
    window.history.back();
  }

  handleDeleteClick(event) {
    this.setState({ deleting: true });

    FarmService.deleteFarm(this.state.id).then(
      (resp) => {
        MessageService.SuccessEvent.next({ message: 'ลบฟาร์มเรียบร้อยแล้ว' });
        this.setState({ deleting: false });
        this.props.history.push('/my-farm');
      },
      (error) => {
        this.setState({ deleting: false });
      }
    );
  }

  render() {

    return (
      <Manage edit={this.state.edit}
        pageName={this.state.pageName}
        onSaveClick={this.onValidate}
        handleDeleteClick={this.handleDeleteClick}
        deleting={this.state.deleting}
        init={this.state.init}
        loading={this.state.loading}>

        <div className="container">
          <div className="row" id="content-add">
            <div className="col-md-12">
              <h4 className="mt-5">ข้อมูลฟาร์ม</h4>
              <div className="row mt-4">
                <div className="col-md-12">
                  <form autoComplete="off" noValidate disabled>
                    <div className="form-group">
                      <label htmlFor="name">ชื่อฟาร์ม:</label>
                      <input type="text" className="form-control" placeholder="ชื่อฟาร์ม" name="name" value={this.state.name} disabled={this.state.loading} onChange={this.handleFieldChange}></input>
                      {this.renderErrorFor('name')}
                    </div>
                    <div className="form-group">
                      <label htmlFor="tags">Tags:</label>
                      <TagsInput inputProps={{ placeholder: 'เพิ่ม Tag' }}
                        value={this.state.tags} onChange={this.tagChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">รูปภาพ:</label>
                      <div className="custom-file">
                        <input type="file" id="file" className="custom-file-input" name="file" accept="image/*" disabled={this.state.loading} onChange={e => { this.handleFieldChange(e); }}></input>
                        <label id="fileName" className="custom-file-label" id="" htmlFor="file">{this.state.file ? this.state.file : 'เลือกไฟล์'}</label>
                      </div>
                      {this.renderErrorFor('file')}
                    </div>
                    <div className="form-group">
                      <label htmlFor="desc">รายละเอียด:</label>
                      <textarea className="form-control" maxLength="255"
                        id="desc"
                        rows="3"
                        placeholder="รายละเอียด"
                        name="desc"
                        value={this.state.desc}
                        disabled={this.state.loading}
                        onChange={this.handleFieldChange}>
                      </textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.state.edit ? 'ปรับปรุง' : 'เพิ่ม'}${this.state.pageName} ?`} refID="confirm" onConfirm={this.handleSaveClick} />
      </Manage >
    );
  }
}

export default AddFarm;
