import config from '../helpers/config.js';
import HttpHelper from '../helpers/http_helper.js';

export const PartnerService = {
    getByFarmID,
    save,
    upload,
    getByID,
    deleteByID
};

export function getByFarmID(type, farmID) {
    return fetch(`${config.ApiEndpoint}/api/partner/${type}/farm/${farmID}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function save(data) {
    return fetch(`${config.ApiEndpoint}/api/partner`, HttpHelper.httpPost(data)).then(HttpHelper.handleResponse);
}

export function upload(id, data) {

    let auth = JSON.parse(localStorage.getItem('auth'));
    let headers = null;
    if (auth) {
        headers = {
            'Authorization': auth.token_type + ' ' + auth.access_token
        };
    }

    return fetch(`${config.ApiEndpoint}/api/partner/${id}/upload`, {
        method: 'POST',
        headers: headers,
        body: data
    }).then(HttpHelper.handleResponse);
}

export function getByID(id) {
    return fetch(`${config.ApiEndpoint}/api/partner/${id}`, HttpHelper.httpGet()).then(HttpHelper.handleResponse);
}

export function deleteByID(id) {
    return fetch(`${config.ApiEndpoint}/api/partner/${id}`, HttpHelper.httpDelete()).then(HttpHelper.handleResponse);
}