import React, { Component } from 'react';
import Manage from '../main/manage_layout.js';
import { AssetService } from '../services/asset_service';
import { AccountChartService } from '../services/account_chart_service';
import MessageService from '../services/message_service.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import ConfirmDialog from '../main/confirm_dialog';

class AssetItem extends Component {

  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      init: true,
      loading: true,
      deleting: false,
      accountCharts: [],
      data: {
        name: '',
        account_chart_id: '',
        image: '',
        life_time: '',
        asset_value: ''
      },
      errors: [],
      pageName: 'สินทรัพย์'
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onValidate = this.onValidate.bind(this);

    moment.locale('th');
  }

  componentDidMount() {

    this.isAdd = this.props.match.params.id === undefined;
    AccountChartService.getAccountChart(this.props.match.params.farmID).then(
      (resp) => {

        let loading = false;
        let init = false;

        if (!this.isAdd) {
          loading = true;
          init = true;
          this.getByID(this.props.match.params.id);
        }

        this.setState({
          accountCharts: resp,
          loading: loading,
          init: init
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    )

  }

  getByID(id) {
    AssetService.getByID(id).then(
      (resp) => {
        if (resp.buy_date) {
          resp.buy_date = moment(resp.buy_date).toDate();
        }
        this.setState({
          data: resp,
          edit: true,
          loading: false,
          init: false
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    )
  }

  handleFieldChange(event) {

    let data = this.state.data ? this.state.data : {};
    data[event.target.name] = event.target.value;

    if (event.target.name === 'image') {
      let fileUpload = document.getElementById("image");
      let fileName = null;
      if (fileUpload && fileUpload.files.length > 0) {
        fileName = fileUpload.files[0].name;
        this.file = fileUpload.files[0];
      }
      data[event.target.name] = fileName;
    }

    this.setState({
      data: data
    });

  }

  handleDateChange(date) {
    let data = this.state.data ? this.state.data : {};
    data.buy_date = moment(date).toDate();
    this.setState({ data: data });
  }

  hasErrorFor(field) {
    return !!this.state.errors[field]
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className='error'>
          <strong>* {this.state.errors[field]} &emsp;</strong>
        </span>
      )
    }
  }

  onValidate() {

    let errors = {};
    if (this.state.data.name.length === 0) {
      errors["name"] = "โปรดระบุชื่อสินทรัพย์";
    }
    if (this.state.data.account_chart_id.length === 0) {
      errors["account_chart_id"] = "โปรดระบุผังบัญชี";
    }

    // if (this.isAdd) {
    //   if (this.state.data.image.length === 0) {
    //     errors["image"] = "โปรดเลือกรูปภาพสินค้า";
    //   }
    // }

    this.setState({ errors: errors });

    if (window.$.isEmptyObject(errors)) {
      this.confirmDialog.showConfirm();
    }

  }

  handleSaveClick(event) {
    event.preventDefault();
    this.setState({ loading: true });
    let data = this.state.data;
    if (!this.isAdd) {
      data.id = this.props.match.params.id;
    }
    data.farm_id = this.props.match.params.farmID;
    if (data.buy_date) {
      data.buy_date = moment(data.buy_date).format('YYYY-MM-DD 00:00:00');
    }

    AssetService.save(data).then(
      (resp) => {
        if (this.file) {
          this.upload(resp, this.file);
        } else {
          this.success();
        }
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }


  upload(resp, fileUpload) {

    let formData = new FormData();
    formData.append('file', fileUpload);

    AssetService.upload(resp.id, formData).then(
      (resp) => {
        this.success();
      },
      (error) => {
        this.setState({ loading: false });
      }
    );

  }

  success() {
    this.setState({ loading: false });
    MessageService.SuccessEvent.next({ message: this.state.pageName + 'เรียบร้อยแล้ว' });
    window.history.back();
  }

  handleDeleteClick(event) {
    this.setState({ deleting: true });
    AssetService.deleteByID(this.props.match.params.id).then(
      (resp) => {
        MessageService.SuccessEvent.next({ message: 'ลบสินทรัพย์เรียบร้อยแล้ว' });
        this.setState({ deleting: false });
        window.history.back();
      },
      (error) => {
        this.setState({ deleting: false });
      }
    );
  }

  render() {

    return (
      <Manage edit={this.state.edit}
        pageName={this.state.pageName}
        onSaveClick={this.onValidate}
        handleDeleteClick={this.handleDeleteClick}
        deleting={this.state.deleting}
        init={this.state.init}
        loading={this.state.loading}>

        <div className="container">
          <div className="row" id="content-add">
            <div className="col-md-12">
              <h4 className="mt-5">ข้อมูลสินทรัพย์</h4>
              <div className="row">

                {!this.state.loading &&
                  <div className="col-md-12">
                    <form>
                      <div className="form-group">
                        <label >ชื่อสินทรัพย์:</label>
                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="ชื่อสินทรัพย์" name="name" autoComplete="off"
                          value={this.state.data.name || ''} onChange={this.handleFieldChange} ></input>
                        {this.renderErrorFor('name')}
                      </div>
                      <div className="form-group">
                        <label>ผังบัญชี:</label>
                        <select className="form-control" value={this.state.data.account_chart_id} name="account_chart_id"
                          onChange={this.handleFieldChange}>
                          <option value={''} >เลือกผังบัญชี</option>
                          {
                            this.state.accountCharts.map(a => {
                              return (
                                <option key={a.id} value={a.id} >{a.name}</option>
                              )
                            })
                          }
                        </select>
                        {this.renderErrorFor('account_chart_id')}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">รูปภาพสินค้า:</label>
                        <div className="custom-file">
                          <input type="file" id="image" className="custom-file-input" name="image" accept="image/*" onChange={e => { this.handleFieldChange(e); }}></input>
                          <label id="fileName" className="custom-file-label" htmlFor="image">{this.state.data.image ? this.state.data.image : 'เลือกไฟล์'}</label>
                        </div>
                        {this.renderErrorFor('image')}
                      </div>
                      <div className="form-group">
                        <label htmlFor="">วันที่ซื้อ:</label>
                        <DatePicker
                          locale="th"
                          placeholderText="วันที่ซื้อ"
                          dateFormat="dd MMM yyyy"
                          className="form-control"
                          selected={this.state.data.buy_date}
                          onChange={this.handleDateChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">อายุการใช้งาน (ปี): </label>
                        <input type="number" className="form-control" name="life_time" placeholder="ปี" value={this.state.data.life_time || ''} onChange={this.handleFieldChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">มูลค่า (บาท):</label>
                        <input type="number" className="form-control" name="asset_value" placeholder="0.00" value={this.state.data.asset_value || ''} onChange={this.handleFieldChange} />
                      </div>
                    </form>
                  </div>
                }
                {this.state.loading &&
                  <div className="col-md-12 text-center">
                    <span><i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></span>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog ref={instance => { this.confirmDialog = instance; }} title="ยืนยัน" body={`${this.state.edit ? 'ปรับปรุง' : 'เพิ่ม'}${this.state.pageName} ?`} refID="confirm" onConfirm={this.handleSaveClick} />
      </Manage >
    );
  }
}

export default AssetItem;
