import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { AccountingService } from "../services/accounting_service";
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Constant from '../helpers/constant';


class AccountingList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: []
    }

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  componentDidMount() {

    AccountingService.getList(this.props.farm.id).then(
      resp => {
        this.setState({
          data: resp,
          loading: false
        });

        this.dataTable = window.$('.table').DataTable({
          dom: 'frtlip',
          ordering: false,
          language: {
            emptyTable: "ไม่พบข้อมูล",
            zeroRecords: "ไม่พบข้อมูล",
            paginate: {
              previous: '‹',
              next: '›'
            },
            sLengthMenu: "แสดง _MENU_ รายการ/หน้า",
            aria: {
              paginate: {
                previous: 'ก่อนหน้า',
                next: 'ถัดไป'
              }
            }
          }
        });

        window.$(".dataTables_length").css('clear', 'none');
        window.$(".dataTables_length").css('margin-right', '20px');
        window.$(".dataTables_info").css('display', 'none');
        window.$(".dataTables_filter").css('display', 'none');
        window.$(".dataTables_paginate").css('margin-top', '-40px');

      },
      error => {
        this.setState({ loading: false });
      }
    )



  }

  onStatusChange(event) {
    let status = Constant.StatusDisplay[event.target.value];

    if (this.dataTable.column(4).search() !== status) {
      if (!status) {
        status = " ";
      }

      this.dataTable
        .column(4)
        .search(status)
        .draw();
    }

  }

  onSearchChange(event) {
    this.dataTable.search(event.target.value);
    this.dataTable.draw();
  }

  onAddNewClick(type) {
    switch (type) {
      case 'SELL':
        this.props.history.push('/add-accounting-sell/' + this.props.farm.id);
        break;

      case 'BUY':
        this.props.history.push('/add-accounting-buy/' + this.props.farm.id);
        break;

      case 'EXPENSE':
        this.props.history.push('/add-accounting-expense/' + this.props.farm.id);
        break;

      case 'INCOME':
        this.props.history.push('/add-accounting-income/' + this.props.farm.id);
        break;

      case 'WITHDRAW':
        this.props.history.push('/add-accounting-withdraw/' + this.props.farm.id);
        break;

      case 'WITHDRAW_EXPIRED':
        this.props.history.push('/add-accounting-withdraw-expired/' + this.props.farm.id);
        break;

      case 'HARVEST':
        this.props.history.push('/add-accounting-harvest/' + this.props.farm.id);
        break;
    }
  }

  render() {
    return (
      <>
        <h4>บัญชี</h4>
        <div className="row">
          <div className="col-md-4">
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="ค้นหา"
                aria-label="Recipient's " aria-describedby="basic-addon2" onChange={this.onSearchChange}></input>
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <select className="form-control" disabled={this.state.loading} onChange={this.onStatusChange}>
              <option value={""}>ทุกสถานะ</option>
              <option value={"SUCCESS"}>{Constant.StatusDisplay["SUCCESS"]}</option>
              <option value={"VOID"}>{Constant.StatusDisplay["VOID"]}</option>
            </select>
          </div>
          <div className="col-md-4 text-right">
            <button type="button" className="btn btn-primary btn-primary-green" data-toggle="modal" data-target="#accounting-type">
              เพิ่มบัญชี
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table id="example" className="table table-hover" width="100%">
              <thead className="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" width="20%">ประเภท</th>
                  <th scope="col" width="20%">เจ้าหนี้/ลูกหนี้</th>
                  <th scope="col" width="20%">วันที่</th>
                  <th scope="col" width="20%">สถานะ</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.loading &&
                  <tr className="col-md-3 text-center">
                    <td colSpan="99" className="text-center">
                      <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                    </td>
                  </tr>
                }

                {
                  !this.state.loading &&
                  this.state.data.map(d => {
                    return (
                      <tr key={d.id}>
                        <td scope="row" >
                          <Link to={`/accounting-detail/${d.id}`}  >
                            {d.display_id}
                          </Link>
                        </td>
                        <td scope="row">
                          {Constant.AccountTypeDisplay[d.accounting_type]}
                        </td>
                        <td scope="row">
                          {d.credit_partner_name}
                        </td>
                        <td scope="row">
                          {moment(d.accounting_date).format('DD MMM YYYY')}
                        </td>
                        <td scope="row">
                          {
                            d.status === 'SUCCESS' ? <div className="status success"></div> : <div className="status void"></div>
                          }
                          {Constant.StatusDisplay[d.status]}
                        </td>
                      </tr>
                    )
                  })

                }
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal fade" id="accounting-type" tabIndex="-1" role="dialog" aria-labelledby="accounting-type-title" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="accounting-type-title">เพิ่มบัญชี</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <small>กรุณาเลือกประเภทบัญชี: </small>
                <button type="button" className="menu-item" data-dismiss="modal" onClick={(e) => this.onAddNewClick('BUY')}>
                  {Constant.AccountTypeDisplay["BUY"]} <i className="fa fa-chevron-right pull-right mt-1" aria-hidden="true"></i>
                </button>
                <button type="button" className="menu-item mt-2" data-dismiss="modal" onClick={(e) => this.onAddNewClick('SELL')}>
                  {Constant.AccountTypeDisplay["SELL"]} <i className="fa fa-chevron-right pull-right mt-1" aria-hidden="true"></i>
                </button>
                <button type="button" className="menu-item mt-2" data-dismiss="modal" onClick={(e) => this.onAddNewClick('EXPENSE')}>
                  {Constant.AccountTypeDisplay["EXPENSE"]} <i className="fa fa-chevron-right pull-right mt-1" aria-hidden="true"></i>
                </button>
                <button type="button" className="menu-item mt-2" data-dismiss="modal" onClick={(e) => this.onAddNewClick('INCOME')}>
                  {Constant.AccountTypeDisplay["INCOME"]} <i className="fa fa-chevron-right pull-right mt-1" aria-hidden="true"></i>
                </button>
                <button type="button" className="menu-item mt-2" data-dismiss="modal" onClick={(e) => this.onAddNewClick('WITHDRAW')}>
                  {Constant.AccountTypeDisplay["WITHDRAW"]} <i className="fa fa-chevron-right pull-right mt-1" aria-hidden="true"></i>
                </button>
                <button type="button" className="menu-item mt-2" data-dismiss="modal" onClick={(e) => this.onAddNewClick('WITHDRAW_EXPIRED')}>
                  {Constant.AccountTypeDisplay["WITHDRAW_EXPIRED"]} <i className="fa fa-chevron-right pull-right mt-1" aria-hidden="true"></i>
                </button>
                {/* <button type="button" className="menu-item mt-2" data-dismiss="modal" onClick={(e) => this.onAddNewClick('HARVEST')}>
                  {Constant.AccountTypeDisplay["HARVEST"]} <i className="fa fa-chevron-right pull-right mt-1" aria-hidden="true"></i>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccountingList;
