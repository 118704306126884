import React, { Component } from 'react';

class ConfirmDialog extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  showConfirm() {
    window.$('#' + this.props.refID).modal('show');
  }

  render() {

    const { title, body, refID, onConfirm } = this.props;

    return (
      <>
        <div className="modal fade" id={refID} role="dialog" aria-labelledby={refID + 'ModalLabel'} aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={refID + 'ModalLabel'}>{title}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {
                body &&
                <div className="modal-body">
                  {body}
                </div>
              }
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-primary-green" data-dismiss="modal" onClick={onConfirm}>ยืนยัน</button>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default ConfirmDialog;
