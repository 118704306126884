import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ReportService } from '../services/report_service';
import Constant from '../helpers/constant'
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';
import Config from '../helpers/config';

class Report08 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      selected: {
        account_code: '11005'
      }
    }

    this.onSelectedChange = this.onSelectedChange.bind(this);

    moment.locale('th');
  }

  componentDidMount() {
    this.getReportData(this.state.selected);
  }

  onSelectedChange(e) {
    let selected = this.state.selected;
    selected[e.target.name] = e.target.value;
    this.setState({
      selected: selected
    });
    if (e.target.value) {
      this.getReportData(selected);
    } else {
      this.setState({
        data: null
      });
    }
  }

  getReportData(selected) {
    this.setState({
      loading: true
    })
    ReportService.getRpt08(this.props.farm.id, selected).then(
      resp => {
        this.setState({
          data: resp.data,
          top: resp.top_to,
          loading: false
        })
      },
      error => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render() {

    let row = 0;

    return (
      <>
        <div className="row mt-3">

          <div className="col-md-6">
            <select className="form-control" name="account_code" value={this.state.selected.account_code || ''} disabled={this.state.loading}
              onChange={this.onSelectedChange}>
              <option value="" selected disabled>เลือกประเภท</option>
              <option value="11005">สินค้า</option>
              <option value="11007">วัตถุดิบ</option>
            </select>
          </div>

        </div>

        <div className="row  mt-3">
          <div className="col-md-7">
            <p className="searchTitle">ข้อมูล <strong>{this.props.farm.name} </strong>, รายงาน
             <strong> รายงานสินค้าคงเหลือ </strong><br />
              {moment().format('D MMMM YYYY')}
            </p>
          </div>
          {
            (!this.state.loading && this.state.data) &&
            <div className="col-md-5 text-right">
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-08/${this.props.farm.id}/export/xlsx?account_code=${this.state.selected.account_code}`}
                className="btn btn-primary btn-csv btn-primary-green btn-120"><i className="fas fa-file-csv"></i> Excel
              </a>
              &emsp;
              <a target="__blank" href={`${Config.ApiEndpoint}/api/report/rpt-08/${this.props.farm.id}/export/pdf?account_code=${this.state.selected.account_code}`}
                className="btn btn-primary btn-pdf btn-danger btn-120"><i className="fas fa-file-pdf"></i> PDF
              </a>
            </div>
          }

        </div>

        {
          this.state.loading &&
          <div className="row">
            <div className="col-md-12 text-center">
              <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        }
        {
          (!this.state.loading && this.state.data) &&
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">ลำดับ</th>
                <th scope="col">รายการ</th>
                <th scope="col" className="text-right">
                  ยอดยกมา<br />
                  {
                    this.state.top &&
                    moment(this.state.top).format('D MMMM YYYY')
                  }</th>
                <th scope="col" className="text-right">รับ</th>
                <th scope="col" className="text-right">จ่าย</th>
                <th scope="col" className="text-right">คงเหลือ<br />{moment().format('D MMMM YYYY')}</th>
              </tr>
            </thead>
            <tbody>

              {
                this.state.data.map((d, i) => {
                  row += 1;
                  return (
                    <tr key={row}>
                      <td className="text-center">{row}</td>
                      <td>{d.name}</td>
                      <td className="text-right">
                        {
                          parseFloat(d.top) < 0
                            ? '(' + Math.abs(parseFloat(d.top)).toLocaleString() + ')'
                            : parseFloat(d.top).toLocaleString()
                        }
                      </td>
                      <td className="text-right">
                        {
                          parseFloat(d.income) < 0
                            ? '(' + Math.abs(parseFloat(d.income)).toLocaleString() + ')'
                            : parseFloat(d.income).toLocaleString()
                        }
                      </td>
                      <td className="text-right">
                        {
                          parseFloat(d.pay) < 0
                            ? '(' + Math.abs(parseFloat(d.pay)).toLocaleString() + ')'
                            : parseFloat(d.pay).toLocaleString()
                        }
                      </td>
                      <td className="text-right">
                        {
                          parseFloat(d.qty) < 0
                            ? '(' + Math.abs(parseFloat(d.qty)).toLocaleString() + ')'
                            : parseFloat(d.qty).toLocaleString()
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        }
      </>
    );
  }
}
export default Report08;
