import React, { Component } from 'react';
import PartnerList from './partner_list';

class PayableList extends Component {

  render() {
    return (
      <>
        <PartnerList farm={this.props.farm} partnerType={"PAYABLE"} history={this.props.history} />
      </>
    );
  }
}

export default PayableList;
