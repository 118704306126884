import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CropService } from "../services/crop_service";
import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/locale/th';

class CropList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: []
    }
    this.onSearchChange = this.onSearchChange.bind(this);

    moment.locale('th');
  }

  componentDidMount() {

    CropService.getByFarmID(this.props.farm.id).then(
      (resp) => {
        this.setState({
          data: resp,
          loading: false,
        });

        this.dataTable = window.$('#example').DataTable({
          dom: 'frtlip',
          ordering: false,
          language: {
            emptyTable: "ไม่พบข้อมูล",
            zeroRecords: "ไม่พบข้อมูล",
            paginate: {
              previous: '‹',
              next: '›'
            },
            sLengthMenu: "แสดง _MENU_ รายการ/หน้า",
            aria: {
              paginate: {
                previous: 'ก่อนหน้า',
                next: 'ถัดไป'
              }
            }
          }
        });

        window.$(".dataTables_length").css('clear', 'none');
        window.$(".dataTables_length").css('margin-right', '20px');
        window.$(".dataTables_info").css('display', 'none');
        window.$(".dataTables_filter").css('display', 'none');
        window.$(".dataTables_paginate").css('margin-top', '-40px');
      }
    );

  }

  onSearchChange(event) {
    this.dataTable.search(event.target.value);
    this.dataTable.draw();
  }

  render() {
    return (
      <>
        <h4>จัดการรอบปลูก</h4>
        <div className="row">
          <div className="col-md-4">
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="ค้นหา"
                aria-label="Recipient's " aria-describedby="basic-addon2" onChange={this.onSearchChange}></input>
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
              </div>
            </div>
          </div>
          <div className="col-md-8 text-right">
            <Link to={"/add-crop/" + this.props.farm.id} className="btn btn-primary btn-primary-green">เพิ่มรอบปลูก</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table id="example" className="table table-hover" width="100%">
              <thead className="thead-light">
                <tr>
                  <th scope="col">รอบปลูก</th>
                  <th scope="col" width="15%">แปลง</th>
                  <th scope="col" width="15%">วันที่เริ่มปลูก</th>
                  <th scope="col" width="15%">วันที่เก็บเกี่ยว</th>
                </tr>
              </thead>
              <tbody>
                {
                  !this.state.loading ?

                    this.state.data.map((d) => {
                      return (
                        <tr key={d.id}>
                          <td scope="row">
                            <Link to={`/detail-crop/${d.id}`}  >
                              {d.crop_name}
                            </Link>
                          </td>
                          <td scope="row">
                            {d.plot_name}
                          </td>
                          <td scope="row" >
                            {moment(d.start_date).format('DD MMM YYYY')}
                          </td>
                          <td scope="row">
                            {
                              d.status === 'AWAITING_HARVEST' && 'รอดำเนินการ...'
                            }
                            {
                              d.status === 'HARVESTED' && moment(d.end_date).format('DD MMM YYYY')
                            }
                            {
                              d.status === 'VOID' && 'ยกเลิก'
                            }

                          </td>
                        </tr>
                      )
                    })

                    :

                    <tr className="col-md-3 text-center">
                      <td colSpan="99" className="text-center">
                        <i className="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default CropList;
